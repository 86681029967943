export const defaultRefundPolicy = `
<p>We value our customers and understand that issues can arise with digital products. Our primary aim is to ensure customer satisfaction and we are committed to addressing any concerns you may have.</p>

<p>We offer a <strong>{{ ADD PERIOD e.g. 15-day }}</strong> refund policy, which means you have <strong>{{ ADD PERIOD e.g. 15 days }}</strong> after purchasing a digital product to request a refund.</p>

<p>To be eligible for a refund, you must meet the following condition:</p>

<ul>
    <li>You must initiate a refund request within <strong>{{ ADD PERIOD e.g. 15 days }}</strong> of the purchase.</li>
</ul>

<p>To start a refund request, you can contact us at [Your Email].</p>

<p>Please include details of your order, including:</p>

<ul>
    <li>Order ID (sent to you via email after purchasing)</li>
    <li>Name of the product</li>
    <li>Date of purchase</li>
</ul>

<p>Upon receipt of your request, we will review the circumstances and issue a refund if appropriate. Refunds will be credited back to your original method of payment.</p>

<h3>Exceptions / non-returnable items</h3>

<p>Certain types of items cannot be returned, like custom products (such as special orders or personalized items), and we do not offer refunds on:</p>

<ul>
    <li>Any product not purchased from us</li>
    <li>Products obtained illegally or from unauthorized sellers</li>
</ul>

<p>Once set, this refund policy cannot be modified or altered for any purchases made while it is in effect. Any changes to our refund policy will only apply to purchases made following the effective date of the change and will be posted on this page.</p>
`;
