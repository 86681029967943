import Axios from "src/services/api";

export const getFile = async (url: string) => {
  const response = await Axios.post(
    "/products/fetch-file",
    { url: url },
    {
      responseType: "blob",
    }
  );
  const blob = new Blob([response.data]);
  // Parse the URL to get file name and type
  const urlParts = url.split("/");
  const fileName = urlParts[urlParts.length - 1];
  const fileType = blob.type;

  // Create a file from the blob
  const file = new File([blob], fileName, { type: fileType });

  return file;
};
