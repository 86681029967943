import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.scss";
import { Button } from "../Button";
import { X } from "react-feather";

interface ModalProps {
  title?: ReactNode | string;
  children: ReactNode;
  footerLeft?: ReactNode;
  onClose?: () => void;
  width?: number;
  noX?: boolean;
  isUpsell?: boolean;
  renderInLocalPortal?: string;
  noClose?: boolean;
  noTitle?: boolean;
  noFooter?: boolean;
  footerRightButton1?: {
    variant?: "secondary" | "tertiary";
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
  };
  footerRightButton2?: {
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
    variant?: "secondary" | "primary" | "danger" | "tertiary";
  };
}

const Modal: React.FC<ModalProps> = ({
  title,
  children,
  footerLeft,
  footerRightButton1,
  footerRightButton2,
  width,
  noX,
  noClose,
  isUpsell,
  renderInLocalPortal,
  noTitle,
  onClose,
  noFooter,
}) => {
  const handleClickOverlay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (noClose) return;
    if (e.target === e.currentTarget) {
      onClose && onClose();
    }
  };

  const modalContent = (
    <div className={styles.modalOverlay} onClick={handleClickOverlay}>
      <div className={styles.modalContainer}>
        {!noTitle && (
          <div
            className={`${styles.modalHeader} ${
              isUpsell ? styles.isUpsell : ""
            }`}
          >
            <h2>{title}</h2>
            {noX ? null : (
              <X className={styles.modalCloseButton} onClick={onClose} />
            )}
          </div>
        )}
        {isUpsell ? (
          <form id="payment-form" onSubmit={footerRightButton2?.onClick}>
            <div
              className={`${styles.modalBody} ${
                isUpsell ? styles.isUpsellBody : ""
              }`}
              style={{ width: width ? width + "px" : "unset" }}
            >
              {children}
            </div>
            <div className={styles.modalFooter}>
              {footerLeft && <div>{footerLeft}</div>}
              {footerRightButton1 && footerRightButton2 && (
                <div className={styles.modalFooterRight}>
                  {footerRightButton1.label && footerRightButton1.onClick && (
                    <Button
                      disabled={footerRightButton1.disabled}
                      onClick={footerRightButton1.onClick}
                      text={footerRightButton1.label}
                      variant={footerRightButton1.variant || "secondary"}
                    />
                  )}
                  {footerRightButton2.label && footerRightButton2.onClick && (
                    <Button
                      disabled={footerRightButton2.disabled}
                      type="submit"
                      text={footerRightButton2.label}
                      variant={footerRightButton2.variant || "primary"}
                    />
                  )}
                </div>
              )}
            </div>
          </form>
        ) : (
          <>
            <div
              className={`${styles.modalBody} ${
                isUpsell ? styles.isUpsellBody : ""
              }`}
              style={{ width: width ? width + "px" : "unset" }}
            >
              {children}
            </div>
            {!noFooter && (
              <div className={styles.modalFooter}>
                {footerLeft && <div>{footerLeft}</div>}
                {footerRightButton1 && footerRightButton2 && (
                  <div className={styles.modalFooterRight}>
                    {footerRightButton1.label && footerRightButton1.onClick && (
                      <Button
                        disabled={footerRightButton1.disabled}
                        onClick={footerRightButton1.onClick}
                        text={footerRightButton1.label}
                        variant={footerRightButton1.variant || "secondary"}
                      />
                    )}
                    {footerRightButton2.label && footerRightButton2.onClick && (
                      <Button
                        disabled={footerRightButton2.disabled}
                        onClick={footerRightButton2.onClick}
                        text={footerRightButton2.label}
                        variant={footerRightButton2.variant || "primary"}
                      />
                    )}
                  </div>
                )}
                {footerRightButton2 && !footerRightButton1 && (
                  <div style={{ marginLeft: "auto" }}>
                    {footerRightButton2.label && footerRightButton2.onClick && (
                      <Button
                        disabled={footerRightButton2.disabled}
                        onClick={footerRightButton2.onClick}
                        text={footerRightButton2.label}
                        variant={footerRightButton2.variant || "primary"}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  return isUpsell
    ? modalContent
    : ReactDOM.createPortal(
        modalContent,
        document.getElementById(
          renderInLocalPortal || "modal-root"
        ) as HTMLElement
      );
};

export default Modal;
