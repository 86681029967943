import React, { Suspense, useEffect, useState } from "react";
import styles from "./Discount.module.scss";
import { PageLayout } from "src/components/PageLayout";
import { PageTitle } from "src/components/PageTitle";
import { useNavigate, Link, useParams } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import {
  Button,
  Card,
  ErrorBanner,
  FormGroup,
  Modal,
  Select,
  SwitchInput,
  TextArea,
  TextInput,
} from "src/components";
import Me from "../Onboarding/me.png";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { toast } from "react-hot-toast";
import usePopups from "src/services/usePopups";
import { Checkout } from "../Sales/Checkout";
import { useAuth0 } from "@auth0/auth0-react";
import { AiOutlineDelete, AiOutlineLink } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import { DefineDiscount } from "./steps";
import { defaultProduct } from "../Product_/Product.consts";
import { PageLoader } from "src/components/page-loader";

export interface DiscountType {
  mainProductIds: any;
  name: string;
  code: string;
  value: number;
  valueType?: "percentage" | "fixed";
  active?: boolean;
  expiration: Date | null;
}

const defaultDiscount: DiscountType = {
  mainProductIds: [],
  name: "",
  code: "",
  value: 0,
  valueType: "percentage",
  active: true,
  expiration: null,
};

const Discount: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [discount, setDiscount] = React.useState<DiscountType>(defaultDiscount);
  const [selectedProduct, setSelectedProduct] = React.useState<any>({} as any);

  const [products, setProducts] = React.useState<any[]>([]);
  const [discountProducts, setDiscountProducts] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingProducts, setLoadingProducts] = React.useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { popups, togglePopup } = usePopups(["deleteDiscount"]);

  const [errors, setErrors] = useState<any>({});

  const [firstTime, setFirstTime] = useState<boolean>(true);

  const [name, setName] = useState<string>("");

  const [active, setActive] = useState<boolean>(true);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [mutating, setMutating] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      toast.promise(
        Axios.get("/discounts/" + id).then((res) => {
          setLoading(false);
          setDiscount(res.data);
        }),
        {
          loading: "Loading discount...",
          success: "Discount loaded!",
          error: (err) => {
            console.log(err);
            navigate("/discounts");
            return "Failed to load discount";
          },
        }
      );
    }
  }, []);

  useEffect(() => {
    if (firstTime) {
      setLoadingProducts(true);
      setFirstTime(false);
      Axios.get("/products").then((res) => {
        setDiscountProducts(
          res.data.map((product: any) => {
            return {
              ...product,
              label: product.name,
              value: product._id,
            };
          })
        );

        if (!id && res.data.length > 0)
          setDiscount((discount__) => ({
            ...discount__,
            discountProductId: res.data[0] ? res.data[0]._id : undefined,
          }));

        setProducts(
          res.data.map((product: any) => {
            return {
              ...product,
              label: product.name,
              value: product._id,
            };
          })
        );
        setLoadingProducts(false);
      });
    }
  }, [productIds]);

  const onSubmit = () => {
    if (!validateForm()) return;

    setMutating(true);
    if (id) {
      Axios.post("/discounts/" + id, discount)
        .then(() => {
          setMutating(false);
          toast.success("Discount updated!");
        })
        .catch((error) => {
          console.log(error);
          setMutating(false);
          toast.error("Failed to update discount");
        });
    } else {
      Axios.post("/discounts", discount)
        .then((response) => {
          setMutating(false);
          toast.success("Discount created!");
          navigate("/discounts/" + response.data._id);
        })
        .catch((error) => {
          console.log(error);
          setMutating(false);
          toast.error("Failed to create discount");
        });
    }
  };

  const validateForm = (): boolean => {
    let formErrors: any = {};

    if (currentStep === 1) {
      if (!discount.name) formErrors.name = "Please name your discount";
      if (!discount.code)
        formErrors.code =
          "Please enter a discount code for your customers to use";
      if (discount.mainProductIds?.length === 0)
        formErrors.mainProductIds = "Please select at least one product";

      if (discount.valueType === "percentage") {
        if (discount.value < 1 || discount.value > 100)
          formErrors.value = "Please enter a percentage between 1 and 100";
      }

      if (discount.valueType === "fixed") {
        if (discount.value < 1)
          formErrors.value = "Please enter a fixed value greater than 1";
      }

      if (discount.code?.length < 3)
        formErrors.code = "Please enter a code greater than 3 characters";

      if (discount.code?.length > 20)
        formErrors.code = "Please enter a code less than 20 characters";

      if (discount.expiration !== null && discount.expiration < new Date())
        formErrors.expiration = "Please select a future date";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const clickNext = () => {
    if (!validateForm()) return;

    onSubmit();
  };

  if (loading || loadingProducts) return <PageLoader />;

  return (
    <div className={styles.pageContainer}>
      {/* <div
        className={`${styles.notificationContainer} ${
          isVisible ? styles.visible : ""
        }`}
      >
        <img src={Me} alt="chain" />
        <div className={styles.notifText}>
          <div className={styles.title}>Learn how to use the discount page</div>
          <div className={styles.subTitle}>
            I created a video to show you how to use the discount page and what
            each setting does.
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              text="Watch video"
              onClick={() => {
                window.open(
                  "https://pf-vids.s3.amazonaws.com/discounts-tutorials.mp4",
                  "_blank"
                );
              }}
            />
            <Button
              variant="tertiary"
              text={hiding ? "Hiding..." : "No, thanks"}
              onClick={() => {
                setHiding(true);
                Axios.get("/users/hide-discount-guide").then((res) => {
                  setIsVisible(false);
                  setHiding(false);
                });
              }}
            />
          </div>
        </div>
      </div> */}
      <div className={styles.formsContainer}>
        <div className={styles.form}>
          <div className={styles.navbar}>
            <h1>
              {id ? discount.name + ": " + discount.code : "Create discount"}
            </h1>
            <div className={styles.actions}>
              {id && (
                <Button
                  isIcon
                  variant="secondary"
                  text={
                    <AiOutlineDelete
                      size={18}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  }
                  onClick={() => {
                    togglePopup("deleteDiscount");
                  }}
                />
              )}
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  navigate("/discounts");
                }}
              />
              <Button
                text={id ? "Edit discount" : "Create discount"}
                disabled={mutating}
                onClick={clickNext}
              />
            </div>
          </div>
          <div className={styles.stepIndicator}>
            <div
              className={styles.stepIndicatorItem}
              style={{
                width: `${(currentStep / 1) * 100}%`,
                transition: "width 0.5s ease-in-out",
              }}
            />
          </div>
          <div className={styles.formContent}>
            {currentStep === 1 ? (
              <DefineDiscount
                discount={discount}
                setDiscount={setDiscount}
                errors={errors}
                products={products}
              />
            ) : null}
          </div>
        </div>
      </div>

      {popups.deleteDiscount && (
        <Modal
          onClose={() => togglePopup("deleteDiscount")}
          title="Delete Discount"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("deleteDiscount");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Delete",
            onClick: () => {
              Axios.delete("/discounts/" + id)
                .then(() => {
                  togglePopup("deleteDiscount");
                  toast.success("Discount deleted!");
                  navigate("/discounts");
                })
                .catch((error) => {
                  console.log(error);
                  togglePopup("deleteDiscount");
                  toast.error("Failed to delete discount");
                });
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to delete this discount?
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Discount;
