import React, { Suspense, useEffect, useState } from "react";
import styles from "./Upsell.module.scss";
import { PageLayout } from "src/components/PageLayout";
import { PageTitle } from "src/components/PageTitle";
import { useNavigate, Link, useParams } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import {
  Button,
  Card,
  ErrorBanner,
  FormGroup,
  Modal,
  Select,
  SwitchInput,
  TextArea,
  TextInput,
} from "src/components";
import Me from "../Onboarding/me.png";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { toast } from "react-hot-toast";
import usePopups from "src/services/usePopups";
import { Checkout } from "../Sales/Checkout";
import { useAuth0 } from "@auth0/auth0-react";
import { AiOutlineDelete, AiOutlineLink } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import { DefineUpsell, ViewUpsell } from "./steps";
import { defaultProduct } from "../Product_/Product.consts";
import { PageLoader } from "src/components/page-loader";

export type UpsellType = {
  name: string;
  offer?: string;
  upsellDescription?: string;
  upsellPrice: number;
  primaryButtonText: string;
  secondaryButtonText: string;
  active?: boolean;
  upsellProductId?: any;
  mainProductIds?: any[];
};

const defaultUpsell: UpsellType = {
  name: "",
  offer: "",
  upsellDescription: "",
  upsellPrice: 0,
  primaryButtonText: "Yes, add to my order",
  secondaryButtonText: "No, thanks",
  active: true,
  upsellProductId: {},
  mainProductIds: [],
};

const Upsell: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [upsell, setUpsell] = React.useState<UpsellType>(defaultUpsell);

  const [products, setProducts] = React.useState<any[]>([]);
  const [upsellProducts, setUpsellProducts] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingProducts, setLoadingProducts] = React.useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { popups, togglePopup } = usePopups(["deleteUpsell"]);

  const [errors, setErrors] = useState<any>({});

  const [firstTime, setFirstTime] = useState<boolean>(true);

  const [name, setName] = useState<string>("");

  const [active, setActive] = useState<boolean>(true);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [mutating, setMutating] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      toast.promise(
        Axios.get("/upsells/" + id).then((res) => {
          setLoading(false);
          setUpsell(res.data);
        }),
        {
          loading: "Loading upsell...",
          success: "Upsell loaded!",
          error: (err) => {
            console.log(err);
            navigate("/upsells");
            return "Failed to load upsell";
          },
        }
      );
    }
  }, []);

  useEffect(() => {
    if (firstTime) {
      setLoadingProducts(true);
      setFirstTime(false);
      Axios.get("/upsells").then((response) => {
        Axios.get("/products").then((res) => {
          setUpsellProducts(
            res.data.map((product: any) => {
              return {
                ...product,
                label: product.name,
                value: product._id,
              };
            })
          );

          if (!id && res.data.length > 0)
            setUpsell((upsell__) => ({
              ...upsell__,
              upsellProductId: res.data[0] ? res.data[0]._id : undefined,
            }));

          setProducts(
            res.data
              .filter((prod: any) => {
                // check if product is already in any upsell.mainProductIds, if so, don't include it in the list
                return !response.data.upsells.some((upsell: any) => {
                  return (
                    upsell.mainProductIds.includes(prod._id) &&
                    upsell._id !== id
                  );
                });
              })
              .map((product: any) => {
                return {
                  ...product,
                  label: product.name,
                  value: product._id,
                };
              })
          );
          setLoadingProducts(false);
        });
      });
    }
  }, [productIds]);

  const onSubmit = () => {
    if (!validateForm()) return;

    setMutating(true);
    if (id) {
      Axios.post("/upsells/" + id, upsell)
        .then(() => {
          setMutating(false);
          toast.success("Upsell updated!");
        })
        .catch((error) => {
          console.log(error);
          setMutating(false);
          toast.error("Failed to update upsell");
        });
    } else {
      Axios.post("/upsells", upsell)
        .then((response) => {
          setMutating(false);
          toast.success("Upsell created!");
          navigate("/upsells/" + response.data._id);
        })
        .catch((error) => {
          console.log(error);
          setMutating(false);
          toast.error("Failed to create upsell");
        });
    }
  };

  const validateForm = (): boolean => {
    let formErrors: any = {};

    if (currentStep === 1) {
      if (!upsell.name) formErrors.name = "Please name your upsell";
      if (!upsell.offer)
        formErrors.offer =
          "Please write an offer - this is the title of the upsell";
      if (!upsell.primaryButtonText)
        formErrors.primaryButtonText = "Please write a primary button text";
      if (!upsell.secondaryButtonText)
        formErrors.secondaryButtonText = "Please write a secondary button text";
    } else {
      if (!upsell.upsellPrice && upsell.upsellPrice !== 0)
        formErrors.price = "Price is required";
      else if (isNaN(Number(upsell.upsellPrice)))
        formErrors.price = "Price must be a number";
      else if (Number(upsell.upsellPrice) < 0)
        formErrors.price = "Price must be greater than 0";

      if (upsell.mainProductIds?.length === 0)
        formErrors.mainProductIds = "Please select at least one product";

      if (upsell.upsellProductId === "")
        formErrors.upsellProductId =
          "Please select a product to offer as upsell";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const clickNext = () => {
    if (!validateForm()) return;

    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    } else {
      onSubmit();
    }
  };

  const me = JSON.parse(localStorage.getItem("me")!);
  const [isVisible, setIsVisible] = useState(false);
  const [hiding, setHiding] = useState(false);
  useEffect(() => {
    if (me?.hideUpsellGuide) return;

    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutId); // clean up the timeout
    };
  }, []);

  if (loading || loadingProducts) return <PageLoader />;

  return (
    <div className={styles.pageContainer}>
      <div
        className={`${styles.notificationContainer} ${
          isVisible ? styles.visible : ""
        }`}
      >
        <img src={Me} alt="chain" />
        <div className={styles.notifText}>
          <div className={styles.title}>Learn how to use the upsell page</div>
          <div className={styles.subTitle}>
            I created a video to show you how to use the upsell page and what
            each setting does.
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              text="Watch video"
              onClick={() => {
                window.open(
                  "https://pf-vids.s3.amazonaws.com/upsells-tutorials.mp4",
                  "_blank"
                );
              }}
            />
            <Button
              variant="tertiary"
              text={hiding ? "Hiding..." : "No, thanks"}
              onClick={() => {
                setHiding(true);
                Axios.post("/users/hide-upsell-guide").then((res) => {
                  setIsVisible(false);
                  setHiding(false);
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.formsContainer}>
        <div className={styles.form}>
          <div className={styles.navbar}>
            <h1>{id ? upsell.name || "Edit upsell" : "Create upsell"}</h1>
            <div className={styles.actions}>
              <div
                className={
                  styles.publishedItem +
                  (upsell.active ? " " + styles.published : "")
                }
              >
                <SwitchInput
                  checked={upsell.active || false}
                  onChange={(checked) => {
                    console.log(checked);
                    setUpsell({ ...upsell, active: checked });
                  }}
                  id="published"
                  name="published"
                />
                <span>{upsell.active ? "Active" : "Unactive"}</span>
              </div>
              <div className={styles.divider} />
              {id && (
                <Button
                  isIcon
                  variant="secondary"
                  text={
                    <AiOutlineDelete
                      size={18}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  }
                  onClick={() => {
                    togglePopup("deleteUpsell");
                  }}
                />
              )}
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  navigate("/upsells");
                }}
              />
              <Button
                text={
                  currentStep === 1
                    ? "Next: Products"
                    : id
                    ? "Edit upsell"
                    : "Create upsell"
                }
                disabled={mutating}
                onClick={clickNext}
              />
            </div>
          </div>
          <div className={styles.stepIndicator}>
            <div
              className={styles.stepIndicatorItem}
              style={{
                width: `${(currentStep / 2) * 100}%`,
                transition: "width 0.5s ease-in-out",
              }}
            />
          </div>
          <div className={styles.formContent}>
            <div className={styles.stepCounter}>
              <IoArrowBack
                size={18}
                style={{
                  cursor: "pointer",
                }}
                color={currentStep === 1 ? "#ccc" : "#3447ef"}
                onClick={() => {
                  if (currentStep > 1) {
                    setCurrentStep(currentStep - 1);
                  }
                }}
              />{" "}
              Step {currentStep}/2
            </div>
            {currentStep === 1 ? (
              <DefineUpsell
                upsell={upsell}
                setUpsell={setUpsell}
                errors={errors}
              />
            ) : currentStep === 2 ? (
              <ViewUpsell
                upsell={upsell}
                setUpsell={setUpsell}
                errors={errors}
                products={products}
                upsellProducts={upsellProducts}
                loadingProducts={loadingProducts}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.previewContainer}>
        <div className={styles.previewNavbar}>
          {currentStep === 1 || currentStep === 2 ? (
            <h1>Upsell preview</h1>
          ) : null}
          {false && (
            <div className={styles.actions}>
              <Button
                isIcon
                variant="secondary"
                text={
                  <AiOutlineLink
                    size={18}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                }
                onClick={() => {
                  // // copy to clipboard
                  navigator.clipboard.writeText(
                    `${window.location.origin}/prod/${id}`
                  );
                  toast.success("copied");
                }}
              />
              {/* button to open preview in new tab */}
              <Link to={`/prod/${id}`} target="_blank">
                <Button text="View product" />
              </Link>
            </div>
          )}
        </div>
        <div className={styles.pagesPreview}>
          <div className={styles.pagePreview}>
            <Suspense fallback={<div>Loading...</div>}>
              <Checkout
                demoMode
                _user={user}
                upsell={{
                  ...upsell,
                }}
              />
            </Suspense>
          </div>
        </div>
      </div>
      {popups.deleteUpsell && (
        <Modal
          onClose={() => togglePopup("deleteUpsell")}
          title="Delete Upsell"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("deleteUpsell");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Delete",
            onClick: () => {
              Axios.delete("/upsells/" + id)
                .then(() => {
                  togglePopup("deleteUpsell");
                  toast.success("Upsell deleted!");
                  navigate("/upsells");
                })
                .catch((error) => {
                  console.log(error);
                  togglePopup("deleteUpsell");
                  toast.error("Failed to delete upsell");
                });
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to delete this upsell?
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Upsell;
