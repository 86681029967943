import jwtDecode from "jwt-decode";

export const isTokenExpired = (token: string) => {
  try {
    const decoded: any = jwtDecode(token);
    return decoded.exp && Date.now() >= decoded.exp * 1000;
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};
