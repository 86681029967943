import React, { ChangeEvent, DragEvent, useState } from "react";
import styles from "./Product.module.scss";
import { FiUploadCloud } from "react-icons/fi";
import { BsFileText } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";

// this is a file uploader
const SingleUploader = ({
  value,
  onChange,
  type = "file",
  text,
  error,
}: {
  type?: "file" | "image";
  value?: File | null;
  onChange: (file: File | null) => void;
  text?: string;
  error?: string;
}) => {
  const [highlight, setHighlight] = useState(false);
  const ref = React.useRef<HTMLInputElement>(null);

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    onChange(file);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlight(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlight(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlight(false);

    const file = event.dataTransfer.files?.[0] || null;
    onChange(file);
  };

  const acceptType = type === "image" ? "image/*" : "";

  return (
    <div
      className={`${styles.fileUploader} ${highlight ? styles.highlight : ""} ${
        error ? styles.error : ""
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => ref.current?.click()}
    >
      <div className={styles.fileUploaderInner}>
        {value ? (
          <>
            {
              // if the file is an image, show the image
              type === "image" ? (
                <div className={styles.imageContainer}>
                  <img src={URL.createObjectURL(value)} alt="preview" />
                </div>
              ) : (
                <div className={styles.fileContainer}>
                  <BsFileText size={20} color="#768AA9" />
                </div>
              )
            }
            <span className={styles.textSide}>
              <p className={styles.fileName}>{value.name}</p>
              {/* this should be the size in MB */}
              <p>
                {(value.size / 1024 / 1024).toFixed(2)} MB
                {" " /* 1MB = 1024KB = 1024 * 1024 bytes */}
              </p>
            </span>
            <div
              className={styles.deleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                onChange(null);
              }}
            >
              <MdDeleteOutline size={20} color="#e54f4f" />
            </div>
          </>
        ) : (
          <>
            <div className={styles.iconContainer}>
              <FiUploadCloud size={20} color="#768AA9" />
            </div>
            <span className={styles.textSide}>
              <p>
                Drag it here, or <span>click to browse</span>
              </p>
              <p>{text}</p>
            </span>
          </>
        )}
      </div>
      <input
        type="file"
        ref={ref}
        onChange={handleFileInputChange}
        accept={acceptType}
      />
    </div>
  );
};

export default SingleUploader;
