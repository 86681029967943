import { useState } from 'react';

type PopupsType<T extends string> = Record<T, boolean>;
type PopupsParam<T extends string> = PopupsType<T> | ReadonlyArray<T>;

const usePopups = <T extends string>(
  popups: PopupsParam<T>
): {
  popups: PopupsType<T>;
  togglePopup: (popupKey: T, isOpen?: boolean) => void;
} => {
  const popupsObject = (
    Array.isArray(popups) ? createObject(popups as T[]) : popups
  ) as PopupsType<T>;

  const [popupsState, setPopupsState] = useState<PopupsType<T>>(popupsObject);

  const togglePopup = (popupKey: T, isOpen?: boolean) => {
    setPopupsState((prevPopupState) => ({
      ...prevPopupState,
      [popupKey]: isOpen ?? !prevPopupState[popupKey],
    }));
  };

  return { popups: popupsState, togglePopup };
};

function createObject<T extends string>(steps: T[]): PopupsType<T> {
  const typed = {} as Record<string, boolean>;
  steps.forEach((step) => (typed[step] = false));
  return typed as unknown as PopupsType<T>;
}

export default usePopups;
