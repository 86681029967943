import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Editor } from "@tiptap/react";
import { useInView } from "react-cool-inview";
import {
  RiH1,
  RiH2,
  RiH3,
  RiListOrdered,
  RiListUnordered,
  RiCodeBoxLine,
  RiLink,
  RiLinkUnlinkM,
  RiDoubleQuotesL,
  RiSeparator,
  RiTextWrap,
  RiFormatClear,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiImageLine,
  RiVideoLine,
  RiCursorFill,
  RiFileAddLine,
  RiVidiconLine,
  RiDownloadCloudLine,
} from "react-icons/ri";

import {
  HiCursorClick,
  HiDownload,
  HiOutlineCursorClick,
} from "react-icons/hi";

import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineStrikethrough,
} from "react-icons/ai";

import { setLink } from "./helpers";

import "./Toolbar.scss";
import Axios from "src/services/api";
import { toast } from "react-hot-toast";

type ToolbarProps = {
  editor: Editor;
  extended?: boolean;
  refund?: boolean;
};

function Toolbar({ editor, extended, refund }: ToolbarProps) {
  const { observe, inView } = useInView({
    rootMargin: "-1px 0px 0px 0px",
    threshold: [1],
  });
  const isCursorOverLink = editor.getAttributes("link").href;

  const hiddenImageInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    editor.chain().focus().run();
  }, [extended]);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files![0];
    const reader = new FileReader();
    let imageNode: any;

    reader.onloadend = () => {
      const url = reader.result as string;

      editor
        .chain()
        .focus()
        .insertContent({ type: "image", attrs: { src: url } })
        .run();

      editor.state.doc.descendants((node, pos) => {
        if (node.type.name === "image" && node.attrs.src === url) {
          imageNode = { node, pos };
        }
      });

      // Create new FormData and append the file
      const formData = new FormData();
      formData.append("image", file);

      // POST the formData to your API endpoint
      const uploadImage = Axios.post("/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.promise(uploadImage, {
        loading: "Uploading...",
        success: (res) => {
          if (imageNode) {
            const { node, pos } = imageNode;
            const newNode = node.type.create({ src: res.data.imageUrl });
            const transaction = editor.state.tr?.replaceWith(
              pos,
              pos + node.nodeSize,
              newNode
            );
            editor.view.dispatch(transaction);
          }
          return "Image uploaded successfully!";
        },
        error: "Failed to upload image.",
      });
    };

    reader.readAsDataURL(file);

    if (event.target) {
      event.target.value = "";
    }
  };

  const handleImageClick = () => {
    hiddenImageInput.current!.click();
  };

  const handleFileClick = () => {
    const text = window.prompt("Enter the text of the download button");

    if (text) {
      editor
        .chain()
        .focus()
        .insertContent(
          `<a class="added-button download-button" href="#">${text}</a>`
        )
        .run();
    }
  };

  const handleButtonPrompt = () => {
    const text = prompt("Button Text");
    const href = prompt("Button URL");

    if (text && href) {
      // @ts-ignore
      editor
        .chain()
        .focus()
        .insertContent(`<a class="added-button" href="${href}">${text}</a>`)
        .run();
    }
  };

  return (
    <div
      className={classNames("ToolbarContainer", { sticky: !inView })}
      ref={observe}
    >
      <div className="Toolbar">
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleBold().run()}
          title="Bold"
        >
          <AiOutlineBold size={18} />
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          title="Italic"
        >
          <AiOutlineItalic size={18} />
        </div>
        {extended && (
          <div
            className="icon"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            title="Strike"
          >
            <AiOutlineStrikethrough size={18} />
          </div>
        )}
        <div className="divider"></div>
        <div
          className="icon"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          title="Heading 1"
        >
          <RiH1 size={20} />
        </div>
        <div
          className="icon"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          title="Heading 2"
        >
          <RiH2 size={20} />
        </div>
        <div
          className="icon"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          title="Heading 3"
        >
          <RiH3 size={20} />
        </div>

        <div
          className="icon"
          onClick={() => editor.chain().focus().setParagraph().run()}
          title="Paragraph"
        >
          <div className="text">P</div>
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          title="Bullet List"
        >
          <RiListUnordered size={20} />
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          title="Ordered List"
        >
          <RiListOrdered size={20} />
        </div>
        {extended && (
          <div
            className="icon"
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            title="Code Block"
          >
            <RiCodeBoxLine size={20} />
          </div>
        )}
        <div className="divider"></div>
        <div className="icon" onClick={() => setLink(editor)} title="Link">
          <RiLink size={20} />
        </div>
        <div
          className={classNames("icon", { disabled: !isCursorOverLink })}
          onClick={() => setLink(editor)}
          title="Unlink"
        >
          <RiLinkUnlinkM size={20} />
        </div>

        <div className="divider"></div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          title="Blockquote"
        >
          <RiDoubleQuotesL size={20} />
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          title="Horizontal Rule"
        >
          <RiSeparator size={20} />
        </div>
        {!refund && (
          <>
            <div className="divider"></div>
            <div className="icon" onClick={handleImageClick} title="Image">
              <RiImageLine size={20} />
              <input
                type="file"
                ref={hiddenImageInput}
                onChange={handleImageChange}
                style={{ display: "none" }}
                accept="image/*"
              />
            </div>
            <div
              className="icon"
              onClick={() => {
                function getEmbedUrl(url: string): string | null {
                  const youtubeRegex =
                    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/)|youtu\.be\/)([\w-]{11})$/;
                  const match = url.match(youtubeRegex);

                  if (match && match[1]) {
                    const videoId = match[1];
                    return `https://www.youtube.com/embed/${videoId}`;
                  }

                  return null;
                }

                // usage
                const url = window.prompt(
                  "Enter the URL of the video (Only YouTube/Vimeo are supported for now)"
                );

                if (url) {
                  const embedUrl = getEmbedUrl(url);
                  if (embedUrl)
                    editor
                      .chain()
                      .focus()
                      .insertContent(`<video src="${embedUrl}"></video>`)
                      .run();
                } else {
                  // Handle invalid YouTube URL
                  console.error("Invalid YouTube URL");
                }
              }}
              title="Video"
            >
              <RiVidiconLine size={20} />
            </div>
            <div
              className="icon"
              onClick={() =>
                editor.chain().focus().unsetAllMarks().clearNodes().run()
              }
              title="Clear Formatting"
            >
              <RiFormatClear size={20} />
            </div>
            <div className="icon" onClick={handleButtonPrompt} title="Button">
              <HiOutlineCursorClick size={20} />
            </div>
            {extended && (
              <>
                <div
                  className="icon text"
                  onClick={handleFileClick}
                  title="Product Link or Download File"
                >
                  Link to Product
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export { Toolbar };
