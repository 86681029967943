import React from "react";
import { Modal, SwitchInput } from "src/components";
import styles from "./MoRModal.module.scss";
import { FaCheckCircle } from "react-icons/fa";
import toast from "react-hot-toast";
import Axios from "src/services/api";

interface MoRModalProps {
  onSave: () => void;
  onClose?: () => void;
  inSettings?: boolean;
}

const MoRModal = ({ onSave, inSettings, onClose }: MoRModalProps) => {
  const [taxEnabled, setTaxEnabled] = React.useState(true);
  return (
    <Modal
      noTitle
      noX
      noClose={!inSettings}
      onClose={() => onClose && onClose()}
      footerRightButton2={{
        label: "Got it, thanks!",
        onClick: () => {
          if (inSettings) {
            onSave();
            return;
          }

          toast.promise(
            Axios.post("/users/edit-tax", {
              taxEnabled,
            })
              .then(() => {
                onSave();
              })
              .catch((err) => {
                console.log(err);
              }),
            {
              loading: "Saving...",
              success: "Saved",
              error: "Could not save",
            }
          );
        },
      }}
    >
      <div className={styles.modalContent}>
        <div className={styles.morTitle}>
          <h2>We've Got Your Taxes Covered</h2>
          {!inSettings && (
            <div
              className={
                styles.publishedItem +
                (taxEnabled ? " " + styles.published : "")
              }
            >
              <SwitchInput
                checked={taxEnabled}
                onChange={(checked: any) => {
                  setTaxEnabled(checked);
                }}
                id="published"
                name="published"
              />
              <span>
                {taxEnabled ? "Remit my taxes" : "Don't remit my taxes"}
              </span>
            </div>
          )}
        </div>
        <p>
          Welcome to Pocketsflow! As you explore our platform to sell your
          digital products, we want to highlight an important aspect of our
          service: Pocketsflow acts as the <b>Merchant of Record (MoR)</b> for
          all transactions.
        </p>
        <p>What does this mean for you?</p>
        <div className={styles.infoSection}>
          <FaCheckCircle
            style={{
              color: "#4CAF50",
              marginTop: "2px",
              flexShrink: 0,
            }}
            size={22}
          />
          <p>
            <strong>Automatic Tax Calculation:</strong> We automatically
            calculate the correct taxes for every sale based on the buyer's
            location. This might include sales tax, VAT, GST, or other taxes as
            applicable. After we reach an obligation threshold in a particular
            tax jurisdiction, we will register, collect, and remit taxes in that
            jurisdiction.{" "}
          </p>
        </div>
        <div className={styles.infoSection}>
          <FaCheckCircle
            style={{
              color: "#4CAF50",
              marginTop: "2px",
              flexShrink: 0,
            }}
            size={22}
          />
          <p>
            <strong>Tax Collection and Remittance:</strong> We collect these
            calculated taxes from the buyer at checkout and directly remit them
            to the appropriate tax authorities. This means you don't have to
            worry about tax collection, filings, or payments related to your
            sales on Pocketsflow.
          </p>
        </div>
        <div className={styles.infoSection}>
          <FaCheckCircle
            style={{
              color: "#4CAF50",
              marginTop: "2px",
              flexShrink: 0,
            }}
            size={22}
          />
          <p>
            <strong>Tax Invoicing:</strong> For each transaction, we provide the
            buyer with a comprehensive tax invoice. You are not required to
            generate or send these invoices.
          </p>
        </div>
        <div className={styles.infoSection}>
          <FaCheckCircle
            style={{
              color: "#4CAF50",
              marginTop: "2px",
              flexShrink: 0,
            }}
            size={22}
          />
          <p>
            <strong>Tax Registration:</strong> As our volume and reach grows, we
            will handle the registration for the tax collection in new
            jurisdictions as required by law. We use{" "}
            <a target="_blank" href="https://stripe.com/tax">
              Stripe Tax
            </a>{" "}
            and{" "}
            <a target="_blank" href="https://avalara.com">
              Avalara
            </a>{" "}
            to get notified of our registration obligations.
          </p>
        </div>
        <p>
          Remember, while we handle transactional taxes on your behalf, you may
          still have other tax obligations related to your income from sales on
          Pocketsflow, such as income tax or self-employment tax. We recommend
          you consult a tax professional to understand your tax obligations.
        </p>
        <p>
          We're glad to have you on board and are here to make selling your
          digital products as hassle-free as possible. If you have any
          questions, please don't hesitate to get in touch with us at{" "}
          <a href="mailto:support@pocketsflow.com">support@pocketsflow.com</a>
        </p>
      </div>
    </Modal>
  );
};

export default MoRModal;
