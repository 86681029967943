import React, { useEffect, useRef, useState } from "react";

import styles from "./Refunds.module.scss";
import {
  Button,
  Card,
  EmptyState,
  PageLayout,
  PageTitle,
} from "src/components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Axios from "src/services/api";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Count, Countries } from "../Customers/Customers";
import "../Dashboard/Dashboard.scss";
import { IoChevronDownOutline } from "react-icons/io5";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import Gravatar from "src/components/Gravatar";

const columns = ["Name", "Refund period", "Applied to", "Created at"];

const Refunds = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [refunds, setRefunds] = useState<any[]>([]);

  useEffect(() => {
    Axios.get("/refunds").then((response) => {
      setRefunds(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div>
            <Link to="/refunds/new">
              <Button text="New refund policy" />
            </Link>
          </div>
        }
      >
        Refund policies
      </PageTitle>
      {loading || refunds?.length === 0 ? (
        <EmptyState
          loading={loading}
          title="Create your first refund policy"
          subTitle="Refund policies are important for your customers to know if they can get a refund for your products."
          buttonText="Create refund policy"
          buttonClick={() => {
            navigate("/refunds/new");
          }}
        />
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.productsTable}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {refunds?.map((refund, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        navigate(`/refunds/${refund._id}`);
                      }}
                    >
                      <td>
                        <div className={styles.ellipsis}>{refund.name}</div>
                      </td>
                      <td>
                        <div className={styles.ellipsis}>
                          {refund.period} {refund.periodType}
                        </div>
                      </td>
                      <td>
                        <div className={styles.ellipsis}>
                          {refund.appliedTo || 0} product
                          {(refund.appliedTo > 1 || !refund.appliedTo) && "s"}
                        </div>
                      </td>
                      <td>
                        <div className={styles.ellipsis}>
                          {dayjs(refund.createdAt).fromNow()}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Count items={refunds} />
        </>
      )}
    </div>
  );
};

export default Refunds;
