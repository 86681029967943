import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import PP from "./payp.png";
import { toast } from "react-hot-toast";
import Me from "../Onboarding/me.png";
import lodash from "lodash";
import { Button, Card, ErrorBanner, Modal, PageTitle } from "src/components";
import Axios from "src/services/api";
import styles from "./Profile.module.scss";
import { AiFillCheckCircle, AiOutlineBank } from "react-icons/ai";
import { PageLoader } from "src/components/page-loader";
import { useLocation, useNavigate } from "react-router-dom";
import usePopups from "src/services/usePopups";

const Payments = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const errorPayPal = queryParams.get("errorPayPal");

  const { popups, togglePopup } = usePopups(["disconnectPayPal"]);
  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  const [stripeAccount, setStripeAccount] = React.useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Axios.get("/users/stripe-account").then((res) => {
      setIsLoading(false);
      setStripeAccount(res.data);
      localStorage.setItem("stripeAccount", JSON.stringify(res.data));
    });
  }, []);

  const defaultUserData = {
    ppp: me?.ppp || false,
    currencyConversion: me?.currencyConversion || false,
  };
  const [userData, setUserData] = useState(defaultUserData);

  const submitForm = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    toast.promise(
      Axios.post(`/users/update`, userData).then((res) => {
        localStorage.setItem("me", JSON.stringify(res.data));
      }),
      {
        loading: "Saving...",
        success: "Saved!",
        error: "Error while saving.",
      }
    );
  };

  const connectStripe = async () => {
    try {
      toast.promise(
        Axios.post("/users/connect-stripe", {
          email: me?.email,
        }).then((res) => {
          return Axios.post("/users/create-stripe-link", {
            connectedAccountId: res.data.id,
          }).then((response) => {
            // window.open(response.data.url, "_blank");

            const a = document.createElement("a");
            a.href = response.data.url;
            a.rel = "noopener noreferrer";
            a.click();

            // window.location.reload();
          });
        }),
        {
          loading: "Connecting...",
          success: "Connected!",
          error: "Error while connecting.",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const disconnectStripe = async () => {
    try {
      toast.promise(
        Axios.get("/users/disconnect-stripe").then((resp) => {
          console.log(resp.data.stripeConnectedAccountId);
          if (
            !resp.data.stripeConnectedAccountId ||
            resp.data.stripeConnectedAccountId === ""
          ) {
            window.location.reload();
          }
        }),
        {
          loading: "Disconnecting...",
          success: "Disconnected!",
          error: "Error while disconnecting.",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const connectPayPal = async () => {
    if (me?.merchantIdInPayPal && me?.merchantId) {
      toast.promise(
        Axios.post("/users/paypal-disconnect").then(() => {
          window.location.reload();
        }),
        {
          loading: "Disconnecting...",
          success: "Disconnected!",

          error: "Error while disconnecting.",
        }
      );
    } else {
      toast.promise(
        Axios.post("/users/connect-paypal").then((response) => {
          const actionURL = response.data.links.find(
            (link: any) => link.rel === "action_url"
          ).href;

          // window.open(actionURL, "_blank");
          const a = document.createElement("a");
          a.href = actionURL;
          // a.target = "_blank";
          a.rel = "noopener noreferrer";
          a.click();
        }),
        {
          loading: "Connecting...",
          success: "Connected!",

          error: "Error while connecting.",
        }
      );
    }
  };

  // const connectBankAccount = async () => {
  //   try {
  //     toast.promise(
  //       Axios.post("/users/create-stripe-link", {
  //         connectedAccountId: stripeAccount.id,
  //       }).then((response) => {
  //         window.open(response.data.url, "_blank");
  //       }),
  //       {
  //         loading: "Connecting...",
  //         success: "Connected!",

  //         error: "Error while connecting.",
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const createLogin = async () => {
    try {
      toast.promise(
        Axios.get("/users/create-login-link").then((response) => {
          // window.open(response.data.url, "_blank");
          const a = document.createElement("a");
          a.href = response.data.url;
          // a.target = "_blank";
          a.rel = "noopener noreferrer";
          a.click();
        }),
        {
          loading: "Loading...",
          success: "Loaded!",
          error: "Error while loading.",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  const [hiding, setHiding] = useState(false);
  useEffect(() => {
    if (me?.hidePaymentsGuide) return;

    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutId); // clean up the timeout
    };
  }, []);

  useEffect(() => {
    const scriptId = "paypal-js";

    if (
      !document.getElementById(scriptId) &&
      process.env.NODE_ENV === "production"
    ) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.async = true;
      script.src =
        "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
      // You can add any additional functionalities that require the PayPal script here
      document.body.appendChild(script);
    }
  }, []);

  // const isStripeConnected =
  //   stripeAccount && stripeAccount?.message !== "No stripe account connected";
  const hasBankAccount = stripeAccount?.external_accounts?.data.length > 0;

  // const [supportedCountries, setSupportedCountries] = useState([]);
  // const [currentCountry, setCurrentCountry] = useState<any>(null);
  // const [unsupportedUser, setUnsupportedUser] = useState<any>(null);

  // useEffect(() => {
  //   import("../../services/supportedCountries.json").then((data) => {
  //     setSupportedCountries(data.default as any);
  //   });
  // }, []);

  // determine user country
  // useEffect(() => {
  //   if (me?.finishedOnboarding) {
  //     Axios.get("https://api.ipgeolocation.io/ipgeo", {
  //       params: {
  //         apiKey: "5c920f814db94414b5f7f70bd9dd56de",
  //       },
  //     })
  //       .then((res) => {
  //         setCurrentCountry(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [me]);

  // useEffect(() => {
  //   if (me?.finishedOnboarding) {
  //     if (currentCountry) {
  //       setUnsupportedUser(
  //         supportedCountries.some(
  //           (country: any) => country.code === currentCountry.country_code2
  //         )
  //           ? null
  //           : currentCountry
  //       );
  //     }
  //   }
  // }, [currentCountry]);

  // console.log(me);

  return (
    <form onSubmit={submitForm}>
      <div className={styles.settingsPage}>
        <div
          className={`${styles.notificationContainer} ${
            isVisible ? styles.visible : ""
          }`}
        >
          <img src={Me} alt="chain" />
          {/* <div className={styles.notifText}>
            <div className={styles.title}>
              Learn how to use the payments settings
            </div>
            <div className={styles.subTitle}>
              I created a video to show you how to use the payments page and
              what each setting does.
            </div>
            <div className={styles.buttonsContainer}>
              <a
                href="https://pf-vids.s3.amazonaws.com/payments-tutorial.mp4"
                target="_blank"
              >
                <Button text="Watch video" />
              </a>
              <Button
                variant="tertiary"
                text={hiding ? "Hiding..." : "No, thanks"}
                onClick={() => {
                  setHiding(true);
                  Axios.post("/users/hide-payments-guide").then((res) => {
                    setIsVisible(false);
                    setHiding(false);
                  });
                }}
              />
            </div>
          </div> */}
        </div>
        <PageTitle
          actions={
            <>
              <Button
                text={"Cancel"}
                variant="secondary"
                onClick={() => setUserData(defaultUserData)}
              />
              <Button
                text={"Save changes"}
                type="submit"
                disabled={lodash.isEqual(userData, defaultUserData)}
              />
            </>
          }
        >
          Payments
        </PageTitle>
        {errorPayPal && (
          <ErrorBanner message="Make sure you have a PayPal business account and your email is confirmed." />
        )}
        {!hasBankAccount && !me?.merchantIdInPayPal && (
          <ErrorBanner
            message="If you connect to your bank account or PayPal, then you can withdraw your earnings. We are partners with Stripe and PayPal to provide you a secure experience."
            type="info"
          />
        )}
        <Card>
          <div className={styles.formsContainer}>
            {/* <FormGroup label="Enable Purchasing Power Parity (PPP)">
              <SwitchInput
                name="ppp"
                checked={userData.ppp}
                onChange={(checked) => {
                  setUserData({ ...userData, ppp: checked });
                }}
                id="ppp"
              />
            </FormGroup> */}
            {/* <FormGroup label="Enable local currency conversion">
              <SwitchInput
                name="currencyConversion"
                checked={userData.ç}
                onChange={(checked) => {
                  setUserData({ ...userData, currencyConversion: checked });
                }}
                id="currencyConversion"
              />
            </FormGroup> */}
            <div
              className={styles.stripeBank}
              style={
                {
                  // marginTop: !hasBankAccount && !me?.merchantIdInPayPal ? -20 : 0,
                }
              }
            >
              <div className={styles.iconContainer}>
                <AiOutlineBank size={22} />
              </div>
              <div className={styles.bankText}>
                <div className={styles.bankTitle}>
                  <span>Bank account</span>{" "}
                  {hasBankAccount && (
                    <AiFillCheckCircle color="#1ecc1e" size={16} />
                  )}
                </div>
                <div className={styles.bankDescription}>
                  {isLoading
                    ? "Loading bank account..."
                    : hasBankAccount
                    ? `**** ${
                        stripeAccount?.external_accounts?.data[0]?.last4
                      } ${stripeAccount?.external_accounts?.data[0]?.currency.toUpperCase()}`
                    : "Connect your bank account to get paid out."}
                </div>
              </div>
              <div className={styles.buttonContainer}>
                {hasBankAccount ? (
                  <>
                    <Button
                      variant="tertiary"
                      text="Disconnect"
                      onClick={disconnectStripe}
                    />
                    <Button
                      variant={"secondary"}
                      text={"Manage"}
                      onClick={createLogin}
                    />
                  </>
                ) : (
                  <Button
                    variant={"secondary"}
                    text={"Connect"}
                    onClick={connectStripe}
                  />
                )}
              </div>
            </div>
            <div className={styles.stripeBank} style={{ marginTop: -20 }}>
              <div className={styles.iconContainer}>
                <img src={PP} alt="PayPal" />
              </div>
              <div className={styles.bankText}>
                <div className={styles.bankTitle}>
                  <span>PayPal</span>{" "}
                  {me?.merchantIdInPayPal && me?.merchantId && (
                    <AiFillCheckCircle color="#1ecc1e" size={16} />
                  )}
                </div>
                <div className={styles.bankDescription}>
                  {me?.merchantIdInPayPal && me?.merchantId
                    ? me?.merchantIdInPayPal
                    : "Connect your PayPal account."}
                </div>
              </div>
              <div className={styles.buttonContainer}>
                {me?.merchantIdInPayPal && me?.merchantId ? (
                  <Button
                    variant="tertiary"
                    text="Disconnect"
                    onClick={() => togglePopup("disconnectPayPal")}
                  />
                ) : (
                  <Button
                    variant="secondary"
                    text={"Connect PayPal"}
                    onClick={connectPayPal}
                  />
                )}
                {/* <div style={{ pointerEvents: "none" }}>
                  <Button variant="tertiary" text="Coming soon" />
                </div> */}
              </div>
            </div>
          </div>
        </Card>
      </div>
      {popups.disconnectPayPal && (
        <Modal
          onClose={() => togglePopup("disconnectPayPal")}
          title="Disconnect PayPal"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("disconnectPayPal");
            },
          }}
          footerRightButton2={{
            label: "Disconnect PayPal",
            onClick: () => {
              connectPayPal();
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Disconnecting your PayPal account will prevent you from offering
            PayPal services and products on your checkout pages.
            <br />
            Do you wish to continue?
          </div>
        </Modal>
      )}
    </form>
  );
};

export const PayPalCallback: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const merchantIdInPayPal = queryParams.get("merchantIdInPayPal");
  const merchantId = queryParams.get("merchantId");

  useEffect(() => {
    if (merchantId && merchantIdInPayPal) {
      Axios.post("/users/paypal-connect-success", {
        merchantId,
        merchantIdInPayPal,
      })
        .then(() => {
          // use window and navigate to the profile/payments page
          window.location.href = "/profile/payments";
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error while connecting PayPal. Please contact us.");
          navigate("/profile/payments?errorPayPal=true");
        });
    }
  }, []);

  return <PageLoader />;
};

export default Payments;
