import React, { Suspense, useEffect, useState } from "react";
import styles from "./Refund.module.scss";
import { useNavigate, Link, useParams } from "react-router-dom";
import Axios from "src/services/api";
import { Button, InfoIcon, Modal, SwitchInput } from "src/components";
import Me from "../Onboarding/me.png";
import { toast } from "react-hot-toast";
import usePopups from "src/services/usePopups";
import { Checkout } from "../Sales/Checkout";
import { useAuth0 } from "@auth0/auth0-react";
import { AiOutlineDelete, AiOutlineLink } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import { defaultProduct } from "../Product_/Product.consts";
import { PageLoader } from "src/components/page-loader";
import DefineRefund from "./steps/DefineRefund/DefineRefund";
import { RefundPolicy } from "../Sales/RefundPolicy";

export type RefundType = {
  name: string;
  policy: string;
  period: number;
  periodType: "days" | "weeks";
};

const defaultRefund: RefundType = {
  name: "",
  policy: "",
  period: 0,
  periodType: "days",
};

const Refund: React.FC = () => {
  const navigate = useNavigate();

  const [refund, setRefund] = React.useState<RefundType>(defaultRefund);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { popups, togglePopup } = usePopups(["deleteRefund"]);

  const [errors, setErrors] = useState<any>({});

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [mutating, setMutating] = useState<boolean>(false);

  const [canEdit, setCanEdit] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      setLoading(true);
      toast.promise(
        Axios.get("/refunds/" + id).then((res) => {
          setRefund(res.data);

          Axios.get("/orders/refund-policy/" + id)
            .then((response) => {
              setLoading(false);
              const orders = response.data;

              if (
                orders.some(
                  (order: any) => !order.isTransferred && !order.isRefunded
                )
              ) {
                setCanEdit(false);
              }
            })
            .catch((error) => {
              setLoading(false);
            });
        }),
        {
          loading: "Loading refund...",
          success: "Refund loaded!",
          error: (err) => {
            console.log(err);
            navigate("/refunds");
            return "Failed to load refund";
          },
        }
      );
    }
  }, [id]);

  const onSubmit = () => {
    if (!validateForm()) return;

    setMutating(true);
    if (id) {
      Axios.post("/refunds/" + id, refund)
        .then(() => {
          setMutating(false);
          toast.success("Refund updated!");
        })
        .catch((error) => {
          console.log(error);
          setMutating(false);
          toast.error("Failed to update refund");
        });
    } else {
      Axios.post("/refunds", refund)
        .then((response) => {
          setMutating(false);
          toast.success("Refund created!");
          navigate("/refunds/" + response.data._id);
        })
        .catch((error) => {
          console.log(error);
          setMutating(false);
          toast.error("Failed to create refund");
        });
    }
  };

  const validateForm = (): boolean => {
    let formErrors: any = {};

    if (currentStep === 1) {
      if (!refund.name) formErrors.name = "Please name your refund";
      if (!refund.policy || refund.policy === "<p></p>")
        formErrors.policy = "Please write a policy to show to your customers";
      if (!refund.period) formErrors.period = "Please set a refund period";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const clickNext = () => {
    if (!validateForm()) return;

    onSubmit();
  };

  const me = JSON.parse(localStorage.getItem("me")!);
  const [isVisible, setIsVisible] = useState(false);
  const [hiding, setHiding] = useState(false);
  useEffect(() => {
    if (me?.hideRefundGuide) return;

    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutId); // clean up the timeout
    };
  }, []);

  if (loading) return <PageLoader />;

  return (
    <div className={styles.pageContainer}>
      <div
        className={`${styles.notificationContainer} ${
          isVisible ? styles.visible : ""
        }`}
      >
        <img src={Me} alt="chain" />
        <div className={styles.notifText}>
          <div className={styles.title}>Learn how to use the refund page</div>
          <div className={styles.subTitle}>
            I created a video to show you how to use the refund page and what
            each setting does.
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              text="Watch video"
              onClick={() => {
                window.open(
                  "https://pf-vids.s3.amazonaws.com/refunds-tutorial.mp4",
                  "_blank"
                );
              }}
            />
            <Button
              variant="tertiary"
              text={hiding ? "Hiding..." : "No, thanks"}
              onClick={() => {
                setHiding(true);
                Axios.post("/users/hide-refund-guide").then((res) => {
                  setIsVisible(false);
                  setHiding(false);
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.formsContainer}>
        <div className={styles.form}>
          <div className={styles.navbar}>
            <h1>{id ? refund.name || "Edit refund" : "Create refund"} </h1>
            <div className={styles.actions}>
              {id && canEdit && (
                <Button
                  isIcon
                  variant="secondary"
                  text={
                    <AiOutlineDelete
                      size={18}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  }
                  onClick={() => {
                    togglePopup("deleteRefund");
                  }}
                />
              )}
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  navigate("/refunds");
                }}
              />
              {canEdit && (
                <Button
                  text={id ? "Edit refund" : "Create refund"}
                  disabled={mutating}
                  onClick={clickNext}
                />
              )}
            </div>
          </div>
          <div className={styles.stepIndicator}>
            <div
              className={styles.stepIndicatorItem}
              style={{
                width: `${(currentStep / 1) * 100}%`,
                transition: "width 0.5s ease-in-out",
              }}
            />
          </div>
          <div className={styles.formContent}>
            {currentStep === 1 ? (
              <DefineRefund
                refund={refund}
                setRefund={setRefund}
                errors={errors}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.previewContainer}>
        <div className={styles.previewNavbar}>
          {currentStep === 1 || currentStep === 2 ? (
            <h1>Refund preview</h1>
          ) : null}
        </div>
        <div className={styles.pagesPreview}>
          <div className={styles.pagePreview}>
            <Suspense fallback={<div>Loading...</div>}>
              <RefundPolicy _refundPolicy={refund.policy} _user={me} />
            </Suspense>
          </div>
        </div>
      </div>
      {popups.deleteRefund && (
        <Modal
          onClose={() => togglePopup("deleteRefund")}
          title="Delete Refund"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("deleteRefund");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Delete",
            onClick: () => {
              Axios.delete("/refunds/" + id)
                .then(() => {
                  togglePopup("deleteRefund");
                  toast.success("Refund deleted!");
                  navigate("/refunds");
                })
                .catch((error) => {
                  console.log(error);
                  togglePopup("deleteRefund");
                  toast.error("Failed to delete refund");
                });
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to delete this refund?
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Refund;
