import React, { useEffect, useState } from "react";
import styles from "./Onboarding.module.scss";
import SettingsStep from "./SettingsStep";
import { Button } from "src/components";
import HearAboutUs from "./HearAboutUs";
import { IoIosArrowBack } from "react-icons/io";
import Level from "./Level";
import Help from "./Help";
import Me from "./me.png";
import { X } from "react-feather";
import { toast } from "react-hot-toast";
import Axios from "src/services/api";

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState<any>({});
  const [show, setShow] = useState(true);
  const [loadingImport, setLoadingImport] = useState(false);
  const [doneImport, setDoneImport] = useState(false);
  const [verify, setVerify] = useState(true);

  useEffect(() => {
    setShow(true);
  }, [currentStep]);

  const [userState, setUserState] = useState({
    firstName: "",
    lastName: "",
    currency: "USD",
    contactEmail: "",
    country: "US",
    zipCode: "",
    subdomain: "",
    state: "",
    city: "",
    agree: false,
    hearAboutUs: "",
    taxId: "",
    level: "newbie",
    inviteCode: "",
  });

  const validateForm = () => {
    let formErrors: any = {};
    if (currentStep === 1) {
      if (!userState.firstName) {
        formErrors.firstName = "First name is required";
      }
      if (!userState.lastName) {
        formErrors.lastName = "Last name is required";
      }
      if (!userState.contactEmail) {
        formErrors.contactEmail = "Support email is required";
      }
      if (!userState.subdomain) {
        formErrors.subdomain = "Username is required";
      }
      // if (!userState.currency) {
      //   formErrors.currency = "Currency is required";
      // }

      if (!userState.agree) {
        formErrors.agree = "You must agree to the terms and conditions";
      }

      if (!verify) {
        formErrors.subdomain = "Pick a unique username";
      }

      if (!userState.country) {
        formErrors.country = "Country is required";
      }
      if (!userState.zipCode) {
        formErrors.zipCode = "Zip code is required";
      }
      if (!userState.state) {
        formErrors.state = "State is required";
      }
      if (!userState.city) {
        formErrors.city = "City is required";
      }
    }
    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const onClickNext = () => {
    if (!validateForm()) return;

    if (currentStep === 4) {
      toast.promise(
        Axios.post("/users/finish-onboarding", {
          ...userState,
          subdomain: userState.subdomain.toLowerCase(),
        }).then((res) => {
          window.location.href = "/guide";
        }),
        {
          loading: "Saving data...",
          success: "Saved!",
          error: "Error while saving.",
        }
      );
      return;
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <div className={styles.onboardingPage}>
      <div className={styles.nav}>
        <img
          className={styles.logoImageText}
          src={process.env.PUBLIC_URL + "/pf-logo.svg"}
          alt="Pocketsflow"
        />
      </div>
      <div className={styles.pageContent}>
        <div className={styles.stepIndicator}>
          <div
            className={styles.indicator}
            style={{
              width: `${(currentStep / 4) * 100}%`,
            }}
          />
        </div>
        {currentStep > 1 && (
          <div
            className={styles.back}
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            <IoIosArrowBack size={24} />
          </div>
        )}
        {currentStep === 1 ? (
          <SettingsStep
            userState={userState}
            setUserState={setUserState}
            errors={errors}
            setVerify={setVerify}
          />
        ) : currentStep === 2 ? (
          <HearAboutUs userState={userState} setUserState={setUserState} />
        ) : currentStep === 3 ? (
          <Level userState={userState} setUserState={setUserState} />
        ) : (
          <Help userState={userState} setUserState={setUserState} />
        )}
        <div className={styles.buttonContainer}>
          <Button
            onClick={onClickNext}
            text={currentStep === 4 ? "Finish onboarding!" : "Next Step"}
          />
        </div>
      </div>
      {show && currentStep === 4 && userState.level !== "newbie" && (
        <div className={styles.import}>
          <div className={styles.close}>
            <X size={16} onClick={() => setShow(false)} />
          </div>

          <div className={styles.text}>
            <img src={Me} alt="chain" />
            <span>
              <div>
                Since you have some experience, you can import all of your
                products from Gumroad or LemonSqueezy on the products page!
              </div>
              <div className={loadingImport || doneImport ? styles.btn : ""}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setLoadingImport(true);
                    Axios.post("/users/request-import", {}).then(() => {
                      setLoadingImport(false);
                      setDoneImport(true);
                      toast.success(
                        <>
                          Requested product import successfully!<br></br>We will
                          be in touch with you by email soon.
                        </>,
                        {
                          duration: 10000,
                        }
                      );
                    });
                  }}
                  text={
                    loadingImport
                      ? "Requesting import..."
                      : doneImport
                      ? "I will send you an email very soon ✅"
                      : "Import my products from another platform"
                  }
                />
              </div>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Onboarding;
