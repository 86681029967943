import React, { useEffect } from "react";
import styles from "./Reviews.module.scss";
import { PageLayout } from "src/components/PageLayout";
import { PageTitle } from "src/components/PageTitle";
import { Link, useNavigate } from "react-router-dom";
import Axios from "src/services/api";
import { Product } from "src/models/product";
import { Button, EmptyState, FormGroup, Select } from "src/components";
import { Rating } from "react-simple-star-rating";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import { Count } from "../Customers/Customers";

const columns = ["Customer email", "Product", "Date", "Review", "Rating"];

const Reviews: React.FC = () => {
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = React.useState<any>("all");
  const [products, setProducts] = React.useState<any[]>([]);
  const [reviews, setReviews] = React.useState<any[]>([]);
  const [filteredReviews, setFilteredReviews] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [expandedReviews, setExpandedReviews] = React.useState<
    Record<number, boolean>
  >({});

  useEffect(() => {
    Axios.get("/reviews").then((response) => {
      setReviews(response.data);
      setFilteredReviews(response.data);
      // get products
      Axios.get("/products").then((res) => {
        setProducts(
          res.data.map((product: any) => {
            return {
              ...product,
              label: product.name,
              value: product._id,
            };
          })
        );
        setLoading(false);
      });
    });
  }, []);

  const handleSeeMoreClick = (index: number) => {
    setExpandedReviews((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    if (selectedProduct === "all") {
      setFilteredReviews(reviews);
    } else {
      setFilteredReviews(
        reviews.filter((review) => review.productId === selectedProduct)
      );
    }
  }, [selectedProduct, reviews]);

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div className={styles.filter}>
            {products?.length > 0 && (
              <FormGroup label="Filter by product">
                <Select
                  selectedValue={selectedProduct}
                  onChange={(value) => setSelectedProduct(value)}
                  options={[{ label: "All products", value: "all" }].concat(
                    ...products
                  )}
                />
              </FormGroup>
            )}
          </div>
        }
      >
        Reviews
      </PageTitle>
      {loading || filteredReviews?.length === 0 ? (
        <EmptyState
          loading={loading}
          title="Get your first review"
          subTitle="Create and share your products to get reviews from customers. Your reviews will show up here"
          buttonText={products?.length === 0 ? "Create product" : undefined}
          buttonClick={() => {
            if (products?.length === 0) {
              navigate("/products/new");
            }
          }}
        />
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.productsTable}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredReviews.map((review, index) => {
                  const isLongReview = review.review?.length > 100; // or whatever limit you want
                  const showFullReview =
                    expandedReviews[index] || !isLongReview;
                  const displayReview = showFullReview
                    ? review.review
                    : `${review.review.slice(0, 100)}...`;

                  return (
                    <tr key={index}>
                      <td style={{ width: "10%" }}>
                        <Link
                          className={styles.productNameContainer}
                          to={`/customers/${review.buyerId}`}
                        >
                          {review.buyerEmail}
                        </Link>
                      </td>
                      <td style={{ width: "13%" }}>
                        <Link
                          className={styles.productNameContainer}
                          to={`/products/${review.productId}`}
                        >
                          {
                            products.find((p) => p._id === review.productId)
                              ?.name
                          }
                        </Link>
                      </td>
                      <td style={{ width: "7%" }}>
                        <div>{dayjs(review.createdAt).fromNow()}</div>
                      </td>
                      <td>
                        <div>{displayReview}</div>
                        {isLongReview && (
                          <button
                            className={styles.seeMoreButton}
                            onClick={() => handleSeeMoreClick(index)}
                          >
                            {showFullReview ? "See less..." : "See more..."}
                          </button>
                        )}
                      </td>
                      <td>
                        <div className={styles.ratingContainer}>
                          <Rating
                            style={{
                              pointerEvents: "none",
                            }}
                            initialValue={review.rating}
                            size={20}
                            transition
                            disableFillHover
                            allowFraction
                            allowHover={false}
                          />
                          <div className={styles.ratingNumber}>
                            {review.rating}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Count items={filteredReviews} />
        </>
      )}
      {/* <div className={styles.tableHeader}>
        {columns.map((column, index) => (
          <div key={index} className={styles.tableHeaderItem}>
            {column}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Reviews;
