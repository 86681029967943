import React, { useEffect, useState } from "react";
import styles from "./AffiliatedProducts.module.scss";
import { PageLayout } from "src/components/PageLayout";
import { PageTitle } from "src/components/PageTitle";
import { Link, useParams } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import { Button, Card, Modal, TextInput } from "src/components";
import { saveAs } from "file-saver";
import axios from "axios";
import { BiBox, BiCalendar, BiMoney } from "react-icons/bi";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import usePopups from "src/services/usePopups";

const columns = [
  "Product owner",
  "Product(s)",
  "Commission",
  "Created at",
  "Active",
  "Actions",
];

const productsColumns = [
  "Product",
  "Commission",
  "Orders",
  "Sales",
  "Commission cut",
];

interface CountryInfo {
  name: string;
  emoji: string;
  unicode: string;
  image: string;
}

export type Countries = Record<string, CountryInfo>;

const AffiliatedProducts: React.FC = () => {
  const [affiliatedProducts, setAffiliatedProducts] = React.useState<any[]>([]);
  const [affiliate, setAffiliate] = useState<string>("");
  const navigate = useNavigate();
  const { popups, togglePopup } = usePopups(["urls"]);
  const [prods, setProds] = useState<any[]>([]);

  const [productsWithSales, setProductsWithSales] = useState<any[]>([]);

  useEffect(() => {
    Axios.get("/affiliated-products").then((response) => {
      setAffiliatedProducts(response.data);
      return Axios.get("/affiliated-products/sales").then((res) => {
        // we get data like this: [{ ...affiliate, sales: [{ ...sale, productId, amount, affiliateCut }] }] group all sales by productId and sum the amounts and affiliateCuts for each product.

        setProductsWithSales(aggregateSales(res.data));
      });
    });
  }, []);

  return (
    <>
      <PageTitle>Your Affiliated Products</PageTitle>
      <div className={styles.tableContainer}>
        <table className={styles.productsTable}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {affiliatedProducts?.map((affiliate, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => {
                    // navigate(`/affiliatedProducts/${affiliate._id}`);
                  }}
                >
                  <td style={{ width: "13%" }}>{affiliate.owner}</td>
                  <td>
                    {affiliate.productIds.length === 1 ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div>{affiliate.products[0].name}</div>
                      </div>
                    ) : (
                      <div>{affiliate.productIds.length + " Products"}</div>
                    )}
                  </td>
                  <td>
                    <div>{affiliate.commissionRate}%</div>
                  </td>
                  <td>
                    <div>{dayjs(affiliate.createdAt).fromNow()}</div>
                  </td>
                  <td>
                    <div>{affiliate.active ? "Active" : "Inactive"}</div>
                  </td>
                  <td>
                    <div
                      className={styles.actions}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Button
                        variant="secondary"
                        onClick={() => {
                          if (affiliate.productIds.length === 1) {
                            navigator.clipboard.writeText(
                              `${window.location.origin}/prod/${affiliate.products[0]._id}?a=${affiliate._id}`
                            );
                            toast.success("Copied to clipboard");
                          } else {
                            setProds(affiliate.products);
                            setAffiliate(affiliate._id);
                            togglePopup("urls");
                          }
                        }}
                        text={
                          "Copy URL" +
                          (affiliate.productIds.length === 1 ? "" : "s")
                        }
                      />
                      {/* <Button variant="danger" text={"Delete"} /> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* ———————————————————————————————————————————————————————————————————————————————————————————————— */}
      <div className={styles.innerPageSection}>
        <h2 className={styles.innerTitle}>Sales per product</h2>
        <div className={styles.tableContainer}>
          <table className={styles.productsTable}>
            <thead>
              <tr>
                {productsColumns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {productsWithSales?.map((prod, index) => {
                return (
                  <tr key={index}>
                    <td style={{ width: "43%" }}>
                      <div className={styles.productNameContainer}>
                        <img
                          src={
                            prod.product.thumbnail ||
                            "https://via.placeholder.com/600?text=Product+Image"
                          }
                        />
                        {prod.product.name}
                      </div>
                    </td>
                    <td>
                      <div>{prod.commissionRate}%</div>
                    </td>
                    <td>
                      <div>{numeral(prod.salesCount).format()}</div>
                    </td>
                    <td>
                      <div>{formatCurrency(prod.totalAmount / 100)}</div>
                    </td>
                    <td>
                      <div>{formatCurrency(prod.totalAffiliateCut / 100)}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {popups.urls && (
        <Modal
          onClose={() => togglePopup("urls")}
          title="Copy URLs"
          footerRightButton1={{}}
          footerRightButton2={{
            label: "Close",
            onClick: () => {
              setProds([]);
              togglePopup("urls");
            },
          }}
        >
          <div className={styles.productsList}>
            {prods.map((product) => (
              <div key={product._id} className={styles.productItem}>
                <div className={styles.productNameContainer}>
                  <img
                    src={product.thumbnail || "https://via.placeholder.com/120"}
                  />
                  <span className={styles.productName}>{product.name}</span>
                </div>
                <div className={styles.productPrice}>
                  {formatCurrency(product.price)}
                </div>
                <div className={styles.copyButton}>
                  <Button
                    variant="secondary"
                    text="Copy URL"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}/prod/${product._id}?a=${affiliate}`
                      );
                      toast.success("Copied to clipboard");
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

function aggregateSales(data: any) {
  let result: any = {};

  // Iterate over each object in the array
  for (let obj of data) {
    // Iterate over the sales array of each object
    for (let sale of obj.sales) {
      // If the product ID doesn't exist in the result object, add it
      if (!result[sale.productId]) {
        result[sale.productId] = {
          totalAmount: 0,
          totalAffiliateCut: 0,
          salesCount: 0,
          commissionRate: obj.commissionRate,
        };
      }

      // Add the sale amount and affiliate cut to the respective totals
      result[sale.productId].totalAmount += sale.amount;
      result[sale.productId].totalAffiliateCut += sale.affiliateCut;
      result[sale.productId].salesCount++;
      result[sale.productId].commissionRate = obj.commissionRate;
      result[sale.productId].product = sale.product;
    }
  }

  // Convert result object to array
  let resultArray = Object.entries(result).map(
    ([
      productId,
      // @ts-ignore
      { totalAmount, totalAffiliateCut, salesCount, commissionRate, product },
    ]) => ({
      productId,
      totalAmount,
      totalAffiliateCut: totalAffiliateCut * 100,
      salesCount,
      commissionRate,
      product,
    })
  );

  return resultArray;
}
export default AffiliatedProducts;
