import React from "react";
import styles from "./Onboarding.module.scss";
import { RadioInput } from "src/components/RadioInput";

const Level = ({ userState, setUserState }: any) => {
  const onChange = (key: string, value: string) => {
    setUserState({ ...userState, [key]: value });
  };
  return (
    <div className={styles.step}>
      <div className={styles.title}>Do you have any prior experience?</div>
      <div className={styles.subTitle}>
        Are you just starting out or do you have some experience selling digital
        products online? Based on your answer, the next step will be different
        :)
      </div>
      <div className={styles.content}>
        <div className={styles.radioInputContainer}>
          <RadioInput
            id="level-1"
            name="level"
            value="newbie"
            checked={userState.level === "newbie"}
            onChange={(value: string) => onChange("level", value)}
          />
          <div className={styles.radioInputLabel}>Newbie</div>
        </div>
        <div className={styles.radioInputContainer}>
          <RadioInput
            id="level-2"
            name="level"
            value="startedRecently"
            checked={userState.level === "startedRecently"}
            onChange={(value: string) => onChange("level", value)}
          />
          <div className={styles.radioInputLabel}>Recently started</div>
        </div>
        <div className={styles.radioInputContainer}>
          <RadioInput
            id="level-3"
            name="level"
            value="experienced"
            checked={userState.level === "experienced"}
            onChange={(value: string) => onChange("level", value)}
          />
          <div className={styles.radioInputLabel}>Enough experience</div>
        </div>
      </div>
    </div>
  );
};

export default Level;
