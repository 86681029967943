import { useState, useEffect } from "react";

type TaxType = "Sales Tax" | "VAT" | "GST" | "GST/HST" | null;

const useTaxType = (countryCode: string): TaxType => {
  const [taxType, setTaxType] = useState<TaxType>(null);

  useEffect(() => {
    const getTaxType = (country: string): TaxType => {
      switch (country) {
        case "US":
          return "Sales Tax";
        case "DE": // Germany
        case "FR": // France
        case "IT": // Italy
        case "ES": // Spain
        case "NL": // Netherlands
        case "BE": // Belgium
        case "IE": // Ireland
        case "AT": // Austria
        case "PT": // Portugal
        case "FI": // Finland
        case "SE": // Sweden
        case "DK": // Denmark
        case "PL": // Poland
        case "GR": // Greece
        case "CZ": // Czech Republic
        case "RO": // Romania
        case "HU": // Hungary
        case "BG": // Bulgaria
        case "SK": // Slovakia
        case "LT": // Lithuania
        case "HR": // Croatia
        case "SI": // Slovenia
        case "EE": // Estonia
        case "LV": // Latvia
        case "CY": // Cyprus
        case "LU": // Luxembourg
        case "MT": // Malta
          return "VAT";
        case "IN":
          return "GST";
        case "GB": // UK
          return "VAT";
        case "CA":
          return "GST/HST";
        case "AU":
          return "GST";
        default:
          return "VAT";
      }
    };

    setTaxType(getTaxType(countryCode));
  }, [countryCode]);

  return taxType;
};

export default useTaxType;
