import React from "react";
import md5 from "crypto-js/md5";

type GravatarProps = {
  email: string;
  size?: number;
};

const Gravatar: React.FC<GravatarProps> = ({ email, size = 200 }) => {
  const hash = email ? md5(email.trim().toLowerCase()).toString() : '';
  const url = `https://www.gravatar.com/avatar/${hash}?s=${size}`;

  return <img src={url} alt="Gravatar" />;
};

export default Gravatar;
