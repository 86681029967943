import { useEffect, useMemo, useState } from "react";
import grapesjs, { Editor } from "grapesjs";
import GjsEditor, { Canvas } from "@grapesjs/react";

import ProductPageQuestion from "./ProductPageQuestion";
import styles from "./ProductPage.module.scss";
import "./ProductPage.scss";
import WebpagePlugin from "grapesjs-preset-webpage";
import plugin from "grapesjs-blocks-basic";
import navbarPlugin from "grapesjs-navbar";
import pluginCountdown from "grapesjs-component-countdown";
import pluginGradient from "grapesjs-style-gradient";
// @ts-ignore
import pluginTabs from "grapesjs-tabs";
import h1 from "./icons/h1.svg";
import {
  addButton,
  addCtaButton,
  addFonts,
  addHeaders,
  addThumbnail,
  addEmptySeparator,
  addFAQs,
} from "./components";
import ProductPageForm from "./ProductPageForm";

const ProductPage = ({
  onNextPage,
  images,
  setImages,
  errors,
  product,
  setProduct,

  setBigPreview,
  setIsYes,
}: any) => {
  const [isQuestion, setIsQuestion] = useState<boolean>(true);
  const [answer, setAnswer] = useState<string>("yes");

  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  useEffect(() => {
    if (!isQuestion && answer === "yes") {
      // @ts-ignore
      // shutdown intercom
      window.Intercom("shutdown");
    }

    return () => {
      // @ts-ignore
      // boot intercom
      window.Intercom("boot", {
        user_id: me?.userId,
        stripe_connected_account_id: me?.stripeConnectedAccountId
          ? me?.stripeConnectedAccountId
          : "",
        api_base: "https://api-iam.intercom.io",
        app_id: "k4iliebs",
        name: me?.firstName,
      });
    };
  }, [isQuestion]);

  useEffect(() => {
    if (product.hasProductPage === "no") {
      setIsQuestion(true);
      setAnswer("no");
      return;
    }
  }, []);

  useEffect(() => {
    if (product.hasProductPage === "unknown") {
      setIsQuestion(true);
      setAnswer("yes");
      return;
    }

    if (product.hasProductPage === "yes") {
      setIsQuestion(false);
      setAnswer("yes");
      return;
    }
  }, [product.hasProductPage]);

  const onSubmit = () => {
    setProduct({ ...product, hasProductPage: answer });

    if (answer === "no") {
      setIsQuestion(false);
      setAnswer("no");
      onNextPage();
      return;
    }
  };

  return isQuestion ? (
    <ProductPageQuestion
      answer={answer}
      setAnswer={setAnswer}
      onSubmit={onSubmit}
    />
  ) : (
    <ProductPageForm
      images={images}
      setImages={setImages}
      errors={errors}
      product={product}
      setProduct={setProduct}
      setBigPreview={setBigPreview}
    />
    // <div className={styles.editorWrapper}>
    //   {/* ADD BLOCKS (TEXTS, BUY BUTTON) AND TOOLBAR */}
    //   <GjsEditor
    //     grapesjs={grapesjs}
    //     grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
    //     onEditor={onEditor}
    //     options={{
    //       height: "100vh",
    //       storageManager: false,
    //       fromElement: true,
    //       canvas: {
    //         styles: [
    //           "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Oswald:wght@400;700&family=Source+Sans+Pro:wght@400;700&family=Raleway:wght@400;700&family=PT+Sans:wght@400;700&family=Merriweather:wght@400;700&family=Roboto+Condensed:wght@400;700&family=Ubuntu:wght@400;700&family=Noto+Sans:wght@400;700&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;700&family=Lora:wght@400;700&family=PT+Serif:wght@400;700&family=Muli:wght@400;700&family=Nunito:wght@400;700&family=Fira+Sans:wght@400;700&family=Work+Sans:wght@400;700&family=Titillium+Web:wght@400;700&family=Dosis:wght@400;700&family=Hind:wght@400;700&family=Arimo:wght@400;700&family=Cabin:wght@400;700&family=Exo+2:wght@400;700&family=Inconsolata:wght@400;700&family=Karla:wght@400;700&family=Heebo:wght@400;700&family=Quicksand:wght@400;700&family=Rubik:wght@400;700&family=Josefin+Sans:wght@400;700&family=Barlow:wght@400;700&family=Anton:wght@400;700&family=Pacifico:wght@400;700&family=Abel:wght@400;700&family=Yanone+Kaffeesatz:wght@400;700&family=Indie+Flower:wght@400;700&family=Oxygen:wght@400;700&family=Nanum+Gothic:wght@400;700&family=Rajdhani:wght@400;700&family=Kanit:wght@400;700&family=Questrial:wght@400;700&family=Balsamiq+Sans:wght@400;700&family=Manrope:wght@400;700&family=Varela+Round:wght@400;700&family=Crimson+Text:wght@400;700&family=Asap:wght@400;700&family=Signika:wght@400;700&family=Cardo:wght@400;700&family=Kreon:wght@400;700&family=Space+Mono:wght@400;700&family=Fjalla+One:wght@400;700&family=Concert+One:wght@400;700&family=Francois+One:wght@400;700&family=Righteous:wght@400;700&family=Cinzel:wght@400;700&display=swap",
    //         ],
    //       },
    //     }}
    //     plugins={[
    //       WebpagePlugin,
    //       plugin,
    //       navbarPlugin,
    //       pluginCountdown,
    //       pluginGradient,
    //     ]}
    //   ></GjsEditor>
    // </div>
  );
};

export default ProductPage;
