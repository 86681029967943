import React from "react";
import styles from "./Button.module.scss";

interface ButtonProps {
  text: React.ReactNode | string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  variant?: "primary" | "secondary" | "danger" | "tertiary" | "success";
  loading?: boolean;
  isIcon?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  type = "button",
  disabled = false,
  loading = false,
  variant = "primary",
  isIcon = false,
}) => {
  const buttonClasses = `${styles.button} ${styles[variant]} ${disabled ? styles.disabled : ""} ${isIcon ? styles.icon : ""}`;

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
    >
      {loading ? "Loading..." : text}
    </button>
  );
};

export default Button;
