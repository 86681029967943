import React, { Fragment, Suspense, useMemo, useState } from "react";
import styles from "src/pages/Product_/Product.module.scss";
import styles2 from "./ProductPage.module.scss";
import { Button, FormGroup, Select, SwitchInput, TipTap } from "src/components";
import { ColorInput } from "src/pages/Product/components/ColorsModal/ColorsModal";
import TogglableArea from "../DefineProduct/TogglableArea";
import MultipleUploader from "../DefineProduct/MultipleUploader";
import { Check, X } from "react-feather";

import image1 from "./prod-page-01.png";
import image2 from "./prod-page-02.png";
import image3 from "./prod-page-03.png";
import { ProductType } from "../../Product.types";

const options = [
  { value: "buyNow", label: "Buy now" },
  { value: "iWantThis", label: "I want this" },
  { value: "buyForPrice", label: "Buy for {price}" },
  { value: "getNowFree", label: "Get it free" },
  { value: "buyFor0", label: "Buy now for $0" },
];

const designs = [
  {
    id: 1,
    image: image1,
  },
  {
    id: 2,
    image: image2,
  },
  {
    id: 3,
    image: image3,
  },
];
const ProductPageForm = ({
  images,
  setImages,
  errors,
  product,
  setProduct,

  setBigPreview,
}: {
  product: ProductType;
  setProduct: React.Dispatch<React.SetStateAction<ProductType>>;

  id?: string;
  errors: any;

  images: File[] | null;
  setImages: React.Dispatch<React.SetStateAction<File[] | null>>;

  setBigPreview: any;
}) => {
  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  const onChange = (key: string, value: any) => {
    setProduct({ ...product, [key]: value });
  };
  const onImagesChange = (files: File[] | null) => {
    setImages(files);
  };

  return (
    <>
      <div className={styles.step}>
        <div className={styles.titleContainer}>
          <h1>Customize the product page</h1>
          <Button
            onClick={() => {
              setProduct({ ...product, hasProductPage: "unknown" });
            }}
            text="Remove product page"
            variant="secondary"
          />
        </div>
        <p className={styles.desc}>
          Add a product description, choose a design for your product page,
          upload images, change colors, and modify the call-to-action text.
        </p>
        <div className={styles.form}>
          <TogglableArea
            title="Images"
            subTitle="Upload images of your product. They will be shown in your product page as carousel."
          >
            <MultipleUploader
              onChange={onImagesChange}
              value={images}
              text="Supports JPEG, PNG, GIF. Maximum file size: 4MB."
              type="image"
            />
          </TogglableArea>
          <FormGroup label="Description" required error={errors.description}>
            <TipTap
              error={errors.description}
              content={product.description}
              onChange={(content: string) => onChange("description", content)}
            />
          </FormGroup>
          <TogglableArea
            title="Page design"
            subTitle="Choose a page design that you like and that fits your product."
          >
            <FormGroup label="Page design">
              <div className={styles.choiceGrid}>
                {designs.map((design) => (
                  <div
                    key={design.id}
                    className={`${styles.choice} ${
                      product.design === design.id ? styles.selected : ""
                    }`}
                    onClick={() => {
                      // if (design.image === soon) return;

                      setProduct({
                        ...product,
                        design: design.id as any,
                        colors:
                          design.id === 1 || design.id === 3
                            ? [
                                {
                                  id: "title",
                                  name: "Title",
                                  value: "#000000",
                                },
                                {
                                  id: "buttons",
                                  name: "Buttons background",
                                  value: "#6772e5",
                                },
                                {
                                  id: "buttonsText",
                                  name: "Buttons text",
                                  value: "#FFFFFF",
                                },
                                {
                                  id: "background",
                                  name: "Background",
                                  value: "#FFFFFF",
                                },
                                {
                                  id: "text",
                                  name: "Text",
                                  value: "#000000",
                                },
                                {
                                  id: "borders",
                                  name: "Borders",
                                  value: "#cfd7df",
                                },
                                {
                                  id: "heroBackground",
                                  name: "Hero background",
                                  value: "#6772e5",
                                },
                                {
                                  id: "cardBackground",
                                  name: "Card background",
                                  value: "#FFFFFF",
                                },
                              ]
                            : design.id === 2
                            ? [
                                {
                                  id: "title",
                                  name: "Title",
                                  value: "#FFFFFF",
                                },
                                {
                                  id: "buttons",
                                  name: "Buttons background",
                                  value: "#6772e5",
                                },
                                {
                                  id: "buttonsText",
                                  name: "Buttons text",
                                  value: "#FFFFFF",
                                },
                                {
                                  id: "background",
                                  name: "Background",
                                  value: "#FFFFFF",
                                },
                                {
                                  id: "text",
                                  name: "Text",
                                  value: "#000000",
                                },
                                {
                                  id: "borders",
                                  name: "Borders",
                                  value: "#cfd7df",
                                },
                                {
                                  id: "heroBackground",
                                  name: "Hero background",
                                  value: "#2E3275",
                                },
                                {
                                  id: "cardBackground",
                                  name: "Card background",
                                  value: "#FFFFFF",
                                },
                              ]
                            : product.colors,
                      });
                    }}
                  >
                    <div className={styles.imageContainer}>
                      <img src={design.image} />
                    </div>
                    {product.design === design.id && (
                      <div className={styles.check}>
                        <Check className={styles.icon} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </FormGroup>
            <FormGroup label="Colors">
              <div className={styles.colorGrid}>
                {product.colors.map((color, index) =>
                  ((product.design === 1 || product.design === 3) &&
                    color.id === "heroBackground") ||
                  ((product.design === 1 || product.design === 3) &&
                    color.id === "cardBackground") ? (
                    <Fragment key={index}></Fragment>
                  ) : (
                    <FormGroup label={color.name} key={index} small>
                      <ColorInput
                        value={color.value}
                        onChange={(value) => {
                          console.log(value);

                          onChange(
                            "colors",
                            product.colors.map((c) => {
                              return c.id === color.id
                                ? {
                                    ...c,
                                    value,
                                  }
                                : c;
                            }) as any
                          );
                        }}
                      />
                    </FormGroup>
                  )
                )}
              </div>
            </FormGroup>
            <div className={styles.buttonContainer}>
              <Button
                text={"Use global colors"}
                onClick={() => onChange("colors", me?.colors)}
              />
              {/* a link to edit global colors in new tab */}
              <Button
                text={"Edit global colors"}
                onClick={() => {
                  // window.open("/profile/global", "_blank")
                  const a = document.createElement("a");
                  a.href = "/profile/global";
                  a.rel = "noopener noreferrer";
                  a.click();
                }}
                variant="tertiary"
              />
            </div>
          </TogglableArea>
          <TogglableArea
            title="Call to action"
            subTitle="Change the text of the call-to-action buttons."
          >
            <FormGroup label="Call to action button">
              <Select
                options={options}
                onChange={(value) => onChange("ctaText", value)}
                selectedValue={product.ctaText}
              />
            </FormGroup>
          </TogglableArea>
          <TogglableArea
            title="Page settings"
            subTitle="Toggle the sales counter and the reviews section."
          >
            <div className={styles.formGrid}>
              <FormGroup label="Show sales counter" inline>
                <SwitchInput
                  name="showReviews"
                  checked={product.showSales}
                  onChange={(checked) => {
                    onChange("showSales", checked);
                  }}
                  id="showSales"
                />
              </FormGroup>
              <FormGroup label="Show reviews section" inline>
                <SwitchInput
                  name="showReviews"
                  checked={product.showReviews}
                  onChange={(checked) => {
                    onChange("showReviews", checked);
                  }}
                  id="showReviews"
                />
              </FormGroup>
            </div>
          </TogglableArea>
        </div>
      </div>
    </>
  );
};

export default ProductPageForm;
