import React, { useEffect, useState } from "react";
import styles from "./Customer.module.scss";
import { PageLayout } from "src/components/PageLayout";
import { PageTitle } from "src/components/PageTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import { Button, Card, EmptyState, TextInput } from "src/components";
import { saveAs } from "file-saver";
import axios from "axios";
import { BiBox, BiCalendar, BiMoney } from "react-icons/bi";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-hot-toast";
import Gravatar from "src/components/Gravatar";

const statsDefault = [
  {
    title: "Sales",
    id: "sales",
    value: "0",
    icon: <BiMoney size={28} />,
    color: "#dcffdb",
    type: "currency",
  },
  {
    title: "Orders",
    id: "orders",
    value: 0,
    icon: <BiBox size={28} />,
    color: "#f3e2ff",
    type: "number",
  },
  {
    title: "Customer since",
    id: "since",
    value: 0,
    icon: <BiCalendar size={28} />,
    color: "#dcf1ff",
    type: "date",
  },
];

const columns = [
  "ID",
  "Product",
  "Gross Revenue",
  "Fee",
  "Net Revenue",
  "Created at",
];

const reviewsColumns = ["Product", "Date", "Review", "Rating"];

interface CountryInfo {
  name: string;
  emoji: string;
  unicode: string;
  image: string;
}

export type Countries = Record<string, CountryInfo>;

const Customer: React.FC = () => {
  const [customer, setCustomer] = React.useState<any>({});
  const [stats, setStats] = useState(statsDefault);
  const [orders, setOrders] = React.useState<any[]>([]);
  const [reviews, setReviews] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const [expandedReviews, setExpandedReviews] = React.useState<
    Record<number, boolean>
  >({});
  const { id } = useParams<{ id: string }>();

  const handleSeeMoreClick = (index: number) => {
    setExpandedReviews((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    Axios.get("/customers/" + id).then((response) => {
      setCustomer(response.data.customer);
      const statsData = response.data.stats.map((stat: any) => {
        const s: any = statsDefault.find((s) => s.id === stat.id);
        return {
          ...s,
          value: stat.value,
        };
      });
      setStats(statsData);
      setOrders(response.data.orders);
      setReviews(response.data.reviews);
      setLoading(false);
    });
  }, []);

  const [countries, setCountries] = useState<Countries | null>(null);

  useEffect(() => {
    import("../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);

  return (
    <div className={styles.bigPageContainer}>
      {loading ? (
        <EmptyState loading />
      ) : (
        <>
          <PageTitle
            actions={
              <div className={styles.country}>
                <div className={styles.emoji}>
                  {countries && countries[customer.country]?.emoji}{" "}
                </div>
                {countries && countries[customer.country]?.name}
              </div>
            }
          >
            <div className={styles.customerTitle}>
              <Gravatar email={customer.buyerEmail} size={100} />
              {customer.buyerEmail}
            </div>
          </PageTitle>
          <div className={styles.pageContainer}>
            <div className={styles.topGrid}>
              {stats.map((stat) => (
                <Card key={stat.id}>
                  <div className={styles.stat}>
                    <div
                      className={styles.icon}
                      style={{
                        backgroundColor: stat.color,
                      }}
                    >
                      {stat.icon}
                    </div>
                    <div className={styles.textSide}>
                      <div className={styles.statTitle}>{stat.title}</div>
                      <div className={styles.statValue}>
                        {stat.type === "currency"
                          ? formatCurrency(stat.value as number)
                          : stat.type === "percentage"
                          ? `${numeral(stat.value).format("0[.]00")}%`
                          : stat.type === "number"
                          ? numeral(stat.value).format("0[.]00")
                          : stat.type === "date"
                          ? dayjs(stat.value).format("DD MMM YYYY")
                          : stat.value}
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
            <div className={styles.tableContainer}>
              <div className={styles.cardTitle}>Orders</div>
              <table className={styles.productsTable}>
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((order, index) => {
                    return (
                      <tr key={index}>
                        <td
                          onClick={() => {
                            navigate(`/orders/${order._id}`);
                          }}
                        >
                          <div className={styles.orderId}>
                            <div className={styles.ellipsis}>
                              #{order._id.slice(-5).toUpperCase()}
                            </div>
                            {order.isRefunded && (
                              <div className={styles.refunded}>Refunded</div>
                            )}
                          </div>
                        </td>
                        <td>
                          <Link
                            className={styles.productNameContainer}
                            to={`/products/${order.product?._id}`}
                          >
                            <div className={styles.ellipsis}>
                              {order.product?.name || "Untitled"}
                            </div>
                          </Link>
                        </td>
                        <td
                          onClick={() => {
                            navigate(`/orders/${order._id}`);
                          }}
                        >
                          <div className={styles.ellipsis}>
                            {formatCurrency(order.grossAmount)}
                          </div>
                        </td>
                        <td
                          onClick={() => {
                            navigate(`/orders/${order._id}`);
                          }}
                        >
                          <div className={styles.ellipsis}>
                            {formatCurrency(order.fee)}
                          </div>
                        </td>
                        <td
                          onClick={() => {
                            navigate(`/orders/${order._id}`);
                          }}
                        >
                          <div className={styles.ellipsis}>
                            {formatCurrency(order.netAmount)}
                          </div>
                        </td>
                        <td
                          onClick={() => {
                            navigate(`/orders/${order._id}`);
                          }}
                        >
                          <div className={styles.ellipsis}>
                            {dayjs(order.createdAt).fromNow()}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {reviews.length > 0 && (
              <div className={styles.tableContainer}>
                <div className={styles.cardTitle}>Reviews</div>
                <table
                  className={styles.productsTable + " " + styles.reviewsTable}
                >
                  <thead>
                    <tr>
                      {reviewsColumns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {reviews?.map((review, index) => {
                      console.log(review);
                      const isLongReview = review.review?.length > 100; // or whatever limit you want
                      const showFullReview =
                        expandedReviews[index] || !isLongReview;
                      const displayReview = showFullReview
                        ? review.review
                        : `${review.review.slice(0, 100)}...`;

                      return (
                        <tr key={index}>
                          <td style={{ width: "13%" }}>
                            <Link
                              className={styles.productNameContainer}
                              to={`/products/${review.productId}`}
                            >
                              {review.product.name}
                            </Link>
                          </td>
                          <td style={{ width: "7%" }}>
                            <div>{dayjs(review.createdAt).fromNow()}</div>
                          </td>
                          <td>
                            <div>{displayReview}</div>
                            {isLongReview && (
                              <button
                                className={styles.seeMoreButton}
                                onClick={() => handleSeeMoreClick(index)}
                              >
                                {showFullReview ? "See less..." : "See more..."}
                              </button>
                            )}
                          </td>
                          <td>
                            <div className={styles.ratingContainer}>
                              <Rating
                                style={{
                                  pointerEvents: "none",
                                }}
                                initialValue={review.rating}
                                size={20}
                                transition
                                disableFillHover
                                allowFraction
                                allowHover={false}
                              />
                              <div className={styles.ratingNumber}>
                                {review.rating}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Customer;
