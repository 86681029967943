import React, { useEffect, useState } from "react";
import styles from "./Affiliates.module.scss";
import { PageLayout } from "src/components/PageLayout";
import { PageTitle } from "src/components/PageTitle";
import { Link, useParams } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import { Button, Card, Modal, TextInput } from "src/components";
import { saveAs } from "file-saver";
import axios from "axios";
import { BiBox, BiCalendar, BiMoney } from "react-icons/bi";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import usePopups from "src/services/usePopups";

const columns = [
  "Affiliate user",
  "Product(s)",
  "Commission",
  "Created at",
  "Active",
  "Actions",
];

const reviewsColumns = ["Product", "Date", "Review", "Rating"];

interface CountryInfo {
  name: string;
  emoji: string;
  unicode: string;
  image: string;
}

export type Countries = Record<string, CountryInfo>;

const Affiliates: React.FC = () => {
  const [affiliates, setAffiliates] = React.useState<any[]>([]);
  const [affiliate, setAffiliate] = useState<string>("");
  const navigate = useNavigate();
  const { popups, togglePopup } = usePopups(["urls"]);
  const [prods, setProds] = useState<any[]>([]);

  useEffect(() => {
    Axios.get("/affiliates")
      .then((response) => {
        setAffiliates(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <PageTitle
        actions={
          <Link to="/affiliates/new">
            <Button text="New affiliate" />
          </Link>
        }
      >
        Affiliates
      </PageTitle>
      <div className={styles.tableContainer}>
        <table className={styles.productsTable}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {affiliates?.map((affiliate, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => {
                    navigate(`/affiliates/${affiliate._id}`);
                  }}
                >
                  <td style={{ width: "13%" }}>{affiliate.affiliateUser}</td>
                  <td>
                    {affiliate.productIds.length === 1 ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Link to={`/products/${affiliate.products[0]._id}`}>
                          {affiliate.products[0].name}
                        </Link>
                      </div>
                    ) : (
                      <div>{affiliate.productIds.length + " Products"}</div>
                    )}
                  </td>
                  <td>
                    <div>{affiliate.commissionRate}%</div>
                  </td>
                  <td>
                    <div>{dayjs(affiliate.createdAt).fromNow()}</div>
                  </td>
                  <td>
                    <div>{affiliate.active ? "Active" : "Inactive"}</div>
                  </td>
                  <td>
                    <div
                      className={styles.actions}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Button
                        variant="secondary"
                        onClick={() => {
                          if (affiliate.productIds.length === 1) {
                            navigator.clipboard.writeText(
                              `${window.location.origin}/prod/${affiliate.products[0]._id}?a=${affiliate._id}`
                            );
                            toast.success("Copied to clipboard");
                          } else {
                            setProds(affiliate.products);
                            setAffiliate(affiliate._id);
                            togglePopup("urls");
                          }
                        }}
                        text={
                          "Copy URL" +
                          (affiliate.productIds.length === 1 ? "" : "s")
                        }
                      />
                      {/* <Button variant="danger" text={"Delete"} /> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {popups.urls && (
        <Modal
          onClose={() => togglePopup("urls")}
          title="Copy URLs"
          footerRightButton1={{}}
          footerRightButton2={{
            label: "Close",
            onClick: () => {
              setProds([]);
              togglePopup("urls");
            },
          }}
        >
          <div className={styles.productsList}>
            {prods.map((product) => (
              <div key={product._id} className={styles.productItem}>
                <Link
                  className={styles.productNameContainer}
                  to={`/products/${product._id}`}
                >
                  <img
                    src={
                      product.thumbnail ||
                      "https://via.placeholder.com/600?text=Product+Image"
                    }
                  />
                  <span className={styles.productName}>{product.name}</span>
                </Link>
                <div className={styles.productPrice}>
                  {formatCurrency(product.price)}
                </div>
                <div className={styles.copyButton}>
                  <Button
                    variant="secondary"
                    text="Copy URL"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}/prod/${product._id}?a=${affiliate}`
                      );
                      toast.success("Copied to clipboard");
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Affiliates;
