// Function to generate a unique code
export function generateUniqueCode(length: number) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+";
  let code = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }
  return code;
}

// Sample mapping of usernames and their corresponding codes
export const usernameToCodeMap: Record<string, string> = {
  solt: "6N9K",
  // Add more usernames and codes as needed
};

// Function to get the username from a code
export function getUsernameFromCode(code: string) {
  for (const username in usernameToCodeMap) {
    if (usernameToCodeMap[username] === code) {
      return username;
    }
  }
  return null; // Return null if the code doesn't match any username
}

// Example: Convert a username to a code
// const username = "solt";
// const uniqueCode = generateUniqueCode(4);

// Example: Convert a code back to a username
// const generatedCode = "6N9K";
// const username = getUsernameFromCode(generatedCode);

// if (username) {
//   console.log(`Username for code "${generatedCode}" is: ${username}`);
// } else {
//   console.log(`No matching username found for code "${generatedCode}".`);
// }
