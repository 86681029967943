import dayjs from "dayjs";

export function prepareChartData(
  startDate: any,
  endDate: any,
  salesDataFromBackend: any[],
  visitorsDataFromBackend: any[]
): any[] {
  // Calculate the earliest and latest dates in the data if no range is provided
  let start = startDate
    ? dayjs(startDate)
    : dayjs(salesDataFromBackend[0]?.createdAt);
  let end = endDate
    ? dayjs(endDate)
    : dayjs(salesDataFromBackend[salesDataFromBackend.length - 1]?.createdAt);

  salesDataFromBackend.forEach((sale) => {
    const saleDate = dayjs(sale?.createdAt);
    if (saleDate.isBefore(start)) {
      start = saleDate;
    }
    if (saleDate.isAfter(end)) {
      end = saleDate;
    }
  });

  visitorsDataFromBackend.forEach((view) => {
    const viewDate = dayjs(view?.createdAt);
    if (viewDate.isBefore(start)) {
      start = viewDate;
    }
    if (viewDate.isAfter(end)) {
      end = viewDate;
    }
  });

  const chartData: any[] = [];
  let currentDate = start;

  while (currentDate.isBefore(end.add(1, "day"))) {
    const formattedDate = currentDate.format("YYYY-MM-DD");

    const salesForDay = salesDataFromBackend.filter(
      (sale) => dayjs(sale?.createdAt).format("YYYY-MM-DD") === formattedDate
    );

    const visitorsForDay = visitorsDataFromBackend.filter(
      (view) => dayjs(view?.createdAt).format("YYYY-MM-DD") === formattedDate
    );

    const salesAmountForDay = salesForDay.reduce(
      (total, currentSale) =>
        total + currentSale.amount + (currentSale.upsellAmount || 0),
      0
    );

    chartData.push({
      date: formattedDate,
      sales: salesAmountForDay / 100,
      orders: salesForDay.length,
      visitors: visitorsForDay.length,
    });

    currentDate = currentDate.add(1, "day");
  }

  return chartData;
}
