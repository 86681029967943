import React, { ChangeEvent, DragEvent, useState } from "react";
import styles from "./Product.module.scss";
import { FiPlusSquare, FiUploadCloud } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

// This is a file uploader
const MultipleUploader = ({
  value,
  onChange,
  type = "file",
  text,
}: {
  type?: "file" | "image";
  value?: File[] | null;
  onChange: (files: File[] | null) => void;
  text?: string;
}) => {
  const [highlight, setHighlight] = useState(false);
  const ref = React.useRef<HTMLInputElement>(null);

  const handleFileInputChange = (event: any) => {
    const files = Array.from(event.target.files);
    let concatenatedFiles = [];

    if (value && value.length > 0) {
      concatenatedFiles = value.concat(files as any);
    } else {
      concatenatedFiles = files;
    }

    onChange(concatenatedFiles as any);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlight(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlight(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlight(false);

    const files = event.dataTransfer.files || null;
    onChange(files as any);
  };

  const acceptType = type === "image" ? "image/*" : "";

  const val = Array.isArray(value) ? value : Array.from(value || []);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id && value) {
      const oldIndex = value.findIndex(
        (image: any) => image.name + image.size === active.id
      );
      const newIndex = value.findIndex(
        (image: any) => image.name + image.size === over?.id
      );
      const updatedImages = arrayMove(value, oldIndex, newIndex);
      onChange(updatedImages);
    }
  };

  return (
    <div
      className={`${styles.fileUploader} ${highlight ? styles.highlight : ""} ${
        styles.multipleUploader
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => ref.current?.click()}
    >
      {val && val?.length > 0 ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          autoScroll={false}
        >
          <SortableContext
            items={val.map((image: any) => image.name + image.size)}
            strategy={verticalListSortingStrategy}
          >
            <div className={styles.imagesContainer}>
              {val.map((file: any, index) => {
                return (
                  <>
                    <SortableImageItem
                      key={file.name + file.size}
                      id={file.name + file.size}
                      file={file}
                      index={index}
                      value={value}
                      onChange={onChange}
                    />
                  </>
                );
              })}
              <div
                className={styles.uploaderItem}
                onClick={(e) => {
                  e.stopPropagation();
                  ref.current?.click();
                }}
              >
                <FiPlusSquare size={48} color="#b6b6c9" />
                <input
                  type="file"
                  ref={ref}
                  onChange={handleFileInputChange}
                  accept={acceptType}
                  multiple
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </SortableContext>
        </DndContext>
      ) : (
        <>
          <div className={styles.fileUploaderInner}>
            <div className={styles.iconContainer}>
              <FiUploadCloud size={20} color="#768AA9" />
            </div>
            <span className={styles.textSide}>
              <p>
                Drag them here, or <span>click to browse</span>
              </p>
              <p>{text}</p>
            </span>
          </div>
          <input
            type="file"
            ref={ref}
            onChange={handleFileInputChange}
            accept={acceptType}
            multiple // Added "multiple" attribute to support multiple file selection
          />
        </>
      )}
    </div>
  );
};

export const SortableImageItem = ({
  id,
  file,
  index,
  value,
  onChange,
}: any) => {
  const { attributes, active, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: active?.id === id ? 2 : 0,
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <div className={styles.imageContainer} key={index}>
        <div
          className={styles.deleteContainer}
          onMouseUpCapture={(e) => {
            e.stopPropagation();
            const files = Array.from(value || []);
            files.splice(index, 1);

            console.log(files);
            onChange(files as any);
          }}
        >
          <MdDeleteOutline size={16} color="#e54f4f" />
        </div>
        <img
          src={URL.createObjectURL(file)}
          alt="file"
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default MultipleUploader;
