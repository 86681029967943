import { useAuth0 } from "@auth0/auth0-react";
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PP from "./payp.png";
import { toast } from "react-hot-toast";
import lodash from "lodash";
import {
  Button,
  Card,
  ErrorBanner,
  FormGroup,
  PageTitle,
  Select,
  SwitchInput,
  TextInput,
} from "src/components";
import Axios from "src/services/api";
import styles from "./Profile.module.scss";
import { ColorInput } from "../Product/components/ColorsModal/ColorsModal";
import { ProductSale } from "../Sales/ProductSale";
import { PRODUCT } from "src/services/consts";
import { defaultProduct } from "../Product_/Product.consts";

const colors = [
  {
    id: "title",
    name: "Title",
    value: "#000000",
  },
  {
    id: "buttons",
    name: "Buttons background",
    value: "#3447ef",
  },
  {
    id: "buttonsText",
    name: "Buttons text",
    value: "#FFFFFF",
  },
  {
    id: "background",
    name: "Background",
    value: "#FFFFFF",
  },
  {
    id: "text",
    name: "Text",
    value: "#000000",
  },
  {
    id: "borders",
    name: "Borders",
    value: "#cfd7df",
  },
];

const GlobalStyles = () => {
  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  const defaultUserData = useMemo(
    () => ({
      colors: me?.colors || colors,
      useGlobalColors: me?.useGlobalColors || false,
    }),
    [me]
  );

  const [userData, setUserData] = useState(defaultUserData);

  const submitForm = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    toast.promise(
      Axios.post(`/users/update`, userData).then((res) => {
        localStorage.setItem("me", JSON.stringify(res.data));
      }),
      {
        loading: "Saving...",
        success: "Saved!",
        error: "Error while saving.",
      }
    );
  };

  return (
    <form onSubmit={submitForm}>
      <div className={styles.settingsPage}>
        <PageTitle
          actions={
            <>
              <Button
                text={"Cancel"}
                variant="secondary"
                onClick={() => setUserData(defaultUserData)}
              />
              <Button
                text={"Save changes"}
                type="submit"
                disabled={lodash.isEqual(userData, defaultUserData)}
              />
            </>
          }
        >
          Global Styles
        </PageTitle>
        <Card>
          <div className={styles.bigContainer}>
            <div className={styles.formsContainer}>
              {userData.colors.map((color: any, index: any) => (
                <FormGroup label={color.name} key={index}>
                  <ColorInput
                    value={color.value}
                    onChange={(value) => {
                      setUserData({
                        ...userData,
                        colors: userData.colors.map((c: any) => {
                          if (c.id === color.id) {
                            return {
                              ...c,
                              value,
                            };
                          }

                          return c;
                        }),
                      });
                    }}
                  />
                </FormGroup>
              ))}
              <FormGroup label="Always use global colors on products">
                <SwitchInput
                  name="ppp"
                  checked={userData.useGlobalColors}
                  onChange={(checked) => {
                    setUserData({ ...userData, useGlobalColors: checked });
                  }}
                  id="ppp"
                />
              </FormGroup>
            </div>
            <div className={styles.prodPageContainer}>
              <ProductSale
                id={"Dummy"}
                prod={{
                  ...defaultProduct,
                  name: "Sample Product",
                  images: [
                    {
                      url: "https://via.placeholder.com/600?text=Product+Image",
                    },
                  ],
                  description: `<div>
                  <h2>Introducing Product</h2>
                  <p>
                      <em>The ultimate product for the modern era.</em>
                  </p>
                  <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel sapien nulla. Morbi vitae enim nec risus tristique varius. Vestibulum eu aliquet arcu.
                  </p>
              </div>`,
                  colors: userData.colors,
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </form>
  );
};

export default GlobalStyles;
