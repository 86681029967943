import ReactRouterPrompt from "react-router-prompt";
import { Modal } from "../Modal";
import styles from "./DataLosePrompt.module.scss";

const DataLosePrompt = ({ isDirty }: { isDirty: boolean }) => {
  return (
    <ReactRouterPrompt when={isDirty}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <Modal
            noX
            noClose
            title="Leave page?"
            footerRightButton1={{
              label: "Cancel",
              onClick: () => {
                onCancel();
              },
            }}
            footerRightButton2={{
              variant: "primary",
              label: "Yes, leave",
              onClick: () => {
                onConfirm();
              },
            }}
          >
            <div className={styles.text}>
              Are you sure you want to leave? Your changes will not be saved.
            </div>
          </Modal>
        )
      }
    </ReactRouterPrompt>
  );
};

export default DataLosePrompt;
