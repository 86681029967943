import React, { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "src/services/api";
import { toast } from "react-hot-toast";

export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const PayPalButton = ({
  merchantId,
  amount,
  currency,

  product,
  variant,
  country,
  zip,
  state,

  discount,

  addedPayPalUpsell,
  demoMode,

  taxPercentage,
  vatNumber,
}: any) => {
  const navigate = useNavigate();
  useLayoutEffect(() => {
    // Dynamically include the PayPal JavaScript SDK
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&merchant-id=${merchantId}&currency=${
      currency || "USD"
    }`;
    script.onload = () => {
      (window as any).paypal
        .Buttons({
          style: {
            layout: "horizontal",
            size: "responsive",
            label: "buynow",
          },
          onclick: async (data: any, actions: any) => {
            console.log("onclick", data, actions);
          },
          createOrder: async (data: any, actions: any) => {
            try {
              let ppAmount = 0;

              ppAmount = parseFloat(
                (
                  amount +
                  (addedPayPalUpsell
                    ? product?.upsell?.upsellPrice -
                        (discount?.valueType === "percentage"
                          ? (discount?.value / 100) *
                            product?.upsell?.upsellPrice
                          : discount?.value) || 0
                    : 0)
                ).toFixed(2)
              );

              if (ppAmount <= 0) {
                ppAmount = 0;
              }

              if (taxPercentage) {
                ppAmount += parseFloat(
                  (ppAmount * (taxPercentage / 100)).toFixed(2)
                );
              }

              const response = await Axios.post(
                "/sales/create-order",
                {
                  // use the "body" param to optionally pass additional order information
                  // like product ids and quantities
                  merchantId,
                  cart: {
                    amount: ppAmount,
                    currency,
                    product,
                  },
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              const orderData = response.data;

              if (orderData.id) {
                return orderData.id;
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);

                throw new Error(errorMessage);
              }
            } catch (error) {
              console.error(error);
              // resultMessage(
              //   `Could not initiate PayPal Checkout...<br><br>${error}`
              // );
            }
          },
          onApprove: async (data: any, actions: any) => {
            try {
              const response = await Axios.post(
                `/sales/${data.orderID}/capture`,
                {
                  merchantId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              const orderData = response.data;

              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`
                );
              } else if (!orderData.purchase_units) {
                throw new Error(JSON.stringify(orderData));
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction =
                  orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                  orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

                // create sale
                const sale = await Axios.post("/sales/create-paypal-sale", {
                  userId: product.userId,
                  productId: product._id,
                  grossAmount: parseFloat(
                    transaction?.seller_receivable_breakdown?.gross_amount
                      ?.value
                  ),
                  buyerEmail: orderData.payer.email_address,
                  amountBeforeTax: parseFloat(
                    transaction?.seller_receivable_breakdown?.net_amount?.value
                  ),
                  taxAmount: 0,
                  taxRate: 0,
                  ToCountry: country,
                  ToZip: zip,
                  ToState: state,
                  refundPolicy: product.refundPolicy,
                  paypalFee: parseFloat(
                    transaction?.seller_receivable_breakdown?.paypal_fee?.value
                  ),
                  paypalCurrency:
                    transaction?.seller_receivable_breakdown?.gross_amount
                      ?.currency_code,
                  paypalRefundLink: transaction?.links?.find(
                    (link: any) => link.rel === "refund"
                  )?.href,
                  netAmount: parseFloat(
                    transaction?.seller_receivable_breakdown?.net_amount?.value
                  ),
                  upsellId: addedPayPalUpsell && product?.upsell?._id,
                  upsellAmount:
                    addedPayPalUpsell && product?.upsell?.upsellPrice,

                  discount: discount?._id,

                  taxPercentage,
                  vatNumber,

                  variantId: variant?._id,
                });

                Axios.post("/customers/create-paypal-customer", {
                  userId: product.userId,
                  buyerEmail: orderData.payer.email_address,
                  country,
                }).then(() => {
                  // delete paymentIntent (not blockable)
                  // go to success page
                  navigate(
                    "/checkout/success?redirect_status=succeeded&saleId=" +
                      sale.data._id
                  );
                });
              }
            } catch (error) {
              toast.error(
                "Error processing payment using PayPal, please try again",
                {
                  duration: 20000,
                }
              );
              console.error(error);
            }
          },
          onError: (err: any) => {
            if (demoMode) return;

            toast.error("Error processing payment using PayPal \n" + err, {
              duration: 20000,
            });
          },
        })
        .render("#paypal-button-container");
    };
    document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, [merchantId, addedPayPalUpsell, amount, product]);

  return <div id="paypal-button-container"></div>;
};

export default PayPalButton;
