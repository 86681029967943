import { useAuth0 } from "@auth0/auth0-react";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import lodash from "lodash";
import {
  Button,
  Card,
  FormGroup,
  InfoIcon,
  PageTitle,
  Select,
  TextInput,
} from "src/components";
import Axios from "src/services/api";
import styles from "./Profile.module.scss";
import { MoRModal } from "src/components/AuthenticationGuard/components/MoRModal";
import usePopups from "src/services/usePopups";

const Taxes = () => {
  const { popups, togglePopup } = usePopups(["mor"]);
  const [me, setMe] = useState<any>(
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!)
  );

  const [defaultUserData, setDefaultUserData] = useState({
    taxId: me?.taxId || "",
    taxEnabled: me?.taxEnabled || "no",
  });

  const [userData, setUserData] = useState(defaultUserData);

  const submitForm = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    toast.promise(
      Axios.post(`/users/update`, {
        ...userData,
        taxEnabled: userData.taxEnabled === "yes" ? true : false,
      }).then((res) => {
        setMe(res.data);
        setDefaultUserData(userData);
        localStorage.setItem("me", JSON.stringify(res.data));
      }),
      {
        loading: "Saving...",
        success: "Saved!",
        error: "Error while saving.",
      }
    );
  };

  return (
    <form onSubmit={submitForm}>
      <div className={styles.settingsPage}>
        <PageTitle
          actions={
            <>
              <Button
                text={"Cancel"}
                variant="secondary"
                onClick={() => setUserData(defaultUserData)}
              />
              <Button
                text={"Save changes"}
                type="submit"
                disabled={lodash.isEqual(userData, defaultUserData)}
              />
            </>
          }
        >
          Tax settings
        </PageTitle>
        <div className={styles.text}>
          Learn more about{" "}
          <b
            style={{ fontWeight: 500, cursor: "pointer" }}
            onClick={() => togglePopup("mor")}
          >
            taxes
          </b>
        </div>
        <br />
        <Card>
          <div className={styles.formsContainer}>
            <FormGroup label="Tax ID (optional)">
              <TextInput
                value={userData.taxId}
                name="taxId"
                onChange={(e: any) =>
                  setUserData({ ...userData, taxId: e.target.value })
                }
                placeholder="e.g. GB123456789"
              />
            </FormGroup>
          </div>
        </Card>
        <br />
        <br />
        <Card>
          <div className={styles.formsContainer}>
            <FormGroup label="Withhold and remit to authorities on my behalf">
              <Select
                options={[
                  {
                    label: "Yes",
                    value: "yes",
                  },
                  {
                    label: "No",
                    value: "no",
                  },
                ]}
                selectedValue={userData.taxEnabled}
                onChange={(value) =>
                  setUserData({ ...userData, taxEnabled: value })
                }
              />
            </FormGroup>
            <div className={styles.divider} />
          </div>
          <div className={styles.text}>
            We will withhold, and remit your taxes to authorities. An extra fee
            of <b>1%</b> will be applied.
          </div>
        </Card>
      </div>
      {popups.mor && (
        <MoRModal
          inSettings
          onClose={() => togglePopup("mor")}
          onSave={() => {
            togglePopup("mor");
          }}
        />
      )}
    </form>
  );
};

export default Taxes;
