import React from 'react';

interface RenderHTMLProps {
  html: string;
}

const RenderHTML: React.FC<RenderHTMLProps> = ({ html }) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default RenderHTML;