import { ProductType } from "./Product.types";

export const defaultProduct: ProductType = {
  name: "",
  subtitle: "",
  price: 0,
  payWant: false,
  description: "",
  file: "",
  url: "",
  images: [],
  published: true,
  isFile: true,
  design: 1,
  hasProductPage: "unknown",
  colors: [
    {
      id: "title",
      name: "Title",
      value: "#000000",
    },
    {
      id: "buttons",
      name: "Buttons background",
      value: "#3447ef",
    },
    {
      id: "buttonsText",
      name: "Buttons text",
      value: "#FFFFFF",
    },
    {
      id: "background",
      name: "Background",
      value: "#FFFFFF",
    },
    {
      id: "text",
      name: "Text",
      value: "#000000",
    },
    {
      id: "borders",
      name: "Borders",
      value: "#cfd7df",
    },
    {
      id: "heroBackground",
      name: "Hero background",
      value: "#6772e5",
    },
    {
      id: "cardBackground",
      name: "Card background",
      value: "#FFFFFF",
    },
  ],
  ctaText: "buyForPrice",
  userId: "",
  showSales: true,
  showReviews: true,
  checkoutText: "",
  thankYouText: "",
  refundPolicy: null,
};
