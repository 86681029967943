// api.ts
import axios, { AxiosInstance } from "axios";

const Axios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add this interceptor to update the Authorization header before each request
Axios.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers!.Authorization = `Bearer ${accessToken}`;
  } else {
    delete config.headers?.Authorization;
  }
  return config;
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response.status === 500 &&
        (error.response.data.includes("undefined") ||
          error.response.data === undefined))
    ) {
      localStorage.removeItem("accessToken");

      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export default Axios;
