import React from "react";
import styles from "./SalesCounter.module.scss";
import { AiFillInfoCircle } from "react-icons/ai";
import numeral from "numeral";

const SalesCounter = ({ salesNumber, product, inBottomBar }: any) => {
  return (
    <div
      style={{
        border: `${
          product.colors.find((c: any) => c.id === "buttons").value
        } 1px solid`,
      }}
      className={styles.salesBanner + (inBottomBar ? " " + styles.inBottomBar : "")}
    >
      <AiFillInfoCircle
        size={24}
        color={product.colors.find((c: any) => c.id === "buttons").value}
      />
      <div
        className={styles.salesText}
        style={{
          color: product.colors.find((c: any) => c.id === "text").value,
        }}
      >
        <b>{numeral(salesNumber).format("0[.]00")}</b> sales
      </div>
    </div>
  );
};

export default SalesCounter;
