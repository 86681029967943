import React, { useEffect, useState } from "react";
import styles from "./VariantsSelector.module.scss";
import { formatCurrency } from "src/services/currency";
const VariantsSelector = ({
  variants,
  setSelectedVariant,
  selectedVariant,
}: {
  variants: any[];
  setSelectedVariant: (variant: any) => void;
  selectedVariant?: any;
}) => {
  const [activeVariant, setActiveVariant] = useState<any>(null);

  useEffect(() => {
    if (selectedVariant) {
      setActiveVariant(
        variants.findIndex((v) => v._id === selectedVariant._id)
      );
    }
  }, [selectedVariant]);

  return (
    <div className={styles.variantsSelector}>
      {variants.map((variant, index) => (
        <div
          key={variant._id}
          className={`${styles.variant} ${
            activeVariant === index ? styles.active : ""
          }`}
          onClick={() => {
            if (activeVariant !== index) {
              setActiveVariant(index);
              setSelectedVariant(variant);
            } else {
              setSelectedVariant(null);
              setActiveVariant(null);
            }
          }}
        >
          <div
            className={styles.variantTopContainer}
            onClick={() => {
              setActiveVariant(index);
              setSelectedVariant(variant);
            }}
          >
            <div className={styles.variantCircle}>
              <div className={styles.variantCircleSmall} />
            </div>
            <div className={styles.variantName}>{variant.name}</div>
            <div className={styles.variantPrice}>
              {formatCurrency(variant.price, "USD")}
            </div>
          </div>
          {variant.description && (
            <div className={styles.variantDescription}>
              {variant.description}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default VariantsSelector;
