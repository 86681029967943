import React, { useEffect, useState } from "react";
import styles from "./Customers.module.scss";
import { PageLayout } from "src/components/PageLayout";
import { PageTitle } from "src/components/PageTitle";
import { Link } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import { Button, EmptyState, TextInput } from "src/components";
import { saveAs } from "file-saver";
import axios from "axios";
import { toast } from "react-hot-toast";
import { PageLoader } from "src/components/page-loader";
import { useNavigate } from "react-router-dom";
import Gravatar from "src/components/Gravatar";

const columns = ["Customer", "Country", "Orders", "Date created"];

interface CountryInfo {
  name: string;
  emoji: string;
  unicode: string;
  image: string;
}

export type Countries = Record<string, CountryInfo>;

const Customers: React.FC = () => {
  const navigate = useNavigate();
  const [customers, setCustomers] = React.useState<any[]>([]);
  const [filteredCustomers, setFilteredCustomers] = React.useState<any[]>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [search, setSearch] = React.useState<string>("");

  useEffect(() => {
    Axios.get("/customers").then((response) => {
      setCustomers(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (search === "") {
      setFilteredCustomers(customers);
    } else {
      setFilteredCustomers(
        customers.filter((customer) => customer.buyerEmail.includes(search))
      );
    }
  }, [search, customers]);

  const handleExport = () => {
    Axios.post("/customers/export", {}, { responseType: "blob" }).then(
      (response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(blob, "customers.csv");
      }
    );
  };

  const [countries, setCountries] = useState<Countries | null>(null);

  useEffect(() => {
    import("../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div className={styles.searchInputContainer}>
            <div className={styles.seachInput}>
              <TextInput
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search customers"
              />
            </div>
            {!loading &&
              // @ts-ignore
              filteredCustomers?.length > 0 && (
                <Button text="Export as CSV" onClick={handleExport} />
              )}
          </div>
        }
      >
        Customers
      </PageTitle>
      {loading || filteredCustomers?.length === 0 ? (
        <EmptyState
          loading={loading}
          title="Get your first customer"
          subTitle="Create and share your products to make sales. Your customers will show up here"
        />
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.productsTable}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredCustomers?.map((customer, index) => {
                  const country = countries?.[customer.country];
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        navigate(`/customers/${customer._id}`);
                      }}
                    >
                      <td style={{ width: "13%" }}>
                        <Gravatar email={customer.buyerEmail} size={100} />
                        <Link
                          className={styles.productNameContainer}
                          to={`/customers/${customer._id}`}
                        >
                          {customer.buyerEmail}
                        </Link>
                      </td>
                      <td>
                        <div>
                          {country?.emoji} {country?.name}
                        </div>
                      </td>
                      <td>
                        <div>{customer.numberOfOrders}</div>
                      </td>
                      <td>
                        <div>{dayjs(customer.createdAt).fromNow()}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Count items={filteredCustomers} />
        </>
      )}
      {/* <div className={styles.tableHeader}>
        {columns.map((column, index) => (
          <div key={index} className={styles.tableHeaderItem}>
            {column}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export const Count = ({ items }: any) => (
  <div className={styles.count}>
    {items.length > 0 && `${items.length}`} Results
  </div>
);

export default Customers;
