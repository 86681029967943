import React, { useState } from "react";
import { FormGroup, Modal, TextInput } from "src/components";
import styles from "./InviteModal.module.scss";
import { FaCheckCircle } from "react-icons/fa";

interface InviteModalProps {
  onSave: (code: any) => void;
}

const InviteModal = ({ onSave }: InviteModalProps) => {
  const [code, setCode] = React.useState("");
  const [errors, setErrors] = useState<any>({});

  const validateForm = (): boolean => {
    let formErrors: any = {};

    if (!code) formErrors.name = "Code is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  return (
    <Modal
      noX
      title="Invite Code"
      noClose
      footerRightButton2={{
        label: "Access Pocketsflow",
        onClick: () => {
          if (!validateForm()) return;
          onSave(code);
        },
      }}
    >
      <div className={styles.modalContent}>
        <FormGroup error={errors.code} label="Code">
          <TextInput
            name="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </FormGroup>
      </div>
    </Modal>
  );
};

export default InviteModal;
