import React, { useEffect, useState } from "react";

import styles from "./Upsells.module.scss";
import {
  Button,
  Card,
  EmptyState,
  PageLayout,
  PageTitle,
} from "src/components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Axios from "src/services/api";
import { formatCurrency } from "src/services/currency";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import numeral from "numeral";
import { Count } from "../Customers/Customers";

const columns = [
  "Name",
  "Upsell product",
  "Applied to",
  "Orders",
  "Revenue added",
  "Price",
  "Created at",
];

const Upsells = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [upsells, setUpsells] = useState<any[]>([]);
  const [salesWithUpsells, setSalesWithUpsells] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    Axios.get("/upsells").then((response) => {
      setUpsells(response.data.upsells);
      setSalesWithUpsells(response.data.salesWithUpsells);
      return Axios.get("/products").then((res) => {
        setProducts(res.data);
        setLoading(false);
      });
    });
  }, []);

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <Link to="/upsells/new">
            <Button text="New upsell" />
          </Link>
        }
      >
        Upsells
      </PageTitle>
      {loading || upsells?.length === 0 || products?.length === 0 ? (
        <EmptyState
          loading={loading}
          title={
            products?.length === 0
              ? "Create your first product"
              : upsells?.length === 0
              ? "Create your first upsell"
              : ""
          }
          subTitle={
            products?.length === 0
              ? "Create your first product first and then create an upsell."
              : upsells?.length === 0
              ? "Create your first upsell and start generating extra revenue."
              : ""
          }
          buttonText={
            products?.length === 0
              ? "Create product"
              : upsells?.length === 0
              ? "Create upsell"
              : ""
          }
          buttonClick={() => {
            navigate(products?.length === 0 ? "/products/new" : "/upsells/new");
          }}
        />
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.productsTable}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              {!loading && (
                <tbody>
                  {upsells?.map((upsell, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          navigate(`/upsells/${upsell._id}`);
                        }}
                      >
                        <td>
                          <div className={styles.ellipsis}>{upsell.name}</div>
                        </td>
                        <td style={{ width: "20%" }}>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Link
                              className={styles.productNameContainer}
                              to={`/products/${upsell.upsellProductId}`}
                            >
                              <div className={styles.ellipsis}>
                                {products.find(
                                  (product) =>
                                    product._id === upsell.upsellProductId
                                )?.name || "Untitled"}
                              </div>
                            </Link>
                          </div>
                        </td>

                        <td style={{ width: "10%" }}>
                          <div className={styles.ellipsis}>
                            {upsell.mainProductIds.length}{" "}
                            {upsell.mainProductIds.length === 1
                              ? "Product"
                              : "Products"}
                          </div>
                        </td>
                        <td style={{ width: "10%" }}>
                          <div className={styles.ellipsis}>
                            {numeral(
                              salesWithUpsells.filter(
                                (sale) => sale.upsellId === upsell._id
                              )?.length || 0
                            ).format()}
                          </div>
                        </td>
                        <td style={{ width: "10%" }}>
                          <div className={styles.ellipsis}>
                            {formatCurrency(
                              salesWithUpsells
                                .filter((sale) => sale.upsellId === upsell._id)
                                ?.reduce((a, b) => a + b.upsellAmount / 100, 0) || 0
                            )}
                          </div>
                        </td>
                        <td style={{ width: "10%" }}>
                          <div className={styles.ellipsis}>
                            {formatCurrency(upsell.upsellPrice)}
                          </div>
                        </td>
                        <td>
                          <div className={styles.ellipsis}>
                            {dayjs(upsell.createdAt).fromNow()}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
          <Count items={upsells} />
        </>
      )}
    </div>
  );
};

export default Upsells;
