import React, { useState } from "react";
import styles from "src/pages/Product_/Product.module.scss";
import TogglableTabs from "../DefineProduct/ToggleTabs";
import { Button, FormGroup } from "src/components";

const ProductPageQuestion = ({ answer, setAnswer, onSubmit }: any) => {
  return (
    <div className={styles.step}>
      <h1>Do you want a product page?</h1>
      <p className={styles.desc}>
        If you want a product page, you can create it in the next step. If not
        you can use the checkout page directly for your customers.
      </p>
      <div className={styles.form}>
        <FormGroup label="Do you want a product web page?">
          <TogglableTabs
            items={[
              {
                id: "yes",
                title: "Yes, I do",
                subTitle:
                  "Either create a product page or choose from pre-made templates.",
              },
              {
                id: "no",
                title: "No, just the checkout page",
                subTitle:
                  "Use the checkout page directly to sell your products.",
              },
            ]}
            onChange={(value: string) => setAnswer(value)}
            value={answer}
          />
        </FormGroup>
        <div
          className={styles.actions}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            text="Submit and continue"
            onClick={() => {
              onSubmit();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductPageQuestion;
