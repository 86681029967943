import React, { useEffect } from "react";
import styles from "./Onboarding.module.scss";
import {
  Button,
  CheckInput,
  FormGroup,
  Select,
  TextInput,
} from "src/components";
import { currencies } from "src/services/currency";
import lodash from "lodash";

import countries from "i18n-iso-countries";
import Axios from "src/services/api";
import { getUsernameFromCode } from "src/services/usernameCode";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const SettingsStep = ({ errors, userState, setUserState, setVerify }: any) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [succeeded, setSucceeded] = React.useState<boolean>(false);
  const [failed, setFailed] = React.useState<boolean>(false);
  const [failedChar, setFailedChar] = React.useState<boolean>(false);
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [failedCharLength, setFailedCharLength] =
    React.useState<boolean>(false);
  const countryOptions = Object.entries(countries.getNames("en")).map(
    ([code, name]) => ({ value: code, label: name })
  );
  const onChange = (key: string, value: string) => {
    setUserState({ ...userState, [key]: value });
  };

  const verifySubdomain = (subdomain: string) => {
    setLoading(true);
    setFailed(false);
    setFailedChar(false);
    setFailedCharLength(false);
    setSucceeded(false);

    Axios.get(`/users/verify-subdomain/${subdomain}`)
      .then(() => {
        setLoading(false);
        setSucceeded(true);
        setVerify(true);
      })
      .catch((error) => {
        setLoading(false);
        setVerify(false);
        if (error.response.status === 400) {
          setFailedChar(error.response.data.message === "char");
          setFailedCharLength(error.response.data.message === "length");
          setFailed(error.response.data.message === "failed");
        }
      });
  };

  const debouncedVerifySubdomain = lodash.debounce(verifySubdomain, 1000);

  useEffect(() => {
    if (!userState.subdomain) {
      setFailed(false);
      setFailedChar(false);
      setFailedCharLength(false);
      setSucceeded(false);
      setLoading(false);
      return;
    }

    if (userState.subdomain.includes(" ")) {
      userState.subdomain = userState.subdomain.replace(/\s/g, "");
    } else {
      setLoading(true);
      setFailed(false);
      setFailedChar(false);
      setFailedCharLength(false);
      setSucceeded(false);
    }
    debouncedVerifySubdomain(userState.subdomain);

    return () => {
      debouncedVerifySubdomain.cancel();
    };
  }, [userState.subdomain]);

  useEffect(() => {
    if (!code.length) {
      setCodeError("");
      onChange("subdomain", "");
      return;
    }

    const username = getUsernameFromCode(code);

    if (username) {
      onChange("subdomain", username);
      setCodeError("");
    } else {
      onChange("subdomain", "");
      setCodeError("Code is wrong");
    }
  }, [code]);

  return (
    <div className={styles.step}>
      <div className={styles.title}>Welcome to Pocketsflow</div>
      <div className={styles.subTitle}>
        We're so excited to have you here! Let's get started by setting up your
        account. You can always change these settings later.
      </div>
      <div className={styles.content}>
        <div className={styles.formGrid}>
          <FormGroup label="First name" error={errors.firstName} required>
            <TextInput
              value={userState.firstName}
              name="firstName"
              onChange={(e: any) => onChange("firstName", e.target.value)}
              error={errors.firstName}
              placeholder="e.g. John"
            />
          </FormGroup>
          <FormGroup label="Last name" error={errors.lastName} required>
            <TextInput
              value={userState.lastName}
              name="lastName"
              onChange={(e: any) => onChange("lastName", e.target.value)}
              error={errors.lastName}
              placeholder="e.g. Doe"
            />
          </FormGroup>
        </div>
        <div className={styles.usernameHolder}>
          {/* <div className={styles.codeInput}>
            <FormGroup label="Enter Code" error={codeError}>
              <TextInput
                value={code}
                name="subdomain"
                onChange={(e: any) => setCode(e.target.value)}
                error={codeError}
                placeholder="e.g. T$2IJ43"
              />
            </FormGroup>
          </div> */}
          <FormGroup label="Username" error={errors.subdomain} required>
            <TextInput
              value={userState.subdomain}
              name="subdomain"
              onChange={(e: any) => onChange("subdomain", e.target.value)}
              error={errors.subdomain}
              placeholder="e.g. johndoe"
            />
          </FormGroup>
          <div className={styles.suffix}>.pocketsflow.com</div>
          {loading && <div className={styles.loading}>Loading...</div>}
          {succeeded && <div className={styles.succeeded}>It's yours 🎉</div>}
          {(failed || failedChar || failedCharLength) && (
            <div className={styles.failed}>
              {failed
                ? "This username is already taken 😔"
                : failedChar
                ? "Invalid characters 😖"
                : "Must be 3 characters and up 🙁"}
            </div>
          )}
        </div>
        <FormGroup label="Support email" error={errors.contactEmail} required>
          <TextInput
            value={userState.contactEmail}
            name="contactEmail"
            onChange={(e: any) => onChange("contactEmail", e.target.value)}
            error={errors.contactEmail}
            placeholder="e.g. johndoe@gmail.com"
          />
        </FormGroup>
        {/* <FormGroup label="Currency" error={errors.currency} required>
          <Select
            options={currencies}
            selectedValue={userState.currency}
            error={errors.currency}
            onChange={(value) =>
              setUserState({ ...userState, currency: value })
            }
          />
        </FormGroup> */}
        <div className={styles.formGrid}>
          <FormGroup label="City" error={errors.city} required>
            <TextInput
              value={userState.city}
              name="city"
              onChange={(e: any) => onChange("city", e.target.value)}
              error={errors.city}
              placeholder="e.g. New York"
            />
          </FormGroup>
          <FormGroup label="State/Province" error={errors.state} required>
            <TextInput
              value={userState.state}
              name="state"
              onChange={(e: any) => onChange("state", e.target.value)}
              error={errors.state}
              placeholder="e.g. New York"
            />
          </FormGroup>
        </div>
        <div className={styles.formGrid}>
          <FormGroup label="Zip code" error={errors.zipCode} required>
            <TextInput
              value={userState.zipCode}
              name="zipCode"
              onChange={(e: any) => onChange("zipCode", e.target.value)}
              error={errors.zipCode}
              placeholder="e.g. 10001"
            />
          </FormGroup>
          <FormGroup label="Country" error={errors.country} required>
            <Select
              options={countryOptions}
              selectedValue={userState.country}
              error={errors.country}
              onChange={(value) =>
                setUserState({ ...userState, country: value })
              }
            />
          </FormGroup>
        </div>
        <FormGroup label="Tax ID (optional)">
          <TextInput
            value={userState.taxId}
            name="taxId"
            onChange={(e: any) => onChange("taxId", e.target.value)}
            error={errors.taxId}
            placeholder="e.g. GB123456789"
          />
        </FormGroup>
        <div className={styles.agree}>
          <CheckInput
            id="agree"
            name="agree"
            checked={userState.agree}
            onChange={(value: any) => onChange("agree", value)}
          />
          {/* text to red if errors.agree is there */}
          <div
            className={styles.agreeText}
            style={errors.agree ? { color: "#e54f4f" } : {}}
          >
            I agree to the{" "}
            <a
              href="https://pocketsflow.com/terms"
              target="_blank"
              style={errors.agree ? { color: "#e54f4f" } : {}}
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href="https://pocketsflow.com/privacy"
              target="_blank"
              style={errors.agree ? { color: "#e54f4f" } : {}}
            >
              Privacy
            </a>{" "}
            Policies
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsStep;
