import React from "react";
import styles from "./Help.module.scss";
import { Card, PageTitle } from "src/components";

import { FiPlayCircle } from "react-icons/fi";

const videos = [
  {
    title: "Products tutorial",
    url: "https://pf-vids.s3.amazonaws.com/products-tutorials.mp4",
  },
  {
    title: "Bank account tutorial",
    url: "https://pf-vids.s3.amazonaws.com/payments-tutorial.mp4",
  },
  {
    title: "Upsells tutorial",
    url: "https://pf-vids.s3.amazonaws.com/upsells-tutorials.mp4",
  },
  {
    title: "Refunds tutorial",
    url: "https://pf-vids.s3.amazonaws.com/refunds-tutorial.mp4",
  },
];

const Help: React.FC = () => {
  return (
    <>
      <div className={styles.pageContainer}>
        <PageTitle>Help & tutorials</PageTitle>
        <div className={styles.content}>
          <h2 className={styles.innerTitle}>Video tutorials</h2>
          <div className={styles.tutorialsGrid}>
            {videos.map((video, index) => (
              <Card key={index}>
                <div className={styles.flex}>
                  <video
                    controls={false}
                    autoPlay={false}
                    onClick={() => {
                      window.open(video.url, "_blank");
                    }}
                  >
                    <source src={video.url} type="video/mp4" />
                  </video>
                  <div className={styles.tutorialTextContainer}>
                    <div className={styles.title}>{video.title}</div>
                    <div
                      className={styles.watch}
                      onClick={() => {
                        window.open(video.url, "_blank");
                      }}
                    >
                      <FiPlayCircle size={18} />
                      Watch video
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
