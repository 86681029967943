import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SavingStep = ({ product, isCreated, hasBankAccount, hasPayPal }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      !hasBankAccount &&
      !hasPayPal &&
      (product.price !== 0 || product.payWant)
    ) {
      navigate(
        `/products/${product?._id}${
          !hasBankAccount && !hasPayPal ? "?showBankAlertModal=true" : ""
        }`,
        { replace: true }
      );
    } else {
      if (isCreated) {
        navigate(`/products/${product?._id}?created=true&showShareModal=true`, {
          replace: true,
        });
      } else {
        navigate(
          `/products/${product?._id}?created=false&showShareModal=true`,
          {
            replace: true,
          }
        );
      }
    }
  }, []);

  return <></>;
};

export default SavingStep;
