import React from "react";
import { FormGroup, Modal, Select } from "src/components";
import styles from "./PayoutSettings.module.scss";
import Tiptap from "src/components/TipTap/TipTap";
import Axios from "src/services/api";

interface PayoutSettingsProps {
  onClose: () => void;
  initialValue: string;
}

const PayoutSettings = ({ onClose, initialValue }: PayoutSettingsProps) => {
  const [schedule, setSchedule] = React.useState<string>(initialValue);

  const handleCountryChange = (value: string) => {
    setSchedule(value);
  };

  return (
    <Modal
      onClose={onClose}
      title="Change payout schedule"
      footerRightButton1={{
        label: "Cancel",
        onClick: onClose,
      }}
      footerRightButton2={{
        label: "Save",
        onClick: () => {
          Axios.post("/payouts/schedule", {
            schedule,
          }).then(() => {
            onClose();
          });
        },
      }}
    >
      <div className={styles.modalContent}>
        <FormGroup label="Payout schedule">
          <Select
            options={[
              { value: "daily", label: "Daily" },
              { value: "weekly", label: "Weekly" },
              { value: "monthly", label: "Monthly" },
            ]}
            onChange={handleCountryChange}
            selectedValue={schedule}
          />
        </FormGroup>
      </div>
    </Modal>
  );
};

export default PayoutSettings;
