import React, { useEffect, useRef, useState } from "react";
import {
  ComposedChart,
  Bar,
  Line,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import styles from "./Dashboard.module.scss";
import { Card, EmptyState, PageTitle } from "src/components";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import "./Dashboard.scss";

import {
  BiMoney,
  BiBox,
  BiUser,
  BiBarChartSquare,
  BiGroup,
  BiSidebar,
} from "react-icons/bi";

import Axios from "src/services/api";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { prepareChartData } from "./utils";
import { Link } from "react-router-dom";
import { Countries } from "../Customers/Customers";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { IoChevronDownOutline } from "react-icons/io5";
import { makeCdnUrl } from "src/services/cdn";

const statsDefault = [
  {
    title: "Revenue",
    id: "sales",
    value: "0",
    icon: <BiMoney size={28} />,
    color: "#dcffdb",
    type: "currency",
  },
  {
    title: "Orders",
    id: "orders",
    value: 0,
    icon: <BiBox size={28} />,
    color: "#f3e2ff",
    type: "number",
  },
  {
    title: "Visitors",
    id: "visitors",
    value: 0,
    icon: <BiUser size={28} />,
    color: "#dcf1ff",
    type: "number",
  },
  {
    title: "Conversion Rate",
    id: "conversionRate",
    value: "0%",
    icon: <BiBarChartSquare size={28} />,
    color: "#ffe5e5",
    type: "percentage",
  },
  {
    title: "Customers",
    id: "customers",
    value: "0",
    icon: <BiGroup size={28} />,
    color: "#d9fffd",
    type: "number",
  },
  {
    title: "Total Page Views",
    id: "pageViews",
    value: "0",
    icon: <BiSidebar size={28} />,
    color: "#ffefe0",
    type: "number",
  },
];

const columns = [
  "Name",
  "Price",
  "Orders",
  // "Page Views",
  // "Visitors",
  "Sales",
  "Refunds",
];
const countriesColumns = [
  "Country",
  "Customers",
  "Orders",
  // "Revenue"
];

const Dashboard = () => {
  const [state, setState] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [stats, setStats] = useState(statsDefault);
  const [openDate, setOpenDate] = useState(false);
  const [chartData, setChartData] = useState<any>([]);
  const [productsWithSales, setProductsWithSales] = useState<any>([]);
  const [countriesData, setCountriesData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const [countries, setCountries] = useState<Countries | null>(null);

  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    import("../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpenDate(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!firstTime) {
      return;
    }
    setFirstTime(false);
    toast.promise(
      Axios.post(
        "/dashboard",
        {
          startDate: state[0].startDate?.toISOString(),
          endDate: state[0]?.endDate?.toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ).then((response) => {
        setChartData(
          prepareChartData(
            state[0].startDate?.toISOString(),
            state[0]?.endDate?.toISOString(),
            response.data.chartData.sales,
            response.data.chartData.visitors
          )
        );

        const sales = response.data.chartData.sales;
        const refunds = response.data.refundedSales;
        const products = response.data.products;

        setCountriesData(response.data.countryData);

        setProductsWithSales(
          products.map((product: any) => {
            const productSales = sales.filter(
              (sale: any) => sale.productId === product._id
            );
            let totalRevenue = 0;
            for (const sale of productSales) {
              totalRevenue += sale.amount / 100;
            }

            const productRefunds = refunds.filter((refund: any) => {
              return refund.productId === product._id;
            });

            let totalRefund = 0;
            for (const sale of productRefunds) {
              totalRefund += sale.amount / 100;
            }

            return {
              ...product,
              totalRevenue,
              totalRefund,
              numberOfSales: productSales.length,
            };
          })
        );

        const statsData = response.data.stats.map((stat: any) => {
          const s: any = statsDefault.find((s) => s.id === stat.id);
          return {
            ...s,
            value: stat.value,
          };
        });
        setStats(statsData);
        setLoading(false);
      }),
      {
        loading: "Loading...",
        success: "Data loaded!",
        error: "Error when loading data",
      }
    );
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        toast.promise(
          Axios.post(
            "/dashboard",
            {
              startDate: state[0].startDate?.toISOString(),
              endDate: state[0]?.endDate?.toISOString(),
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          ).then((response) => {
            setChartData(
              prepareChartData(
                state[0].startDate?.toISOString(),
                state[0]?.endDate?.toISOString(),
                response.data.chartData.sales,
                response.data.chartData.visitors
              )
            );

            const sales = response.data.chartData.sales;
            const refunds = response.data.refundedSales;
            const products = response.data.products;

            setCountriesData(response.data.countryData);

            setProductsWithSales(
              products.map((product: any) => {
                const productSales = sales.filter(
                  (sale: any) => sale.productId === product._id
                );
                let totalRevenue = 0;
                for (const sale of productSales) {
                  totalRevenue += sale.amount / 100;
                }

                const productRefunds = refunds.filter((refund: any) => {
                  return refund.productId === product._id;
                });

                let totalRefund = 0;
                for (const sale of productRefunds) {
                  totalRefund += sale.amount / 100;
                }

                return {
                  ...product,
                  totalRevenue,
                  totalRefund,
                  numberOfSales: productSales.length,
                };
              })
            );

            const statsData = response.data.stats.map((stat: any) => {
              const s: any = statsDefault.find((s) => s.id === stat.id);
              return {
                ...s,
                value: stat.value,
              };
            });
            setStats(statsData);
          }),
          {
            loading: "Loading...",
            success: "Data loaded!",
            error: "Error when loading data",
          }
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (
      ((state[0].startDate && state[0].endDate) ||
        (!state[0].startDate && !state[0].endDate)) &&
      !firstTime
    ) {
      fetchStats();
    }
  }, [state[0].startDate, state[0].endDate]);

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div>
            <div className={styles.actions}>
              {/* <div className={styles.customDates}>
                <div className={styles.date} onClick={handle24hClick}>
                  24h
                </div>
                <div className={styles.date} onClick={handle7dClick}>
                  7d
                </div>
                <div className={styles.date} onClick={handle30dClick}>
                  30d
                </div>
                <div className={styles.date} onClick={handle90dClick}>
                  90d
                </div>
                <div className={styles.date} onClick={handleAllClick}>
                  All
                </div>
              </div> */}
              <div
                className={styles.dateToggler}
                onClick={() => setOpenDate(!openDate)}
              >
                {dayjs(state[0].startDate).format("MMM D, YYYY")} -{" "}
                {dayjs(state[0].endDate).format("MMM D, YYYY")}
                <IoChevronDownOutline
                  style={{
                    marginLeft: 5,
                  }}
                  size={14}
                />
              </div>
              {openDate && (
                <div className={styles.datePicker} ref={ref}>
                  <DateRangePicker
                    // @ts-ignore
                    onChange={(item) => setState([item.selection])}
                    // @ts-ignore
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    // @ts-ignore
                    ranges={state}
                    direction="horizontal"
                    inputRanges={[]}
                    staticRanges={[
                      {
                        label: "Today",
                        isSelected: () => false,
                        range: () => ({
                          startDate: new Date(),
                          endDate: new Date(),
                        }),
                      },
                      {
                        label: "Yesterday",
                        isSelected: () => false,
                        range: () => ({
                          startDate: new Date(
                            new Date().setDate(new Date().getDate() - 1)
                          ),
                          endDate: new Date(
                            new Date().setDate(new Date().getDate() - 1)
                          ),
                        }),
                      },
                      {
                        label: "This Week",
                        isSelected: () => false,
                        range: () => ({
                          startDate: new Date(
                            new Date().setDate(
                              new Date().getDate() - new Date().getDay()
                            )
                          ),
                          endDate: new Date(),
                        }),
                      },
                      {
                        label: "Last Week",
                        isSelected: () => false,
                        range: () => ({
                          startDate: new Date(
                            new Date().setDate(
                              new Date().getDate() - new Date().getDay() - 7
                            )
                          ),
                          endDate: new Date(
                            new Date().setDate(
                              new Date().getDate() - new Date().getDay() - 1
                            )
                          ),
                        }),
                      },
                      {
                        label: "This Month",
                        isSelected: () => true,
                        range: () => ({
                          startDate: new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                          ),
                          endDate: new Date(),
                        }),
                      },
                      {
                        label: "Last Month",
                        isSelected: () => false,
                        range: () => ({
                          startDate: new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() - 1,
                            1
                          ),
                          endDate: new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            0
                          ),
                        }),
                      },
                      {
                        label: "This Year",
                        isSelected: () => false,
                        range: () => ({
                          startDate: new Date(new Date().getFullYear(), 0, 1),
                          endDate: new Date(),
                        }),
                      },
                      {
                        label: "Last Year",
                        isSelected: () => false,
                        range: () => ({
                          startDate: new Date(
                            new Date().getFullYear() - 1,
                            0,
                            1
                          ),
                          endDate: new Date(new Date().getFullYear(), 0, 0),
                        }),
                      },
                      {
                        label: "All Time",
                        isSelected: () => false,
                        range: () => ({
                          startDate: new Date(0),
                          endDate: new Date(),
                        }),
                      },
                    ]}
                  />
                  {/* <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Select a date"
                /> */}
                </div>
              )}
            </div>
          </div>
        }
      >
        Dashboard
      </PageTitle>
      {loading ? (
        <EmptyState loading={true} />
      ) : (
        <>
          <div className={styles.topGrid}>
            {stats.map((stat) => (
              <div className={styles.statItem} key={stat.id}>
                <div className={styles.stat}>
                  <div
                    className={styles.icon}
                    style={{
                      backgroundColor: stat.color,
                    }}
                  >
                    {stat.icon}
                  </div>
                  <div className={styles.textSide}>
                    <div className={styles.statTitle}>{stat.title}</div>
                    <div className={styles.statValue}>
                      {stat.type === "currency"
                        ? formatCurrency(stat.value as number)
                        : stat.type === "percentage"
                        ? `${numeral(
                            (stat.value as number) > 100 ? 100 : stat.value
                          ).format("0[.]00")}%`
                        : stat.type === "number"
                        ? numeral(stat.value).format("0[.]00")
                        : stat.value}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.chartTitle}>Performance overview</div>
          <div className={styles.chartContainer}>
            <div style={{ height: 430 }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 20,
                    right: -10,
                    left: -10,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="date" />
                  <Tooltip content={<CustomTooltip />} />
                  <CartesianGrid strokeDasharray="4 5" horizontal={false} />

                  <Bar
                    dataKey="orders"
                    yAxisId="right"
                    barSize={20}
                    stackId="a"
                    fill="#9807ff"
                  />
                  <Bar
                    dataKey="visitors"
                    yAxisId="right"
                    barSize={20}
                    stackId="a"
                    fill="#42adf4"
                    shape={<Rectangle radius={[4, 4, 0, 0]} />}
                  />
                  <Line type="monotone" dataKey="sales" stroke="#39b637" />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
          <Card>
            <div className={styles.cardTitle}>Top Selling Products</div>
            {productsWithSales?.filter((prod: any) => prod.numberOfSales > 0)
              ?.length === 0 ? (
              <div className={styles.noData}>No data</div>
            ) : (
              <table className={styles.productsTable}>
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {productsWithSales
                    ?.filter((prod: any) => prod.numberOfSales > 0)
                    ?.map((product: any, index: any) => (
                      <tr key={index}>
                        <td>
                          <Link
                            className={styles.productNameContainer}
                            to={`/products/${product._id}`}
                          >
                            <img
                              src={
                                product.thumbnail
                                  ? makeCdnUrl(product.thumbnail)
                                  : "https://via.placeholder.com/600?text=Product+Image"
                              }
                            />
                            {product.name}
                          </Link>
                        </td>
                        <td>{formatCurrency(product.price)}</td>
                        <td>{numeral(product.numberOfSales).format()}</td>
                        {/* <td>${product.today}</td>
                  <td>${product.week}</td>
                  <td>${product.allTime}</td> */}
                        <td>{formatCurrency(product.totalRevenue)}</td>
                        <td>{formatCurrency(product.totalRefund)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </Card>
          <Card>
            <div className={styles.cardTitle}>Top Countries</div>
            {!countriesData || countriesData?.length === 0 ? (
              <div className={styles.noData}>No data</div>
            ) : (
              <table className={styles.productsTable}>
                <thead>
                  <tr>
                    {countriesColumns.map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {countriesData?.map((country: any, index: any) => {
                    const cntr = countries?.[country.country];
                    return (
                      <tr key={index}>
                        <td>
                          <div className={styles.country}>
                            <div>{cntr?.emoji}</div>
                            {cntr?.name}
                          </div>
                        </td>
                        <td>{numeral(country.customerCount).format()}</td>
                        <td>{numeral(country.salesCount).format()}</td>
                        {/* <td>{formatCurrency(country.salesAmount)}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </Card>
        </>
      )}
    </div>
  );
};

export default Dashboard;

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Date: ${label}`}</p>
        <ul className="tooltip-list">
          {payload.map((entry: any, index: any) => (
            <li key={`item-${index}`}>
              <span
                className="data-marker"
                style={{ backgroundColor: entry.color }}
              ></span>
              <span className="name">{entry.name}:</span>
              <span className="value">
                {entry.name === "sales"
                  ? formatCurrency(entry.value)
                  : entry.name === "visitors"
                  ? numeral(entry.value).format()
                  : numeral(entry.value).format()}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};
