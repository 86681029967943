import { Node } from "@tiptap/core";

// Custom Anchor Node
export const Anchor = Node.create({
  name: "anchor",
  group: "block",
  atom: true,

  addAttributes() {
    return {
      href: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "a",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "a",
      {
        ...HTMLAttributes,
        href: node.attrs.href,
        target: node.attrs.target,
        rel: node.attrs.rel,
      },
      ["span", {}, node.text || ""],
    ];
  },

  // @ts-ignore
  addCommands() {
    return {
      setAnchor:
        (attrs: any) =>
        ({ commands }: any) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              text: attrs.text,
              href: attrs.href,
              target: attrs.target || "_blank",
              rel: attrs.rel || "noopener noreferrer",
            },
          });
        },
    };
  },
});
