export const defaultVariant = {
  name: "",
  subtitle: "",
  price: 0,
  payWant: false,
  description: "",
  isFile: true,
  userId: "",
  file: "",
  url: "",
};
