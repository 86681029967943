import React from "react";

export const PageLoader: React.FC = () => {

  return (
    <div className="loader">
      Loading...
    </div>
  );
};
