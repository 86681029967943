import React from "react";
import style from "./TextArea.module.scss";

interface TextAreaProps {
  id?: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  error?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  rows = 3,
  error,
}) => {
  return (
    <textarea
      id={id}
      className={style.textArea + (error ? " " + style.isError : "")}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
    />
  );
};

export default TextArea;
