import React, { useEffect } from "react";
import styles from "./Products.module.scss";
import { PageLayout } from "src/components/PageLayout";
import { PageTitle } from "src/components/PageTitle";
import { Link, useNavigate } from "react-router-dom";
import Axios from "src/services/api";
import { Product } from "src/models/product";
import {
  Button,
  EmptyState,
  Modal,
  SwitchInput,
  TextInput,
} from "src/components";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import Gumroad from "./gumroad.png";
import LemonSqueezy from "./lemonsqueezy.svg";
import usePopups from "src/services/usePopups";
import ImportModal from "./ImportModal";
import { Count } from "../Customers/Customers";
import ImportlmsModal from "./ImportlmsModal";
import { makeCdnUrl } from "src/services/cdn";
import { AiOutlineDelete } from "react-icons/ai";
import { IoAlertCircle } from "react-icons/io5";

const columns = [
  "Name",
  // "Orders",
  "Price",
  // "Today",
  // "7 days",
  // "All time",
  "Created at",
  "View product",
  "Published",
  "",
];

const Products: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string>("");
  const [filteredProducts, setFilteredProducts] = React.useState<any[]>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [id, setId] = React.useState<string>("");
  const { popups, togglePopup } = usePopups([
    "import",
    "bankAlert",
    "importlms",
    "deleteProduct",
  ]);
  const [reload, setReload] = React.useState<boolean>(false);
  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);

  // const [publishedProducts, setPublishedProducts] = React.useState<Product[]>(
  //   []
  // );

  useEffect(() => {
    toast.promise(
      Axios.get<Product[]>("/products").then((response) => {
        setProducts(response.data);
        // setPublishedProducts(response.data.filter((p: any) => p.published));
        setLoading(false);
      }),
      {
        loading: "Loading products...",
        success: "Loaded!",
        error: "Error while loading.",
      }
    );
  }, [reload]);

  useEffect(() => {
    if (search === "") {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter((product) => product.name.includes(search))
      );
    }
  }, [search, products]);

  const [stripeAccount, setStripeAccount] = React.useState<any>(null);

  useEffect(() => {
    Axios.get("/users/stripe-account").then((res) => {
      setStripeAccount(res.data);
    });
  }, []);

  const hasBankAccount = stripeAccount?.external_accounts?.data.length > 0;
  const hasPayPal = !!me?.merchantId && !!me?.merchantIdInPayPal;

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div className={styles.searchInputContainer}>
            <Button
              variant="secondary"
              onClick={() => togglePopup("import")}
              text={
                <div className={styles.buttonText}>
                  <img className={styles.gumroad} src={Gumroad} alt="Gumroad" />
                  <div className={styles.text}>Import Gumroad</div>
                </div>
              }
            />
            <Button
              variant="secondary"
              onClick={() => togglePopup("importlms")}
              text={
                <div className={styles.buttonText}>
                  <img className={styles.lms} src={LemonSqueezy} alt="LMS" />
                  <div className={styles.text}>Import Lemon Squeezy</div>
                </div>
              }
            />
            <Link to="/products/new">
              <Button text="New product" />
            </Link>
          </div>
        }
      >
        Products
      </PageTitle>
      {loading || filteredProducts?.length === 0 ? (
        <EmptyState
          loading={loading}
          title="Create your first product"
          subTitle="Create your first product and start selling it to your customers."
          buttonText="Create product"
          buttonClick={() => {
            navigate("/products/new");
          }}
        />
      ) : (
        <>
          <div className={styles.seachInput}>
            <TextInput
              name="search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
              placeholder="Search products"
            />
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.productsTable}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredProducts?.map((product, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{ width: "40%" }}
                        onClick={() => navigate(`/products/${product._id}`)}
                      >
                        <div className={styles.productNameContainer}>
                          <img
                            src={
                              product.thumbnail
                                ? makeCdnUrl(product.thumbnail)
                                : "https://via.placeholder.com/600?text=Product+Image"
                            }
                          />
                          <div className={styles.productName}>
                            {product.name}
                          </div>
                        </div>
                      </td>
                      {/* <td style={{ width: "20%" }}>
                      {numeral(product.sales).format("0[.]00")}
                    </td> */}
                      <td
                        style={{ width: "10%" }}
                        onClick={() => navigate(`/products/${product._id}`)}
                      >
                        {formatCurrency(product.price)}
                      </td>
                      {/* <td style={{ width: "10%" }}>
                      {formatCurrency(product.today)}
                    </td>
                    <td style={{ width: "10%" }}>
                      {formatCurrency(product.week)}
                    </td>
                    <td style={{ width: "10%" }}>
                      {formatCurrency(product.allTime)}
                    </td> */}
                      <td style={{ width: "20%" }}>
                        {dayjs(product.createdAt).fromNow()}
                      </td>
                      <td style={{ width: "20%" }}>
                        {!hasBankAccount &&
                        !hasPayPal &&
                        (product.price !== 0 || product.payWant) ? (
                          <div
                            onClick={() => {
                              if (!hasBankAccount && !hasPayPal)
                                togglePopup("bankAlert");
                            }}
                          >
                            <a target="_blank">
                              <Button variant="tertiary" text="View" />
                            </a>
                          </div>
                        ) : (
                          <a
                            href={`https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/${product._id}`}
                            target="_blank"
                          >
                            <Button variant="tertiary" text="View" />
                          </a>
                        )}
                      </td>
                      <td
                        style={{ width: "30%" }}
                        // style={{ color: product.published ? "#1ecc1e" : "red" }}
                        onClick={(e) => {
                          console.log(product._id);
                        }}
                      >
                        {/* {product.published ? "Published" : "Unpublished"} */}
                        <div className={styles.switch}>
                          <span>{product.published ? "Yes" : "No"}</span>
                          <SwitchInput
                            checked={
                              !hasBankAccount &&
                              !hasPayPal &&
                              (product.price !== 0 || product.payWant)
                                ? false
                                : product.published
                            }
                            onChange={(checked) => {
                              if (
                                !hasBankAccount &&
                                !hasPayPal &&
                                (product.price !== 0 || product.payWant)
                              ) {
                                togglePopup("bankAlert");
                                return;
                              }

                              toast.promise(
                                Axios.post(
                                  `/products/${product._id}/published`,
                                  {
                                    published: checked,
                                  }
                                ).then(() => {
                                  setReload(!reload);
                                }),
                                {
                                  loading: "Publishing product",
                                  success: checked
                                    ? "Product published"
                                    : "Product unpublished",
                                  error: "Error publishing product",
                                }
                              );
                            }}
                            id={"published" + product._id}
                            name={"published" + product._id}
                          />
                        </div>
                      </td>
                      <td style={{ paddingLeft: "20px" }}>
                        <Button
                          isIcon
                          variant="secondary"
                          text={
                            <AiOutlineDelete
                              size={18}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          }
                          onClick={() => {
                            togglePopup("deleteProduct");
                            setId(product._id);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Count items={filteredProducts} />
        </>
      )}
      {/* <div className={styles.tableHeader}>
        {columns.map((column, index) => (
          <div key={index} className={styles.tableHeaderItem}>
            {column}
          </div>
        ))}
      </div> */}
      {popups.import && (
        <ImportModal
          onClose={() => {
            setReload(!reload);
            togglePopup("import");
          }}
        />
      )}
      {popups.importlms && (
        <ImportlmsModal
          onClose={() => {
            setReload(!reload);
            togglePopup("importlms");
          }}
        />
      )}
      {popups.deleteProduct && (
        <Modal
          onClose={() => togglePopup("deleteProduct")}
          title="Delete Product"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("deleteProduct");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Delete",
            onClick: () => {
              Axios.delete("/products/" + id)
                .then(() => {
                  togglePopup("deleteProduct");
                  toast.success("Product deleted!");
                  navigate("/products");
                })
                .catch((error) => {
                  console.log(error);
                  togglePopup("deleteProduct");
                  toast.error("Failed to delete product");
                });
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to delete this product?
          </div>
        </Modal>
      )}
      {popups.bankAlert && (
        <Modal
          onClose={() => togglePopup("bankAlert")}
          title="Connect a Payment Method"
          footerRightButton1={{
            // @ts-ignore
            label: "Cancel",
            onClick: () => {
              togglePopup("bankAlert");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Connect Bank Account or PayPal",
            onClick: () => {
              navigate("/profile/payments");
            },
          }}
        >
          <div className={styles.bankModalText}>
            <IoAlertCircle fontSize={24} className={styles.bankModalIcon} />
            Your product isn't free, so to start making sales you need to
            connect either your bank account or PayPal so you can get paid out.
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Products;
