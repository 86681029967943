import React from "react";
import styles from "../../Upsell.module.scss";
import { FormGroup, TextArea, TextInput } from "src/components";
import TogglableArea from "src/pages/Product2/steps/DefineProduct/TogglableArea";
import { UpsellType } from "../../Upsell";

const DefineUpsell = ({
  upsell,
  setUpsell,

  errors,
}: {
  upsell: UpsellType;
  setUpsell: React.Dispatch<React.SetStateAction<UpsellType>>;

  errors: any;
}) => {
  const onChange = (key: string, value: string) => {
    setUpsell({ ...upsell, [key]: value });
  };

  return (
    <div className={styles.step}>
      <h1>Define your upsell</h1>
      <p className={styles.desc}>
        Add an upsell name, create an attractive offer, add a description to
        highlight why your customers should buy your upsell, and customize
        buttons text.
      </p>
      <div className={styles.form}>
        <FormGroup label="Name" error={errors.name} required>
          <TextInput
            onChange={(e: any) => onChange("name", e.target.value)}
            value={upsell.name}
            name="name"
            placeholder="Enter upsell name"
            error={errors.name}
          />
        </FormGroup>
        <TogglableArea
          title="Upsell settings"
          subTitle="Get more sales from your upsell by customizing the offer, adding a description, and crafting the call-to-action text."
        >
          <FormGroup label="Offer" error={errors.offer} required>
            <TextInput
              onChange={(e: any) => onChange("offer", e.target.value)}
              value={upsell.offer || ""}
              name="offer"
              placeholder="Enter upsell offer"
              error={errors.offer}
            />
          </FormGroup>
          <FormGroup label="Description" error={errors.upsellDescription}>
            <TextArea
              onChange={(e: any) =>
                onChange("upsellDescription", e.target.value)
              }
              value={upsell.upsellDescription || ""}
              name="description"
              placeholder="Enter upsell description"
              error={errors.upsellDescription}
            />
          </FormGroup>
          <div className={styles.formGrid}>
            <FormGroup
              label="Decline button text"
              error={errors.secondaryButtonText}
              required
            >
              <TextInput
                name="secondaryButtonText"
                value={upsell.secondaryButtonText}
                error={errors.secondaryButtonText}
                onChange={(e) =>
                  onChange("secondaryButtonText", e.target.value)
                }
                placeholder="Enter decline button text"
              />
            </FormGroup>
            <FormGroup
              label="Accept offer button text"
              error={errors.primaryButtonText}
              required
            >
              <TextInput
                name="primaryButtonText"
                value={upsell.primaryButtonText}
                onChange={(e) => onChange("primaryButtonText", e.target.value)}
                placeholder="Enter accept button text"
                error={errors.primaryButtonText}
              />
            </FormGroup>
          </div>
        </TogglableArea>
      </div>
    </div>
  );
};

export default DefineUpsell;
