import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import Axios from "src/services/api";
import { useNavigate } from "react-router-dom";
import { PageLoader } from "src/components/page-loader";

const Callback: React.FC = () => {
  const [created, setCreated] = React.useState(false);
  const { user } = useAuth0();
  const navigate = useNavigate();

  // let's store the access token in local storage
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      const accessToken = await getAccessTokenSilently();
      localStorage.setItem("accessToken", accessToken);

      if (!user && !created) return;

      setCreated(true);
      Axios.post(
        "/users",
        {
          name: user?.name,
          given_name: user?.given_name,
          family_name: user?.family_name,
          middle_name: user?.middle_name,
          picture: user?.picture,
          email: user?.email,
          birthdate: user?.birthdate,
          zoneinfo: user?.zoneinfo,
          locale: user?.locale,
          phone_number: user?.phone_number,
          address: user?.address,
          nickname: user?.nickname,

          referral: localStorage.getItem("referrer") || "direct",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then(async (response) => {
          console.log("User created");
          // delete referrer from local storage
          localStorage.removeItem("referrer");

          // check if product-bought is in local storage
          const productBought = localStorage.getItem("product-bought");
          if (productBought) {
            await Axios.post(
              "/bought-products",
              {
                product: JSON.parse(productBought),
                buyerUser: response.data.userId,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
          }

          // remove product-bought from local storage
          localStorage.removeItem("product-bought");

          window.location.href = "/";
        })
        .catch((err) => {
          if (
            err.response.status === 400 &&
            err.response.data.message === "User already exists"
          ) {
            console.log("User created");
            // localStorage.removeItem("referrer");
            navigate("/");
          }
        });
    };

    getAccessToken();
  }, [user]);

  return <PageLoader />;
};

export default React.memo(Callback);
