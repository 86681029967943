import axios from "axios";

export async function downloadFile(fileUrl: string, fileName: string) {
  try {
    // Fetch the file and convert the response to a Blob
    const response = await axios.get(fileUrl, {
      responseType: "blob",
    });
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });

    // Create a URL for the Blob and extract the file name from the fileUrl
    const blobUrl = URL.createObjectURL(blob);
    const name = fileName + getFileExtension(fileUrl);

    // Create an anchor element and trigger the download
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Release the Blob URL after the download is triggered
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 100);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}

function getFileExtension(fileUrl: string): string {
  const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
  const lastDotPosition = fileName.lastIndexOf(".");

  if (lastDotPosition === -1) {
    return "";
  }
  return "." + fileName.substring(lastDotPosition + 1);
}
