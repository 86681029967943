import React, { useEffect, useState } from "react";
import styles from "../../Refund.module.scss";
import { FormGroup, InfoIcon, Select, TextInput, TipTap } from "src/components";
import { defaultRefundPolicy } from "../../Refund.consts";

const DefineRefund = ({
  refund,
  setRefund,

  errors,
}: {
  refund: any;
  setRefund: any;

  errors: any;
}) => {
  const onChange = (key: string, value: string) => {
    setRefund({ ...refund, [key]: value });
  };

  const [template, setTemplate] = useState<string>("");

  return (
    <div className={styles.step}>
      <h1>
        Define your refund{" "}
        <InfoIcon text="You can't edit this refund because it's already in use by some of your customers." />
      </h1>
      <p className={styles.desc}>
        Add an refund name, write the refund policy, added the refund period,
        and select the products that this refund will apply to.
      </p>
      <div className={styles.form}>
        <FormGroup label="Name" error={errors.name} required>
          <TextInput
            onChange={(e: any) => onChange("name", e.target.value)}
            value={refund.name}
            name="name"
            placeholder="Enter refund name"
            error={errors.name}
          />
        </FormGroup>
        <FormGroup label="" error={errors.period} dummy>
          <div className={styles.formGrid}>
            <FormGroup label="Refund period">
              <TextInput
                onChange={(e: any) => onChange("period", e.target.value)}
                value={refund.period}
                error={errors.period}
                name="period"
                placeholder="0"
                type="number"
              />
            </FormGroup>
            <FormGroup label="Period type">
              <Select
                options={[
                  {
                    label: "Days",
                    value: "days",
                  },
                  {
                    label: "Weeks",
                    value: "weeks",
                  },
                ]}
                onChange={(value) => onChange("periodType", value)}
                selectedValue={refund.periodType}
              />
            </FormGroup>
          </div>
        </FormGroup>
        <FormGroup
          label="Refund policy"
          error={errors.policy}
          required
          action={
            <div
              onClick={() => {
                onChange("policy", defaultRefundPolicy);
                setTemplate(defaultRefundPolicy);
              }}
              className={styles.formAction}
            >
              Use example template
            </div>
          }
        >
          <TipTap
            error={errors.policy}
            content={refund.policy}
            onChange={(content: string) => onChange("policy", content)}
            refund
            template={template}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default DefineRefund;
