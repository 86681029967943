import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./styles/styles.css";
import { Toaster } from "react-hot-toast";
import { inject } from "@vercel/analytics";

inject();


const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/*",
    element: <App />,
  },
]);

root.render(
  <Auth0ProviderWithNavigate>
    <RouterProvider router={router} />
    <Toaster />
  </Auth0ProviderWithNavigate>
);
