// ErrorBanner.tsx

import React from "react";
import { X } from "react-feather";
import styles from "./ErrorBanner.module.scss";
import { FaInfoCircle } from "react-icons/fa";

interface ErrorBannerProps {
  message: string;
  type?: string;
  onClose?: () => void;
}

const ErrorBanner: React.FC<ErrorBannerProps> = ({ message, onClose, type }) => {
  return (
    <div className={`${styles.errorBanner} ${type === 'info' ? styles.info : ''}`}>
      <div className={styles.iconContainer}>
      <FaInfoCircle size={20}/>
      </div>
      <div className={styles.message}>{message}</div>
      {onClose && (
        <button className={styles.closeButton} onClick={onClose}>
          <X size={18} />
        </button>
      )}
    </div>
  );
};

export default ErrorBanner;
