import React, { useEffect, useState } from "react";

import styles from "./Discounts.module.scss";
import { Button, EmptyState, PageTitle } from "src/components";
import "react-datepicker/dist/react-datepicker.css";

import Axios from "src/services/api";
import { formatCurrency } from "src/services/currency";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Count } from "../Customers/Customers";
import "../Dashboard/Dashboard.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const columns = ["Code", "Discount", "Applied to", "Used", "Created at"];

const Discounts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [discounts, setDiscounts] = useState<any[]>([]);
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (!firstTime) return;
    setFirstTime(false);
    Axios.get("/discounts").then((response) => {
      setDiscounts(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <Link to="/discounts/new">
            <Button text="New discount" />
          </Link>
        }
      >
        Discounts
      </PageTitle>
      {loading || discounts?.length === 0 ? (
        <EmptyState
          loading={loading}
          title="Create your first discount"
          subTitle="Create discounts to offer deals to your customers."
          buttonText="Create discount"
          buttonClick={() => {
            navigate("/discounts/new");
          }}
        />
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.productsTable}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {discounts?.map((discount, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        navigate(`/discounts/${discount._id}`);
                      }}
                    >
                      <td>
                        <div className={styles.ellipsis}>{discount.code}</div>
                      </td>
                      <td>
                        <div className={styles.ellipsis}>
                          {discount.valueType === "percentage"
                            ? `${discount.value}% off`
                            : formatCurrency(discount.value) + " off"}
                        </div>
                      </td>
                      <td style={{ width: "15%" }}>
                        <div className={styles.ellipsis}>
                          {discount.mainProductIds.length || 0} product
                          {(discount.mainProductIds.length > 1 ||
                            !discount.mainProductIds.length) &&
                            "s"}
                        </div>
                      </td>
                      <td style={{ width: "15%" }}>
                        <div className={styles.ellipsis}>
                          {discount.used || 0} time
                          {(discount.used > 1 || !discount.used) && "s"}
                        </div>
                      </td>
                      <td>
                        <div className={styles.ellipsis}>
                          {dayjs(discount.createdAt).fromNow()}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Count items={discounts} />
        </>
      )}
    </div>
  );
};

export default Discounts;
