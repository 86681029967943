export const PRODUCT = {
  name: "Sample Product Name",
  price: 0,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, nunc nisl ultricies nunc, vitae a eros. <br><br> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, nunc nisl ultricies nunc, vitae a eros. <br><br> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, nunc nisl ultricies nunc, vitae a eros.",
  thumbnail: null,
  images: [],
  colors: [
    {
      id: "title",
      name: "Title",
      value: "#000000",
    },
    {
      id: "buttons",
      name: "Buttons background",
      value: "#3447ef",
    },
    {
      id: "buttonsText",
      name: "Buttons text",
      value: "#FFFFFF",
    },
    {
      id: "background",
      name: "Background",
      value: "#FFFFFF",
    },
    {
      id: "text",
      name: "Text",
      value: "#000000",
    },
    {
      id: "borders",
      name: "Borders",
      value: "#cfd7df",
    },
  ],
  url: "",
  published: true,
  isFile: false,
  ctaText: "Buy Now",
  checkoutText:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, nunc nisl ultricies nunc, vitae a eros. ",
  thankYouText: "",
  payWant: false,
  minPrice: 0,
  maxPrice: 0,
  showSales: false,
  showReviews: false,
  design: 1,

  upsell: {},
};
