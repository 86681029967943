import React, { useMemo } from "react";
import styles from "./VerifyEmail.module.scss";
import { TbReload } from "react-icons/tb";
import Axios from "src/services/api";
import { toast } from "react-hot-toast";
import Gmail from "./gmail.svg";
import Outlook from "./outlook.svg";
import { useAuth0 } from "@auth0/auth0-react";

const VerifyEmail = () => {
  const { logout } = useAuth0();
  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );
  return (
    <div className={styles.pagelayout}>
      <div
        className={styles.logout}
        onClick={() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("me");
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }}
      >
        Logout
      </div>
      <div className={styles.pagecontent}>
        <h1>Verify your email</h1>
        <p>
          We sent you an email with a link to verify <b>{me.email}</b>.<br />
          Please check your inbox at and click the link in the email.
        </p>
        <div className={styles.gmail}>
          <a href="https://gmail.com" target="_blank">
            <div className={styles.item}>
              <img src={Gmail} alt="Gmail" />
              <div className={styles.text}>Open Gmail</div>
            </div>
          </a>
          <a href="https://outlook.com" target="_blank">
            <div className={styles.item}>
              <img src={Outlook} alt="Outlook" />
              <div className={styles.text}>Open Outlook</div>
            </div>
          </a>
        </div>
        <div className={styles.resend}>
          <TbReload size={15} />{" "}
          <div
            onClick={() => {
              toast.promise(
                Axios.post("/users/send-verification-email", {
                  email: me.email,
                }).then((res) => {
                  console.log(res);
                }),
                {
                  loading: "Sending...",
                  success: "Sent!",
                  error: "Error while sending.",
                }
              );
            }}
          >
            Resend
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
