import React, { useEffect } from "react";
import { ChromePicker } from "react-color";
import { Button, FormGroup, Modal } from "src/components";
import styles from "./ColorsModal.module.scss";

interface ColorsModalProps {
  colors: {
    id: string;
    name: string;
    value: string;
  }[];
  onChange: (colors: { id: string; name: string; value: string }[]) => void;
  onClose: () => void;
}

const ColorsModal = ({ colors, onChange, onClose }: ColorsModalProps) => {
  const [colorsState, setColorsState] = React.useState<typeof colors>(colors);
  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);

  return (
    <Modal
      onClose={onClose}
      title="Change colors"
      footerRightButton1={{
        label: "Cancel",
        onClick: onClose,
      }}
      footerRightButton2={{
        label: "Save",
        onClick: () => onChange(colorsState),
      }}
    >
      <div className={styles.modalContent}>
        <div className={styles.buttonContainer}>
          <Button
            text={"Use global colors"}
            onClick={() => setColorsState(me?.colors)}
          />
        </div>
        {colorsState.map((color, index) => (
          <FormGroup label={color.name} key={index}>
            <ColorInput
              value={color.value}
              onChange={(value) =>
                setColorsState((clrs) => {
                  const newColors = [...clrs];
                  newColors[index].value = value;
                  return newColors;
                })
              }
            />
          </FormGroup>
        ))}
      </div>
    </Modal>
  );
};

interface ColorInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const ColorInput = ({ value, onChange }: ColorInputProps) => {
  const [showPicker, setShowPicker] = React.useState<boolean>(false);
  const wrapperRef = React.useRef(null);
  useOutsideClick(wrapperRef, () => {
    setShowPicker(false);
  });

  return (
    <div className={styles.colorComponent} onClick={() => setShowPicker(true)}>
      <div
        className={styles.color}
        style={{
          backgroundColor: value,
        }}
      />
      {showPicker && (
        <div className={styles.pickerContainer} ref={wrapperRef}>
          <ChromePicker
            color={value}
            onChange={(value) => onChange(value.hex)}
          />
        </div>
      )}
      <div className={styles.code}>{value}</div>
    </div>
  );
};

function useOutsideClick(ref: any, callback: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default ColorsModal;
