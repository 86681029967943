import React from "react";
import styles from "./Product.module.scss";
import { IoChevronDownOutline } from "react-icons/io5";

const TogglableArea = ({
  title,
  subTitle,
  children,
  required,
}: {
  title: string;
  subTitle: string;
  children?: React.ReactNode;
  required?: boolean;
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const [twoLine, setTwoLine] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLParagraphElement>(null);

  // determine if the subtitle is two lines
  React.useEffect(() => {
    if (ref.current) {
      const height = ref.current.clientHeight;
      if (height > 20) {
        setTwoLine(true);
      }
    }
  }, [ref]);

  return (
    <div
      className={
        styles.togglableArea +
        " " +
        (!isOpen ? styles.closed : "") +
        " " +
        (twoLine ? styles.twoLine : "")
      }
    >
      <div className={styles.chevron} onClick={() => setIsOpen(!isOpen)}>
        <IoChevronDownOutline size={20} />
      </div>
      <div className={styles.content} onClick={() => setIsOpen(!isOpen)}>
        <h3 className={styles.togglableTitle}>
          {title}
          {required && <span className={styles.required}>*</span>}
        </h3>
        <p ref={ref} className={styles.togglableP}>
          {subTitle}
        </p>
      </div>
      {children}
    </div>
  );
};

export default TogglableArea;
