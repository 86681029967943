import React from "react";
import styles from "./Product.module.scss";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

type Item = {
  id: string;
  title: string;
  subTitle: string;
  soon?: boolean;
};

const TogglableTabs = ({
  items,
  onChange,
  value,
}: {
  items: Item[];
  onChange: (value: string) => void;
  value: string;
}) => {
  return (
    <div className={styles.togglableTabs}>
      {items.map((item) => (
        <div
          key={item.id}
          className={`${styles.tab} ${
            value === item.id ? styles.selected : ""
          } ${
            item.soon
              ? styles.soon
              : ""
          }`}
          onClick={() => onChange(item.id)}
        >
          {value === item.id && (
            <div className={styles.check}>
              <IoCheckmarkCircleSharp size={18} />
            </div>
          )}
          {
            item.soon
              ? <h3 className={styles.soonText}>Soon</h3>
              : null
          }
          <h3 className={styles.togglableTabTitle}>{item.title}</h3>
          <p className={styles.togglableTabP}>{item.subTitle}</p>
        </div>
      ))}
    </div>
  );
};

export default TogglableTabs;
