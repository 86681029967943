import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "src/services/api";
import styles from "./Creator.module.scss";
import { CreatorNavBar, EmptyState } from "src/components";
import { PageLoader } from "src/components/page-loader";
import { formatCurrency } from "src/services/currency";
import { Rating } from "react-simple-star-rating";

const Creator = ({ userId, _subdomain, _user }: any) => {
  const currentSubdomain = _subdomain || window.location.hostname.split(".")[0];

  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [currency, setCurrency] = useState<string>("USD");

  useEffect(() => {
    if (_user) {
      setUser({
        ...user,
        products: user?.products || [],
        user: _user,
      });
      setLoading(false);
    }
  }, [_user]);

  useEffect(() => {
    Axios.get(`/users/${currentSubdomain}`)
      .then((res) => {
        setUser({
          user: _user ? _user : res.data?.user,
          products: res.data?.products.filter(
            (product: any) => product.published
          ),
          reviews: res.data?.reviews,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 404) {
          window.location.href = "https://app.pocketsflow.com";
        }
      });
    // Axios.get("https://api.ipgeolocation.io/ipgeo", {
    //   params: {
    //     apiKey: "5c920f814db94414b5f7f70bd9dd56de",
    //   },
    // })
    //   .then((resIQ) => {
    //     const country = resIQ.data.country_code2;
    //     const _currency = resIQ.data.currency.code;

    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });
  }, []);

  const footerColor = {
    color: "#000",
  };

  if (loading)
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );

  return (
    <div
      className={`${styles.pageContainer} ${userId ? styles.isOnSettings : ""}`}
    >
      <CreatorNavBar creator={user.user} isCreatorPage />
      {user.user.tagLine && (
        <div className={styles.tagLine}>
          <h1>{user.user.tagLine}</h1>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.productsGrid}>
          {user.products.length === 0 && (
            <EmptyState
              loading={loading}
              title="This user has no products yet"
              subTitle="Check back later to see if they have any products for sale."
            />
          )}
          {user.products.map((product: any) => {
            const reviews = user.reviews.filter(
              (review: any) => review.productId === product._id
            );

            const totalRating = reviews.reduce(
              (acc: number, review: any) => acc + review.rating,
              0
            );

            const averageRating = isNaN(
              parseFloat((totalRating / reviews.length).toFixed(1))
            )
              ? 0
              : parseFloat((totalRating / reviews.length).toFixed(1));

            return (
              <Link
                to={`https://${user.user.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/${product._id}`}
                key={product._id}
              >
                <div className={styles.productContainer}>
                  <div className={styles.imageContainer}>
                    <img
                      className={styles.image}
                      src={
                        product.thumbnail ||
                        "https://via.placeholder.com/600?text=" + product.name
                      }
                      alt={product.name}
                    />
                  </div>
                  <h3>{product.name}</h3>
                  <div className={styles.userAmount}>
                    <div className={styles.ratingContainer}>
                      <Rating
                        style={{
                          pointerEvents: "none",
                        }}
                        initialValue={averageRating}
                        size={20}
                        transition
                        disableFillHover
                        allowFraction
                        allowHover={false}
                      />
                      <div className={styles.ratingNumber}>
                        {averageRating || "No rating"}{" "}
                        {reviews.length > 0 && <>({reviews.length})</>}
                      </div>
                    </div>
                    <div className={styles.amount}>
                      {formatCurrency(product.price, currency)}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <div className={styles.footer} style={footerColor}>
        Powered by{" "}
        <a
          href="https://www.pocketsflow.com/"
          target="_blank"
          style={footerColor}
        >
          Pocketsflow
        </a>
      </div>
    </div>
  );
};

export default Creator;
