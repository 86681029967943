import React, { useState } from "react";
import styles from "../../Upsell.module.scss";
import {
  Button,
  CheckInput,
  ErrorBanner,
  FormGroup,
  Select,
  SwitchInput,
  TextInput,
  TipTap,
} from "src/components";
import { UpsellType } from "../../Upsell";
import TogglableArea from "src/pages/Product2/steps/DefineProduct/TogglableArea";
import { formatCurrency } from "src/services/currency";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

const ViewUpsell = ({
  upsell,
  setUpsell,
  errors,
  upsellProducts,
  loadingProducts,
  products,
}: {
  upsell: UpsellType;
  setUpsell: React.Dispatch<React.SetStateAction<UpsellType>>;

  id?: string;
  errors: any;

  upsellProducts: any;

  products: any;

  loadingProducts: boolean;
}) => {
  const onChange = (key: string, value: any) => {
    setUpsell({ ...upsell, [key]: value });
  };

  return (
    <div className={styles.step}>
      <h1>Select upsell products</h1>
      <p className={styles.desc}>
        Select the product you want to upsell to your customers and the products
        to apply the upsell to.
      </p>
      <div className={styles.form}>
        <TogglableArea
          title="Upsell and price"
          subTitle="Choose which product you want to upsell to your customers and the price you want to offer it for."
        >
          <FormGroup
            label="Product to offer as upsell"
            required
            error={errors.upsellProductId}
          >
            {loadingProducts ? (
              "Loading products..."
            ) : (
              <Select
                selectedValue={upsell.upsellProductId}
                onChange={(value) => onChange("upsellProductId", value)}
                options={upsellProducts}
              />
            )}
          </FormGroup>
          <FormGroup
            label="Upsell price"
            error={errors.price}
            required
            action={
              <div
                className={styles.toggleFile}
                onClick={() => {
                  toast.success("Upsell price set to original product price");
                  onChange(
                    "upsellPrice",
                    upsellProducts.find(
                      (p: any) => p._id === upsell.upsellProductId
                    )?.price || 0
                  );
                }}
              >
                Use original price
              </div>
            }
          >
            <TextInput
              name="upsellPrice"
              value={upsell.upsellPrice}
              onChange={(e) => onChange("upsellPrice", e.target.value)}
              placeholder="Enter price"
              error={errors.price}
              isCurrency
              type="number"
            />
          </FormGroup>
        </TogglableArea>
        <TogglableArea
          title="Products to apply upsell to"
          subTitle="Choose the products you want to apply the upsell to. You can select multiple products."
        >
          {errors.mainProductIds && (
            <ErrorBanner message={errors.mainProductIds}></ErrorBanner>
          )}
          {products.length === 0 ? (
            <div className={styles.emptyList}>
              Either you don't have products, or all of them have upsells
              already
              <br />
              <br />
              {/* button to create new product */}
              <Link to="/products/new">
                <Button text="Create new product" />
              </Link>
            </div>
          ) : (
            <div>
              <div className={styles.selectAll}>
                <CheckInput
                  name="selectAll"
                  checked={upsell.mainProductIds?.length === products.length}
                  onChange={(checked) => {
                    if (checked) {
                      onChange(
                        "mainProductIds",
                        products.map((product: any) => product._id)
                      );
                    } else {
                      onChange("mainProductIds", []);
                    }
                  }}
                  id={"selectAll"}
                />
                <label htmlFor="selectAll">Select all</label>
              </div>
              <div className={styles.productsList}>
                {products.map((product: any, index: any) => {
                  const prodActive =
                    upsell.mainProductIds?.includes(product._id) || false;
                  return (
                    <div className={styles.productItem} key={index}>
                      <CheckInput
                        name="prodActive"
                        checked={prodActive}
                        onChange={(checked) => {
                          if (checked) {
                            onChange("mainProductIds", [
                              ...(upsell.mainProductIds || []),
                              product._id,
                            ]);
                          } else {
                            onChange(
                              "mainProductIds",
                              upsell.mainProductIds?.filter(
                                (id: any) => id !== product._id
                              )
                            );
                          }
                        }}
                        id={"prodActive" + index}
                      />
                      <Link
                        className={styles.productNameContainer}
                        to={`/products/${product._id}`}
                      >
                        <img
                          src={
                            product.thumbnail ||
                            "https://via.placeholder.com/600?text=Product+Image"
                          }
                        />
                        <span className={styles.productName}>
                          {product.name}
                        </span>
                      </Link>
                      <div className={styles.productPrice}>
                        {formatCurrency(product.price)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </TogglableArea>
      </div>
    </div>
  );
};

export default ViewUpsell;
