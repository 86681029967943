import React from "react";
import styles from "./CreatorNavBar.module.scss";
import { Button } from "../Button";
import {
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaPinterestP,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

interface Creator {
  name: string;
  picture: string;
  userId: string;
  _id: string;
}

interface CreatorNavBarProps {
  creator: any;
  isCreatorPage?: boolean;
}

const CreatorNavBar: React.FC<CreatorNavBarProps> = ({
  creator,
  isCreatorPage,
}) => {
  return (
    <header>
      <nav className={styles.navbar}>
        <div className={styles.navBarContainer}>
          {/* href={"/creator/" + creator.userId}  */}
          <a className={styles.navBrand}>
            <img
              src={creator.picture}
              alt="Creator Profile Picture"
              className={styles.creatorProfilePicture}
            />
            <span className={styles.creatorName}>
              {creator.firstName} {creator.lastName}
            </span>
          </a>
          <div className={styles.navCta}>
            {/* <div className={styles.follow}>
            <div>Follow</div>
            <div className="social-media-icons">
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://www.twitter.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF size={24} />
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn size={24} />
              </a>
            </div>
          </div> */}
            {/* <div className={styles.sep} /> */}
            {isCreatorPage && (
              <div className={styles.socialLinks}>
                {creator.twitter && creator.twitter !== "" && (
                  <a href={creator.twitter} target="_blank">
                    <Button
                      isIcon
                      text={<FaTwitter size={24} />}
                      variant="secondary"
                    />
                  </a>
                )}
                {creator.facebook && creator.facebook !== "" && (
                  <a href={creator.facebook} target="_blank">
                    <Button
                      isIcon
                      text={<FaFacebookF size={24} />}
                      variant="secondary"
                    />
                  </a>
                )}
                {creator.instagram && creator.instagram !== "" && (
                  <a href={creator.instagram} target="_blank">
                    <Button
                      isIcon
                      text={<FaInstagram size={24} />}
                      variant="secondary"
                    />
                  </a>
                )}
                {creator.youtube && creator.youtube !== "" && (
                  <a href={creator.youtube} target="_blank">
                    <Button
                      isIcon
                      text={<FaYoutube size={24} />}
                      variant="secondary"
                    />
                  </a>
                )}
                {creator.tiktok && creator.tiktok !== "" && (
                  <a href={creator.tiktok} target="_blank">
                    <Button
                      isIcon
                      text={<FaTiktok size={24} />}
                      variant="secondary"
                    />
                  </a>
                )}
                {creator.linkedin && creator.linkedin !== "" && (
                  <a href={creator.linkedin} target="_blank">
                    <Button
                      isIcon
                      text={<FaLinkedinIn size={24} />}
                      variant="secondary"
                    />
                  </a>
                )}
              </div>
            )}
            {!isCreatorPage && (
              <Link
                to={`https://${creator.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}`}
              >
                <Button text="View Creator Profile" variant="secondary" />
              </Link>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default CreatorNavBar;
