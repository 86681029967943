import React from "react";
import { FormGroup, Modal, TextInput } from "src/components";
import styles from "./Products.module.scss";
import Gumroad from "./gumroad.png";
import Axios from "src/services/api";
import { toast } from "react-hot-toast";

const ImportModal = ({ onClose }: any) => {
  const [url, setUrl] = React.useState("");
  const [errors, setErrors] = React.useState<any>({});

  const validateForm = (): boolean => {
    let formErrors: any = {};
    if (!url) {
      formErrors.url = "Please enter a product URL";
    }

    if (!validateGumroadUrl(url)) {
      formErrors.url = "Please enter a valid Gumroad product URL";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const onSave = () => {
    if (!validateForm()) {
      return;
    }

    toast.promise(
      Axios.post("/products/import-gumroad", { url }).then((res) => {
        onClose();
      }),
      {
        loading: "Importing product... It may take a minute or two.",
        success: "Product imported successfully",
        error: "Error while importing product",
      }
    );
  };

  return (
    <Modal
      onClose={onClose}
      title={
        <div className={styles.modalTitle}>
          Import from Gumroad
          <img className={styles.gumroad} src={Gumroad} alt="Gumroad" />
        </div>
      }
      footerRightButton1={{
        label: "Cancel",
        onClick: onClose,
      }}
      footerRightButton2={{
        label: "Save",
        onClick: onSave,
      }}
    >
      <div className={styles.modalContent}>
        <div className={styles.alert}>
          After import, you still need to double check the{" "}
          <b>design, text, and upload or link</b> your product.
        </div>
        <FormGroup label="Product URL" error={errors.url}>
          <TextInput
            name="url"
            value={url}
            placeholder="Enter product URL"
            onChange={(e) => setUrl(e.target.value)}
          />
        </FormGroup>
      </div>
    </Modal>
  );
};

const validateGumroadUrl = (urlString: any) => {
  try {
    const url = new URL(urlString);
    const regex = /^https?:\/\/.*\.gumroad\.com\/.*$/;

    if (regex.test(url.toString())) {
      return true;
    } else {
      return false;
    }
  } catch (_) {
    return false;
  }
};

export default ImportModal;
