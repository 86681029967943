import React, { useEffect, useRef, useState } from "react";
import styles from "./Page3.module.scss";
import "../content.scss";
import { FormGroup, RenderHtml, TextInput } from "src/components";
import { ChevronLeft, ChevronRight, X } from "react-feather";
import { formatCurrency } from "src/services/currency";
import { ReviewsComponent, SalesCounter } from "../../components";
import { Rating } from "react-simple-star-rating";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
import { CtaButton } from "../Page1";
import { BiFullscreen } from "react-icons/bi";
import { getImageUrl } from "../getImageUrl";
import VariantsSelector from "../../components/VariantsSelector";

const Page3 = ({
  product,
  buyProduct,
  currency,
  reviews,
  salesNumber,
  payWantPrice,
  setPayWantPrice,
  user,
  loading,
  demoMode,
  loadingImages,
  variants,
  setSelectedVariant,
  selectedVariant,
}: any) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isBarVisible, setIsBarVisible] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);
  const componentRef = useRef(null);

  const handleScroll = () => {
    if (!componentRef.current) return;

    const rect = (componentRef.current as any).getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    setIsBarVisible(!(rect.top >= 0 && rect.bottom <= viewportHeight));
  };

  useEffect(() => {
    const el: any = document.getElementById("pageContainer");

    el.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      el.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const totalReviews = reviews.length;
  const countByRating = [0, 0, 0, 0, 0, 0];
  let sumOfRatings = 0;

  reviews.forEach((review: any) => {
    countByRating[5 - Math.floor(review.rating)]++;
    sumOfRatings += review.rating;
  });

  const showArrows = product.images.length > 1;

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className={styles.page3}>
      {product.images && product.images.length > 0 && fullscreen && (
        <div className={styles.fullscreenImage}>
          <div className={styles.img}>
            {showArrows && (
              <>
                <ChevronLeft
                  className={styles.arrowLeft}
                  onClick={handlePrevious}
                  size={40}
                />
                <ChevronRight
                  className={styles.arrowRight}
                  onClick={handleNext}
                  size={40}
                />
              </>
            )}
            <img
              src={product.images[currentIndex]?.url}
              className={styles.image}
            />
          </div>
          <div
            className={styles.xContainer}
            onClick={() => {
              setFullscreen(false);
            }}
          >
            <X
              size={24}
              onClick={() => {
                setFullscreen(false);
              }}
            />
          </div>
          <div className={styles.overlay} />
        </div>
      )}
      {user && (
        <span
          className={styles.user}
          style={{
            color: product.colors.find((c: any) => c.id === "title").value,
          }}
          onClick={() => {
            // window.open(`https://${user.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}`, "_blank");
            const a = document.createElement("a");
            a.href = `https://${user.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}`;
            a.rel = "noopener noreferrer";
            a.click();
          }}
        >
          <img src={user.picture} className={styles.userImage} />
          {user.firstName && user.lastName
            ? user.firstName + " " + user.lastName
            : user.family_name === "" || user.given_name === ""
            ? user.email
            : user.given_name + " " + user.family_name}
        </span>
      )}
      <div className={styles.hero}>
        <h1
          style={{
            color: product.colors.find((c: any) => c.id === "title").value,
          }}
        >
          {product.name}
        </h1>
        <h3
          style={{
            color: product.colors.find((c: any) => c.id === "title").value,
          }}
        >
          {product.subtitle}
        </h3>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          {product.images && product.images.length > 0 && (
            <div className={styles.imageContainer}>
              {showArrows && (
                <>
                  <ChevronLeft
                    className={styles.arrowLeft}
                    onClick={handlePrevious}
                    size={40}
                  />
                  <ChevronRight
                    className={styles.arrowRight}
                    onClick={handleNext}
                    size={40}
                  />
                </>
              )}
              <div
                className={styles.bigImage}
                onClick={() => {
                  setFullscreen(!fullscreen);
                }}
              >
                <BiFullscreen size={16} />
              </div>
              <img
                src={
                  loadingImages
                    ? ""
                    : getImageUrl(product.images[currentIndex]?.url, demoMode)
                }
                className={styles.image}
              />
            </div>
          )}
          <div
            style={{
              color: product.colors.find((c: any) => c.id === "text").value,
            }}
          >
            <RenderHtml html={product.description} />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.creatorContainer}>
            <div
              className={styles.priceContainer}
              ref={componentRef}
              style={{
                color: product.colors.find((c: any) => c.id === "text").value,
              }}
            >
              {product.price > 0 && !product.payWant
                ? formatCurrency(product.price, currency)
                : product.price === 0 && !product.payWant
                ? "Free"
                : product.payWant && payWantPrice > 0
                ? formatCurrency(payWantPrice, currency)
                : "Free"}
            </div>
          </div>
          {product.payWant && (
            <FormGroup
              color={product.colors.find((c: any) => c.id === "text").value}
              label="Pay what you want:"
            >
              <TextInput
                name="price"
                type="number"
                placeholder={
                  product.maxPrice
                    ? `${formatCurrency(product.maxPrice, currency)}+`
                    : "Enter amount"
                }
                value={payWantPrice}
                onChange={(e: any) => {
                  setPayWantPrice(e.target.value);
                }}
                isCurrency
                currency={currency}
                isPayWant
                backgroundColor={
                  product.colors.find((c: any) => c.id === "background").value
                }
                textColor={
                  product.colors.find((c: any) => c.id === "text").value
                }
                borderColor={
                  product.colors.find((c: any) => c.id === "borders").value
                }
              />
            </FormGroup>
          )}
          <CtaButton
            loading={loading}
            payWantPrice={payWantPrice}
            currency={currency}
            product={product}
            buyProduct={buyProduct}
            selectedVariant={selectedVariant}
          />
          {variants && variants.length > 0 && (
            <VariantsSelector
              variants={variants}
              setSelectedVariant={setSelectedVariant}
            />
          )}
          {product.showSales && (
            <SalesCounter salesNumber={salesNumber} product={product} />
          )}
          {product.showReviews && (
            <ReviewsComponent reviews={reviews} product={product} />
          )}
        </div>
      </div>

      <div
        className={`${styles.floatingBottomBar} ${
          isBarVisible ? "" : styles.floatingBottomBarHidden
        }`}
        style={{
          background: product.colors.find((c: any) => c.id === "background")
            .value,
        }}
      >
        {product.showSales && (
          <SalesCounter
            inBottomBar
            salesNumber={salesNumber}
            product={product}
          />
        )}
        <div className={styles.bottomPayWant}>
          {product.payWant && (
            <FormGroup label="Pay what you want:">
              <TextInput
                name="price"
                type="number"
                placeholder={
                  product.maxPrice
                    ? `${formatCurrency(product.maxPrice, currency)}+`
                    : "Enter amount"
                }
                value={payWantPrice}
                onChange={(e: any) => {
                  setPayWantPrice(e.target.value);
                }}
                isCurrency
                currency={currency}
                isPayWant
                backgroundColor={
                  product.colors.find((c: any) => c.id === "background").value
                }
                textColor={
                  product.colors.find((c: any) => c.id === "text").value
                }
                borderColor={
                  product.colors.find((c: any) => c.id === "borders").value
                }
                isCta
              />
            </FormGroup>
          )}
          <CtaButton
            loading={loading}
            payWantPrice={payWantPrice}
            inBottomBar
            currency={currency}
            product={product}
            buyProduct={buyProduct}
            selectedVariant={selectedVariant}
          />
        </div>
      </div>
    </div>
  );
};

export default Page3;
