import React from "react";
import styles from "./PageTitle.module.scss";

type PageTitleProps = {
  children: React.ReactNode;
  actions?: React.ReactNode;
};

const PageTitle: React.FC<PageTitleProps> = ({ children, actions }) => {
  return (
    <div className={styles.titleContainer}>
      <h1 className={styles.pageTitle}>{children}</h1>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

export default PageTitle;
