import React, { useEffect, useState } from "react";

import { FormGroup, Modal, TextArea, TextInput } from "src/components";

import { VariantModalProps, VariantType } from "./VariantModal.types";
import styles from "./VariantModal.module.scss";
import TogglableArea from "../../steps/DefineProduct/TogglableArea";
import TogglableTabs from "../../steps/DefineProduct/ToggleTabs";
import SingleUploader from "../../steps/DefineProduct/SingleUploader";
import { defaultVariant } from "./VariantModal.consts";
import toast from "react-hot-toast";
import Axios from "src/services/api";
import { getFile } from "../../Product.utils";

const VariantModal = ({
  productId,
  onClose,
  variantId,
  variantArg,
}: VariantModalProps) => {
  const [variant, setVariant] = useState<VariantType>({
    ...defaultVariant,
    ...variantArg,
    productId,
  });
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<any>({});
  const [mutating, setMutating] = useState(false);
  const [savedFiles, setSavedFiles] = useState<{
    file: File | null;
    thumbnail: File | null;
  }>({
    file: null,
    thumbnail: null,
  });

  const [prodType, setProdType] = useState<string>(
    variant.isFile ? "file" : "url"
  );
  const [priceType, setPriceType] = useState<string>(
    !variant._id
      ? "regular"
      : variant.payWant
      ? "payWant"
      : variant.price === 0
      ? "free"
      : "regular"
  );

  const onChange = (key: string, value: string) => {
    setVariant({ ...variant, [key]: value });
  };

  const onTypeChange = (value: string) => {
    setProdType(value);
    setVariant({ ...variant, isFile: value === "file" });
  };

  const onPriceChange = (value: string) => {
    setPriceType(value);
    if (value === "free") {
      setVariant({ ...variant, price: 0, payWant: false });
    } else if (value === "payWant") {
      setVariant({
        ...variant,
        price: 0,
        minPrice: 9.99,
        maxPrice: 29.99,
        payWant: true,
      });
    } else {
      setVariant({ ...variant, price: 9.99, payWant: false });
    }
  };

  const onFileChange = (file: File | null) => {
    setFile(file);
  };

  const onThumbnailChange = (file: File | null) => {
    setThumbnail(file);
  };

  const validateForm = () => {
    let formErrors: any = {};
    if (!variant.name) formErrors.name = "Name is required";

    // if product is file, check if file is uploaded
    if (variant.isFile) {
      if (!file) {
        formErrors.file = "Variant file is required";
      }
    } else {
      if (!variant.url) {
        formErrors.url = "Variant URL is required";
      }
    }

    if (!variant.payWant) {
      if (!variant.price && variant.price !== 0) {
        formErrors.price = "Price is required";
      } else if (isNaN(Number(variant.price))) {
        formErrors.price = "Price must be a number";
      } else if (Number(variant.price) < 0) {
        formErrors.price = "Price must be greater than 0";
      }
    } else {
      if (!variant.minPrice && variant.minPrice !== 0) {
        formErrors.minPrice = "Min price is required";
      } else if (isNaN(Number(variant.minPrice))) {
        formErrors.minPrice = "Min price must be a number";
      } else if (Number(variant.minPrice) < 0) {
        formErrors.minPrice = "Min price must be greater than 0";
      }

      if (!variant.maxPrice && variant.maxPrice !== 0) {
        formErrors.maxPrice = "Max price is required";
      } else if (isNaN(Number(variant.maxPrice))) {
        formErrors.maxPrice = "Max price must be a number";
      } else if (Number(variant.maxPrice) < 0) {
        formErrors.maxPrice = "Max price must be greater than 0";
      }
    }
    // }

    setErrors(formErrors);

    // show an error toast if there are errors
    if (Object.keys(formErrors).length > 0) {
      toast.error(
        <>
          <div className={styles.errorToast}>
            <div className={styles.errorToastTitle}>
              Please fix the following errors:
            </div>
            <ul>
              {Object.keys(formErrors).map((key: string) => (
                <li key={key}>{formErrors[key]}</li>
              ))}
            </ul>
          </div>
        </>,
        {
          duration: 40000,
        }
      );
    }

    return Object.keys(formErrors).length === 0;
  };

  const onSubmit = () => {
    if (!validateForm()) return;

    setMutating(true);
    const formData = new FormData();

    if (file !== savedFiles?.file) formData.append("file", file!);

    if (thumbnail !== savedFiles?.thumbnail)
      formData.append("thumbnail", thumbnail!);

    formData.append("url", variant.url);
    formData.append("isFile", variant.isFile as any);
    formData.append("name", variant.name);
    formData.append("description", variant.description);
    formData.append("subtitle", variant.subtitle);
    formData.append("price", (variant.price || 0).toString());
    formData.append("payWant", variant.payWant?.toString());
    formData.append("minPrice", (variant.minPrice || 0)?.toString());
    formData.append("maxPrice", (variant.maxPrice || 0)?.toString());
    formData.append("productId", productId || "");

    if (variant._id) {
      // update variant
      toast.promise(
        Axios.post(`/products/variants/update/${variant._id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            setVariant(res.data);
            setMutating(false);
            setSavedFiles({
              file,
              thumbnail,
            });
            onClose();
          })
          .catch((err) => {
            console.log(err);
            setMutating(false);
          }),
        {
          loading: "Saving variant...",
          success: <>Variant saved</>,
          error: <>Could not save variant</>,
        }
      );
    } else {
      // create variant
      toast.promise(
        Axios.post("/products/variants", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            setVariant(res.data);
            setMutating(false);
            setSavedFiles({
              file,
              thumbnail,
            });
            onClose();
          })
          .catch((err) => {
            console.log(err);
            setMutating(false);
          }),
        {
          loading: "Saving variant...",
          success: <>Variant saved</>,
          error: <>Could not save variant</>,
        }
      );
    }
  };

  useEffect(() => {
    if (variantId) {
      // get variant
      toast.promise(
        Axios.get(`/products/variants/${variantId}`).then((res) => {
          setVariant({
            ...res.data,
            thumbnail:
              res.data.thumbnail === "" || res.data.thumbnail === "null"
                ? undefined
                : res.data.thumbnail,
          });

          if (
            res.data.thumbnail &&
            res.data.thumbnail !== "" &&
            res.data.thumbnail !== "null"
          ) {
            getFile(res.data.thumbnail).then((file) => {
              setThumbnail(file);
              setSavedFiles((prevSavedFiles) => ({
                ...prevSavedFiles,
                thumbnail: file,
              }));
            });
          }

          if (
            res.data.file &&
            res.data.file !== "" &&
            res.data.file !== "null"
          ) {
            getFile(res.data.file).then((file) => {
              setFile(file);
              setSavedFiles((prevSavedFiles) => ({
                ...prevSavedFiles,
                file,
              }));
            });
          }

          setProdType(variant.isFile ? "file" : "url");
        }),
        {
          loading: "Loading variant...",
          success: "Variant loaded",
          error: "Error while loading variant",
        }
      );
    }
  }, [variantId]);

  return (
    <Modal
      onClose={onClose}
      title={variant._id ? "Edit variant" : "Add a variant"}
      footerRightButton1={{
        label: "Cancel",
        onClick: onClose,
      }}
      footerRightButton2={{
        variant: "primary",
        label: mutating ? "Saving..." : "Save variant",
        onClick: onSubmit,
      }}
    >
      <div className={styles.variantModal}>
        <div className={styles.form}>
          <FormGroup label="Title" error={errors.name} required>
            <TextInput
              onChange={(e: any) => onChange("name", e.target.value)}
              value={variant.name}
              name="name"
              placeholder="Enter product title"
              error={errors.name}
            />
          </FormGroup>
          <FormGroup label="Description" error={errors.description}>
            <TextArea
              onChange={(e: any) => onChange("description", e.target.value)}
              value={variant.description}
              name="description"
              placeholder="Enter product description"
              error={errors.description}
            />
          </FormGroup>
          <div id="url">
            <TogglableArea
              title="Variant type"
              subTitle="Choose the type of your variant, whether as a file or as a URL."
              required
            >
              <TogglableTabs
                items={[
                  {
                    id: "file",
                    title: "File",
                    subTitle: "People will download your product",
                  },
                  {
                    id: "url",
                    title: "URL",
                    subTitle: "People will get access to your URL",
                  },
                  {
                    id: "course",
                    title: "Course",
                    subTitle: "Host your course and let your students enroll",
                    soon: true,
                  },
                ]}
                onChange={(value: string) => onTypeChange(value)}
                value={prodType}
              />
              {prodType === "file" ? (
                <SingleUploader
                  onChange={onFileChange}
                  value={file}
                  text="Maximum file size: 200MB"
                  error={errors.file}
                />
              ) : (
                <FormGroup label="URL" required error={errors.url}>
                  <TextInput
                    onChange={(e: any) => onChange("url", e.target.value)}
                    value={variant.url}
                    error={errors.url}
                    name="url"
                    placeholder="Enter product URL"
                  />
                </FormGroup>
              )}
            </TogglableArea>
          </div>
          <div id="price">
            <TogglableArea
              title="Pricing"
              subTitle="Choose a pricing structure that’s suitable for you and price your variant accordingly."
            >
              <TogglableTabs
                items={[
                  {
                    id: "regular",
                    title: "Regular price",
                    subTitle: "Charge a regular fee",
                  },
                  // {
                  //   id: "payWant",
                  //   title: "Pay what you want",
                  //   subTitle: "People will set the price",
                  // },
                  {
                    id: "free",
                    title: "Lead magnet",
                    subTitle: "Give it away for free",
                  },
                ]}
                onChange={(value: string) => onPriceChange(value)}
                value={priceType}
              />
              {priceType === "regular" ? (
                <FormGroup label="Price" error={errors.price} required>
                  <TextInput
                    onChange={(e: any) => onChange("price", e.target.value)}
                    value={variant.price}
                    error={errors.price}
                    name="price"
                    placeholder="0"
                    isCurrency
                    type="number"
                  />
                </FormGroup>
              ) : (
                <FormGroup label="Price">
                  <TextInput
                    onChange={(e: any) => onChange("price", e.target.value)}
                    value={variant.price}
                    name="price"
                    placeholder="0"
                    isCurrency
                    type="number"
                    disabled
                  />
                </FormGroup>
              )}
            </TogglableArea>
          </div>

          <TogglableArea
            title="Thumbnail"
            subTitle="Upload a thumbnail of your variant. Used in your checkout page, and email."
          >
            <SingleUploader
              onChange={onThumbnailChange}
              value={thumbnail}
              text="Supports JPEG, PNG, GIF. Maximum file size: 4MB."
              type="image"
            />
          </TogglableArea>
        </div>
      </div>
    </Modal>
  );
};

export default VariantModal;
