import React from "react";
import { RiInformationLine } from "react-icons/ri";
import styles from "./InfoIcon.module.scss";

const InfoIcon = ({ text, position = 'bottom' }: any) => {
  return (
    <span className={styles.payoutInfoContainer}>
      <span className={styles.payoutInfoIcon}>
        <RiInformationLine color="#6B7280" size={20} />
      </span>
      <span className={`${styles.payoutInfo} ${position}`}>
        {text}
      </span>
    </span>
  );
};

export default InfoIcon;