import React from "react";
import styles from "./EmptyState.module.scss";
import { Button } from "../Button";

type EmptyStateProps = {
  title?: string;
  subTitle?: string;
  buttonClick?: () => void;
  buttonText?: string;
  loading?: boolean;
};

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  subTitle,
  buttonClick,
  buttonText,
  loading,
}) => {
  return (
    <div className={styles.emptyState}>
      {loading ? (
        <div className={styles.loader}>Loading...</div>
      ) : (
        <>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subTitle}>{subTitle}</p>
          {buttonText && buttonClick && (
            <Button text={buttonText} onClick={buttonClick} />
          )}
        </>
      )}
    </div>
  );
};

export default EmptyState;
