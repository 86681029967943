import React, { useEffect } from "react";
import { useEditor, EditorContent, Extension } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Typography from "@tiptap/extension-typography";
import Link from "@tiptap/extension-link";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Placeholder from "@tiptap/extension-placeholder";
import { Video } from "./Embed";

import type { Extensions } from "@tiptap/react";
import "./TipTap.scss";

// Load all highlight.js supported languages
import * as lowlight from "lowlight";

import { Toolbar } from "./Toolbar";
import Dropcursor from "@tiptap/extension-dropcursor";
import Image from "@tiptap/extension-image";
import { Anchor } from "./Anchor";

type TiptapProps = {
  template?: string;
  content?: string;
  editable?: boolean;
  extended?: boolean;
  placeholder?: string;
  withToolbar?: boolean;
  withTypographyExtension?: boolean;
  withLinkExtension?: boolean;
  withCodeBlockLowlightExtension?: boolean;
  withTaskListExtension?: boolean;
  withPlaceholderExtension?: boolean;
  withMentionSuggestion?: boolean;
  withEmojiSuggestion?: boolean;
  withEmojisReplacer?: boolean;
  withHexColorsDecorator?: boolean;
  onChange?: (content: string) => void;
  error?: string;
  refund?: boolean;
};

const CustomEnter = Extension.create({
  name: "customEnter",

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        return editor.chain().createParagraphNear().run();
      },
    };
  },
});

function Tiptap({
  template = "",
  refund = false,
  content = "",
  editable = true,
  extended = false,
  placeholder = "",
  withToolbar = true,
  withTypographyExtension = true,
  withLinkExtension = true,
  withCodeBlockLowlightExtension = false,
  withTaskListExtension = true,
  withPlaceholderExtension = true,
  error,
  onChange,
}: TiptapProps) {
  const extensions: Extensions = [
    StarterKit.configure({
      ...(withCodeBlockLowlightExtension && { codeBlock: false }),
    }),
    Image,
    Dropcursor,
    Video,
    Anchor,
    CustomEnter,
  ];

  if (withTypographyExtension) {
    extensions.push(Typography);
  }

  if (withLinkExtension) {
    extensions.push(
      Link.configure({
        linkOnPaste: false,
        openOnClick: false,
      })
    );
  }

  if (withCodeBlockLowlightExtension) {
    extensions.push(
      CodeBlockLowlight.configure({
        lowlight,
      })
    );
  }

  if (withTaskListExtension) {
    extensions.push(TaskList, TaskItem);
  }

  if (withPlaceholderExtension) {
    extensions.push(
      Placeholder.configure({
        placeholder,
      })
    );
  }

  const editor = useEditor({
    content,
    extensions,
    editable,
    onUpdate: ({ editor }) => {
      onChange && onChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(template);
    }
  }, [template]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className={`WhiteCard ${error ? "error" : ""}`}>
        {withToolbar ? (
          <Toolbar extended={extended} editor={editor} refund={refund} />
        ) : null}
        <EditorContent editor={editor} />
      </div>
    </>
  );
}

export default Tiptap;
