import React, { useEffect, useState } from "react";
import Confetti from "react-dom-confetti";
const ConfettiComponent = () => {
  const [active, setActive] = useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  useEffect(() => {
    setActive(true);
  }, []);

  const config = {
    angle: 90,
    spread: 55,
    startVelocity: 65,
    elementCount: 100,
    dragFriction: 0.1,
    duration: 10000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  return <Confetti active={active} config={config} />;
};

export default ConfettiComponent;