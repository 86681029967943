import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React, {
  ComponentType,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageLoader } from "../page-loader";
import Axios from "src/services/api";
import { isTokenExpired } from "src/services/isTokenExpired";
import usePopups from "src/services/usePopups";
import { OnboardingModal, VerifyEmail } from "./components";
import { toast } from "react-hot-toast";
import { MoRModal } from "./components/MoRModal";
// import { Crisp } from "crisp-sdk-web";
import { InviteModal } from "./components/InviteModal";
import { Onboarding } from "src/pages/Onboarding";
import Unsupported from "./components/Unsupported";
import { useLocation } from "react-router-dom";

interface AuthenticationGuardProps {
  component: ComponentType;
}

const isMaintenanceMode = false;

const blockedDomains = ["redman.top"];

function isEmailFromBlockedDomain(email: string) {
  return blockedDomains.includes(email.split("@")[1]);
}

const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  component,
}) => {
  const Component = withAuthenticationRequired(component, {
    returnTo: () => window.location.origin,
    onRedirecting: () => {
      return (
        <div className="page-layout">
          <PageLoader />
        </div>
      );
    },
  });

  const { isAuthenticated, getIdTokenClaims, logout } = useAuth0();
  const { popups, togglePopup } = usePopups(["mor", "invite"]);
  const [hasMoR, setHasMoR] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [loading, setLoading] = useState(true);
  const [needsVerification, setNeedsVerification] = useState(false);

  const checkTokenExpiration = async () => {
    if (isAuthenticated) {
      const tokenClaims = await getIdTokenClaims();
      const token = tokenClaims?.__raw;
      if (isTokenExpired(token as string)) {
        logout();
      }
    } else {
      setLoading(false);
    }
  };

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    checkTokenExpiration();

    if (accessToken && isAuthenticated) {
      setLoading(true);
      Axios.get("/users/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(async (res) => {
          if (isEmailFromBlockedDomain(res.data.email)) {
            throw new Error(
              "Please contact us urgently to resolve this issue."
            );
          }

          if (!res.data.verifiedEmail) {
            // check if current page is /verify
            if (window.location.pathname !== "/verify") {
              setNeedsVerification(!res.data.verifiedEmail);
            }
          } else {
            if (!res.data.finishedOnboarding) {
              setShowOnboarding(true);
            } else {
              setShowOnboarding(false);
            }
            setHasMoR(res.data.sawMoR);

            const productBought = localStorage.getItem("product-bought");
            console.log(res.data.userId);
            if (productBought) {
              await Axios.post(
                "/bought-products",
                {
                  product: JSON.parse(productBought),
                  buyerUser: res.data.userId,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );
            }

            // remove product-bought from local storage
            localStorage.removeItem("product-bought");
          }

          localStorage.setItem("me", JSON.stringify(res.data));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response &&
            (err.response.status === 400 ||
              err.response.status === 401 ||
              err.response.status === 500)
          ) {
            localStorage.removeItem("accessToken");
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }

          if (
            err.message === "Please contact us urgently to resolve this issue."
          ) {
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }
        });
    } else {
      setLoading(false);
    }
  }, [accessToken, isAuthenticated]);

  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  useEffect(() => {
    if (me?.finishedOnboarding) {
      togglePopup("mor", !hasMoR);
    }
  }, [hasMoR]);

  // useEffect(() => {
  //   Crisp.configure("0f5699d5-d2ee-4d6b-bf25-75058a0c8439");
  // }, []);

  useEffect(() => {
    if (!me) {
      // @ts-ignore
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "k4iliebs",
      });
    } else {
      // @ts-ignore
      window.Intercom("boot", {
        user_id: me?.userId,
        stripe_connected_account_id: me?.stripeConnectedAccountId
          ? me?.stripeConnectedAccountId
          : "",
        api_base: "https://api-iam.intercom.io",
        app_id: "k4iliebs",
        name: me?.firstName,
      });
    }
  }, [me]);

  // const [supportedCountries, setSupportedCountries] = useState([]);
  // const [currentCountry, setCurrentCountry] = useState<any>(null);
  // const [unsupportedUser, setUnsupportedUser] = useState(null);

  // useEffect(() => {
  //   import("../../services/supportedCountries.json").then((data) => {
  //     setSupportedCountries(data.default as any);
  //   });
  // }, []);

  // // determine user country
  // useEffect(() => {
  //   if (me?.finishedOnboarding) {
  //     Axios.get("https://api.ipgeolocation.io/ipgeo", {
  //       params: {
  //         apiKey: "5c920f814db94414b5f7f70bd9dd56de",
  //       },
  //     })
  //       .then((res) => {
  //         setCurrentCountry(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [me]);

  // useEffect(() => {
  //   if (me?.finishedOnboarding) {
  //     if (currentCountry) {
  //       setUnsupportedUser(
  //         supportedCountries.some(
  //           (country: any) => country.code === currentCountry.country_code2
  //         )
  //           ? null
  //           : currentCountry
  //       );
  //     }
  //   }
  // }, [currentCountry]);

  return (
    <>
      {isMaintenanceMode ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "white",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "6rem",
            fontWeight: 700,
            color: "black",
            textAlign: "center",
          }}
        >
          🚧
          <br />
          <br />
          Pocketsflow is under maintenance!
          <br />
          <br />
          🚧
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : loading ? (
        <PageLoader />
      ) : needsVerification ? (
        <VerifyEmail />
      ) : showOnboarding ? (
        <Onboarding />
      ) : (
        <Component />
      )}
      {popups.invite && (
        <InviteModal
          onSave={(code) => {
            toast.promise(
              Axios.post(
                `/users/invite`,
                {
                  code,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              ).then((res) => {
                window.location.reload();
              }),
              {
                loading: "Inviting...",
                success: "Invited!",
                error: "Error while inviting.",
              }
            );
          }}
        />
      )}
      {popups.mor && (
        <MoRModal
          onSave={() => {
            togglePopup("mor");
            toast.promise(
              Axios.post(
                `/users/update`,
                {
                  sawMoR: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              ).then((res) => {
                setHasMoR(true);
              }),
              {
                loading: "Saving...",
                success: "Saved!",
                error: "Error while saving.",
              }
            );
          }}
        />
      )}
    </>
  );
};

export default AuthenticationGuard;
