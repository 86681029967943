// CheckInput.tsx
import React from "react";
import styles from "./CheckInput.module.scss";

interface CheckInputProps {
  id: string;
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const CheckInput: React.FC<CheckInputProps> = ({
  id,
  name,
  checked,
  onChange,
  disabled,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className={styles.checkInput}>
      <input
        type="checkbox"
        disabled={disabled}
        id={id}
        name={name}
        className={styles.checkbox}
        checked={checked}
        onChange={handleInputChange}
      />
      <label htmlFor={id} className={styles.label} />
    </div>
  );
};

export default CheckInput;
