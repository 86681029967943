import React from "react";
import styles from "./RadioInput.module.scss";

interface RadioInputProps {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const RadioInput: React.FC<RadioInputProps> = ({
  id,
  name,
  value,
  checked,
  onChange,
  disabled,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={styles.radioInput}>
      <input
        type="radio"
        disabled={disabled}
        id={id}
        name={name}
        value={value}
        className={styles.radio}
        checked={checked}
        onChange={handleInputChange}
      />
      <label htmlFor={id} className={styles.label} />
    </div>
  );
};

export default RadioInput;
