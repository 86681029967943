// ReviewsComponent.tsx

import React, { FC, useState } from "react";
import styles from "./ReviewsComponent.module.scss";
import { Rating } from "react-simple-star-rating";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import numeral from "numeral";

dayjs.extend(relativeTime);

interface Review {
  rating: number;
  createdAt: string;
  review: string;
  buyerEmail: string;
}

interface ReviewsComponentProps {
  reviews: Review[];
  product: any;
}

const ReviewsComponent: FC<ReviewsComponentProps> = ({ reviews, product }) => {
  const [expandedReviewIndex, setExpandedReviewIndex] = useState(-1);
  const totalReviews = reviews.length;
  const countByRating = [0, 0, 0, 0, 0, 0];
  let sumOfRatings = 0;

  reviews.forEach((review) => {
    countByRating[5 - Math.floor(review.rating)]++;
    sumOfRatings += review.rating;
  });

  const averageRating = totalReviews > 0 ? sumOfRatings / totalReviews : 0;

  return (
    <div
      className={styles.ratingContainer}
      style={{
        borderTop: `1px solid ${
          product.colors.find((c: any) => c.id === "borders").value
        }`,
      }}
    >
      <div className={styles.ratingHeader}>
        <div
          className={styles.left}
          style={{
            color: product.colors.find((c: any) => c.id === "text").value,
          }}
        >
          Ratings
        </div>
        <div className={styles.right}>
          <Rating
            size={24}
            initialValue={averageRating}
            transition
            allowFraction
            disableFillHover
            allowHover={false}
          />
          <div
            className={styles.revs}
            style={{
              color: product.colors.find((c: any) => c.id === "text").value,
            }}
          >
            ({numeral(reviews.length).format("0[.]0")} Rating
            {reviews.length > 1 ? "s" : ""})
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {[5, 4, 3, 2, 1].map((rating) => {
          const count = countByRating[5 - rating];
          const percentage =
            count > 0 ? Math.round((count / totalReviews) * 100) : 0;

          return (
            <div key={rating} className={styles.row}>
              <div
                className={styles.stars}
                style={{
                  color: product.colors.find((c: any) => c.id === "text").value,
                }}
              >
                {rating} star{rating > 1 ? "s" : ""}
              </div>
              <div className={styles.bar}>
                <div
                  className={styles.barInner}
                  style={{
                    width: `${percentage}%`,
                    background: product.colors.find(
                      (c: any) => c.id === "buttons"
                    ).value,
                  }}
                />
              </div>
              <div
                className={styles.percentage}
                style={{
                  color: product.colors.find((c: any) => c.id === "text").value,
                }}
              >
                {percentage}%
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.reviews}>
        {reviews.map((review, index) => (
          <div
            key={index}
            className={styles.review}
            style={{
              border: `1px solid ${
                product.colors.find((c: any) => c.id === "borders").value
              }`,
            }}
          >
            <div className={styles.top}>
              <div className={styles.nameContainer}>
                <div
                  className={styles.name}
                  style={{
                    color: product.colors.find((c: any) => c.id === "text")
                      .value,
                  }}
                >
                  {maskEmail(review.buyerEmail)}
                </div>
                <Rating
                  size={16}
                  initialValue={review.rating}
                  transition
                  allowFraction
                  disableFillHover
                  allowHover={false}
                />
              </div>
              <div
                className={styles.date}
                style={{
                  color: product.colors.find((c: any) => c.id === "text").value,
                }}
              >
                {dayjs(review.createdAt).fromNow()}
              </div>
            </div>
            <div
              className={`${styles.comment} ${
                expandedReviewIndex === index && styles.commentExpanded
              }`}
              style={{
                color: product.colors.find((c: any) => c.id === "text").value,
              }}
            >
              {review.review || "—"}
            </div>
            {review.review?.length > 100 && (
              <button
                className={styles.button}
                style={{
                  color: product.colors.find((c: any) => c.id === "buttons")
                    .value,
                }}
                onClick={() =>
                  setExpandedReviewIndex(
                    expandedReviewIndex === index ? -1 : index
                  )
                }
              >
                {expandedReviewIndex === index ? "See less..." : "See more..."}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewsComponent;

function maskEmail(email: string): string {
  const [username, domain] = email.split("@");

  if (username.length <= 2) {
    return email;
  }

  const maskedUsername =
    username[0] +
    "*".repeat(username.length - 2) +
    username[username.length - 1];

  return `${maskedUsername}@${domain}`;
}
