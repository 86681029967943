import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { AuthenticationGuard, PageTitle } from "src/components";
import CreatorSettings from "./CreatorSettings";
import GlobalStyles from "./GlobalStyles";
import Payments, { PayPalCallback } from "./Payments";
import styles from "./Profile.module.scss";
import { default as Settings } from "./Settings";
import { default as Taxes } from "./Taxes";

const Profile: React.FC = () => {
  const { user } = useAuth0();
  const location = useLocation();
  const isActive = (route: string) => location.pathname === route;

  // useEffect(() => {
  //   Axios.get("/users/stripe-account").then((res) => {
  //     setStripeAccount(res.data);
  //   });
  // }, []);

  // const connectStripe = async () => {
  //   try {
  //     const responseConnect = await Axios.post("/users/connect-stripe", {
  //       email: user?.email,
  //     });
  //     const responseLink = await Axios.post("/users/create-stripe-link", {
  //       connectedAccountId: responseConnect.data.id,
  //     });

  //     window.open(responseLink.data.url, "_blank");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const connectPayPal = async () => {
  //   const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  //   const redirectUri = encodeURIComponent(
  //     process.env.REACT_APP_PAYPAL_REDIRECT_URL || ""
  //   );
  //   const scope = encodeURIComponent(
  //     "openid https://uri.paypal.com/services/paypalattributes/business"
  //   );

  //   window.location.href = `https://www.paypal.com/connect?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;
  // };

  // const saveUser = async () => {
  //   try {
  //     const response = await Axios.post("/users/update-ppp", {
  //       ppp,
  //     });
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  if (!user) {
    return null;
  }

  return (
    <div className={styles.profilePageContainer}>
      <PageTitle>Settings</PageTitle>
      <div className={styles.pageContainer}>
        <div className={styles.sidebar}>
          <div className={isActive("/profile") ? styles.activeItem : ""}>
            <Link to="/profile">Settings</Link>
          </div>
          <div
            className={isActive("/profile/payments") ? styles.activeItem : ""}
          >
            <Link to="/profile/payments">Payments</Link>
          </div>
          <div className={isActive("/profile/taxes") ? styles.activeItem : ""}>
            <Link to="/profile/taxes">Taxes</Link>
          </div>
          <div className={isActive("/profile/global") ? styles.activeItem : ""}>
            <Link to="/profile/global">Global styles</Link>
          </div>
          <div
            className={isActive("/profile/creator") ? styles.activeItem : ""}
          >
            <Link to="/profile/creator">Creator page</Link>
          </div>
          {/* <div>
          Integrations
          </div> */}
        </div>
        <div className={styles.content}>
          <Routes>
            <Route
              path="/"
              element={<AuthenticationGuard component={Settings} />}
            />
            <Route
              path="/payments"
              element={<AuthenticationGuard component={Payments} />}
            />
            <Route
              path="/payments/paypal"
              element={<AuthenticationGuard component={PayPalCallback} />}
            />
            <Route
              path="/taxes"
              element={<AuthenticationGuard component={Taxes} />}
            />
            <Route
              path="/global"
              element={<AuthenticationGuard component={GlobalStyles} />}
            />
            <Route
              path="/creator"
              element={<AuthenticationGuard component={CreatorSettings} />}
            />
          </Routes>
        </div>
      </div>
      {/* <div className="content__body">
        <div className="profile-grid">
          <div className="profile__header">
            <img src={user.picture} alt="Profile" className="profile__avatar" />
            <div className="profile__headline">
              <h2 className="profile__title">{user.name}</h2>
              <span className="profile__description">{user.email}</span>
            </div>
          </div>
          <div className="profile__details">
            <CodeSnippet
              title="Decoded ID Token"
              code={JSON.stringify(user, null, 2)}
            />
          </div>
        </div>
      </div>
      <div className={styles.stripeSection}>
        <h2>Stripe</h2>
        {stripeAccount?.message === "No stripe account connected" ? (
          <Button onClick={connectStripe} text="Connect with Stripe" />
        ) : (
          <Button text="Stripe account is Connected ✅" />
        )}
      </div>
      <br></br>
      <br></br>
      <div className={styles.stripeSection}>
        <h2>PayPal</h2>
        <Button onClick={connectPayPal} text="Connect with PayPal" />
      </div>
      <br></br>
      <br></br>
      <FormGroup label="Enable PPP">
        <SwitchInput
          name="payWant"
          checked={ppp}
          onChange={(checked) => {
            setPpp(checked);
          }}
          id="ppp"
        />
      </FormGroup>
      <br></br>
      <br></br>
      <Button text="Save User" onClick={saveUser} /> */}
    </div>
  );
};

export default Profile;
