import React, { ReactNode } from "react";
import styles from "./Card.module.scss";

interface CardProps {
  title?: string;
  centerTitle?: boolean;
  children: ReactNode;
}

const Card: React.FC<CardProps> = ({ children, title, centerTitle }) => {
  return (
    <div className={`${styles.card} ${styles.className || ""}`}>
      {title && (
        <h4
          className={
            styles.title + " " + (centerTitle ? styles.centerTitle : "")
          }
        >
          {title}
        </h4>
      )}
      {children}
    </div>
  );
};

export default Card;
